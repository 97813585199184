import axios from "axios";
import {
  getCognitoJWTTokens,
  getProductJWTTokens,
  refreshCognitoJWTTokens,
  refreshProductJWTTokens,
} from "./authHelpers";
import { disconnectWebSocketWithoutDispatch } from "../Actions/WebSocketActions/WebSocketActions";
import { logoutUserWithoutDispatch } from "../Actions/UserActions/UserActions";

const isTokenExpired = (token, type = "cognito") => {
  if (token?.split('.')?.length > 1) {
    const expiry =
      JSON.parse(atob(token.split(".")[1])).exp * 1000 - 1000 * 60 * 3;
    const currentTime = Math.floor(new Date().getTime());

    return currentTime >= expiry;
  }

  return true;
};

const setJWTSessionToken = async (token) => {
  const baseUrl = localStorage.getItem("API_URL");
  if (baseUrl) {
    await axios({
      url: `${baseUrl}/DPFAPI/UserRequest`,
      method: "post",
      data: {
        actionType: "SetJWTSessionToken",
        token,
      },
    });
  }
};

const axiosDPFAPIClient = axios.create({
  baseURL: localStorage.getItem("API_URL"),
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosDPFAPIClient.interceptors.request.use(async (config) => {
  // set access tokens to the request if they are available
  // precedence is product token -> cognito token

  let { accessToken: cognitoToken } = getCognitoJWTTokens();
  let { accessToken: productToken } = getProductJWTTokens();

  if (cognitoToken && isTokenExpired(cognitoToken, "cognito")) {
    try {
      await refreshCognitoJWTTokens();
    } catch (error) {
      // clear storage and navigate to sign in
      console.error('axiosDPFAPIClient: axiosDPFAPIClient.interceptors.request.use Ln:43-53');
      console.error(error);
      localStorage.clear();
      window.location = `${window.location.origin}/login`;
      return Promise.reject(new Error(error));
    }

    cognitoToken = getCognitoJWTTokens().accessToken;
  }

  const hasValidCognitoToken =
    cognitoToken && !isTokenExpired(cognitoToken, "cognito");
  const hasExpiredProductToken =
    productToken && isTokenExpired(productToken, "product");

  if (hasValidCognitoToken && hasExpiredProductToken) {
    await refreshProductJWTTokens();
    productToken = getProductJWTTokens().accessToken;
    console.log("Product token expired. Refreshed");

    await setJWTSessionToken(productToken);
    console.log("Set product token and session");
  }

  if (productToken) {
    config.headers = {
      ...config.headers,
      Authorization: productToken,
    };
    return config;
  }

  if (cognitoToken) {
    config.headers = {
      ...config.headers,
      Authorization: cognitoToken,
    };
    return config;
  }

  return config;
});

/**
 * Clears all cookies, that are set by javascript in the browser
 * This won't clear HTTPOnly cookies
 */
export const clearCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
}

export const logoutUserClearStorage = async () => {
  await logoutUserWithoutDispatch();
  localStorage.clear();
  sessionStorage.clear();
  clearCookies();
  disconnectWebSocketWithoutDispatch();
  window.location = `${window.location.origin}/login`;
};

export const isSessionInvalid = (responseBody) => {
  if (!responseBody) {
    console.log('[isSessionInvalid] Response body is undefined, probably due to network error');
    return true;
  }
  const responseKeys = Object.keys(responseBody);
  if (responseBody.length === 0) {
    console.log('[isSessionInvalid] Response body is empty');
    console.log('Skipping session check and logging out the user');
    return true;
  }
  const userLoggedOutRegex = /user\s*has\s*not\s*logged\s*in/i;
  for (const key of responseKeys) {
    if (responseBody[key] && Array.isArray(responseBody[key].result)) {
      const results = responseBody[key].result;
      for (const result of results) {
        if (result.status === 'Failed' && userLoggedOutRegex.test(result.description)) {
          console.log('[isSessionInvalid] User logged out');
          return true;
        }
      }
    }
  }
}

axiosDPFAPIClient.interceptors.response.use(response => {
  if (isSessionInvalid(response?.data)) {
    console.log('DPFAPIClient Session is invalid. Logging out user');
    logoutUserClearStorage();
  }

  return response;
});

export default axiosDPFAPIClient;
