import axiosDPFAPIClient from "../../Utils/axiosDPFAPIClient";

// ---------------------****Supplier Actions****-----------------------------

//----------------------****Get Suppliers List****---------------------------
//----------------------****Get Supplier Tallies****---------------------------

export const getSupplierTallies = () => async (dispatch) => {
  dispatch({
    type: "GET_SUPPLIER_TALLIES_INIT",
  });
  let url = `/DPFAPI/SupplierRequest?actionType=GetSupplierTallies`;
  try {
    let response = await axiosDPFAPIClient.get(url);
    let getSupplierResponse =
      (response && response.data && response.data.SupplierResponse) || "";

    if (getSupplierResponse && getSupplierResponse.results.length > 0) {
      if (getSupplierResponse.results[0].status === "Success") {
        dispatch({
          type: "GET_SUPPLIER_TALLIES_SUCCESS",
          payload: getSupplierResponse,
        });
      } else {
        dispatch({
          type: "GET_SUPPLIER_TALLIES_FAIL",
          payload:
            getSupplierResponse.results[0].description ||
            "Error While Getting Supplier Tallies.",
        });
      }
    } else {
      dispatch({
        type: "GET_SUPPLIER_TALLIES_FAIL",
        payload: "Error While Getting Supplier Tallies.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Supplier Tallies.";
    dispatch({
      type: "GET_SUPPLIER_TALLIES_FAIL",
      payload: error,
    });
  }
};

//----------------------****Get Suppliers List****---------------------------
export const getSuppliersList =
  (currency, previousSupplier, module, supplierTally) => async (dispatch) => {
    dispatch({
      type: "GET_SUPPLIERS_LIST_INIT",
    });
    let url;
    if (currency && module) {
      url = `/DPFAPI/SupplierRequest?actionType=GetSuppliersList&currency=${currency}&module=${module}`;
    } else if (module) {
      url = `/DPFAPI/SupplierRequest?actionType=GetSuppliersList&module=${module}`;
    } else if (previousSupplier) {
      //in Search Page while getting the suppliers list then previous suppliers check will be used
      url = `/DPFAPI/SupplierRequest?actionType=GetSuppliersList&previousSupplier=${previousSupplier}`;
    } else if (supplierTally) {
      let type = supplierTally.type || "";
      let teamOrders = supplierTally.teamOrders || "";
      url = `/DPFAPI/SupplierRequest?actionType=GetSuppliersList&supplierType=${type}&teamOrders=${teamOrders}`;
    } else {
      url = "/DPFAPI/SupplierRequest?actionType=GetSuppliersList";
    }

    try {
      // let response = getSuppliersList_api;

      let response = await axiosDPFAPIClient.get(url);

      let getSupplierResp =
        (response && response.data && response.data.SupplierResponse) || "";
      if (getSupplierResp && getSupplierResp.results.length > 0) {
        if (
          getSupplierResp.results[0] &&
          getSupplierResp.results[0].status === "Failed"
        ) {
          dispatch({
            type: "GET_SUPPLIERS_LIST_FAIL",
            payload:
              getSupplierResp.results[0].description ||
              "Error While Getting Suppliers List.",
          });
        }
        if (
          getSupplierResp.results[0] &&
          getSupplierResp.results[0].status === "Success"
        ) {
          dispatch({
            type: "GET_SUPPLIERS_LIST_SUCCESS",
            payload: getSupplierResp,
          });
        }
      } else {
        dispatch({
          type: "GET_SUPPLIERS_LIST_FAIL",
          payload: "Error While Getting Suppliers List.",
        });
      }
    } catch (err) {
      const error = err.message || "Error While Getting Suppliers List.";
      dispatch({
        type: "GET_SUPPLIERS_LIST_FAIL",
        payload: error,
      });
    }
  };
// export const getSuppliersList = (currency, previousSupplier, module) => async (
//   dispatch
// ) => {
//   dispatch({
//     type: "GET_SUPPLIERS_LIST_INIT",
//   });
//   let url;
//   if (currency && module) {
//     url =
//       
//       `/DPFAPI/SupplierRequest?actionType=GetSuppliersList&currency=${currency}&module=${module}`;
//   } else if (module) {
//     url =
//       
//       `/DPFAPI/SupplierRequest?actionType=GetSuppliersList&module=${module}`;
//   } else if (previousSupplier) {
//     //in Search Page while getting the suppliers list then previous suppliers check will be used
//     url =
//       
//       `/DPFAPI/SupplierRequest?actionType=GetSuppliersList&previousSupplier=${previousSupplier}`;
//   } else {
//     url =
//       
//       "/DPFAPI/SupplierRequest?actionType=GetSuppliersList";
//   }

//   try {
//     // let response = getSuppliersList_api;

//     let response = await axiosClient.get(url);
//     let getSupplierResp =
//       (response && response.data && response.data.SupplierResponse) || "";
//     if (getSupplierResp && getSupplierResp.results.length > 0) {
//       if (
//         getSupplierResp.results[0] &&
//         getSupplierResp.results[0].status === "Failed"
//       ) {
//         dispatch({
//           type: "GET_SUPPLIERS_LIST_FAIL",
//           payload:
//             getSupplierResp.results[0].description ||
//             "Error While Getting Suppliers List.",
//         });
//       }
//       if (
//         getSupplierResp.results[0] &&
//         getSupplierResp.results[0].status === "Success"
//       ) {
//         dispatch({
//           type: "GET_SUPPLIERS_LIST_SUCCESS",
//           payload: getSupplierResp,
//         });
//       }
//     } else {
//       dispatch({
//         type: "GET_SUPPLIERS_LIST_FAIL",
//         payload: "Error While Getting Suppliers List.",
//       });
//     }
//   } catch (err) {
//     const error = err.message || "Error While Getting Suppliers List.";
//     dispatch({
//       type: "GET_SUPPLIERS_LIST_FAIL",
//       payload: error,
//     });
//   }
// };
//----------------------****Get Single Supplier ****-------------------------
export const getSupplier = (supplierGuid) => async (dispatch) => {
  dispatch({
    type: "GET_SUPPLIER_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  const supplierData = {
    actionType: "GetSupplier",
    guid: supplierGuid
  };
  try {
    // let response = getSupplier_api;

    let response = await axiosDPFAPIClient.post(url, supplierData);
    let getSupplierResp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (getSupplierResp && getSupplierResp.results.length > 0) {
      if (
        getSupplierResp.results[0] &&
        getSupplierResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_SUPPLIER_FAIL",
          payload:
            getSupplierResp.results[0].description ||
            "Error While Getting Supplier.",
        });
      }
      if (
        getSupplierResp.results[0] &&
        getSupplierResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_SUPPLIER_SUCCESS",
          payload: getSupplierResp,
        });
      }
    } else {
      dispatch({
        type: "GET_SUPPLIER_FAIL",
        payload: "Error While Getting Supplier.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Supplier.";
    dispatch({
      type: "GET_SUPPLIER_FAIL",
      payload: error,
    });
  }
};

/**
 * 
 * @param {string} supplierGuid
 * @returns {Promise<SupplierResponse>}
 */
export const getSupplierBankDetails = async (supplierGuid) => {
  const url = "/DPFAPI/SupplierRequest";
  const supplierData = {
    actionType: "GetSupplierBankDetails",
    guid: supplierGuid
  };
  const fallbackResponse = {
    SupplierResponse: {
      results: [
        {
          status: "Failed",
          description: "Unable to get the Bank Details of supplier. Try again"
        }
      ],
      bankDetails: {} 
    }
  };
  try {

    let response = await axiosDPFAPIClient.post(url, supplierData);
    const { SupplierResponse } = response?.data || fallbackResponse;
    return SupplierResponse;
  } catch (err) {
    const errorMessage = err?.message || "Error While Getting Supplier.";
    fallbackResponse.SupplierResponse.results[0].description = errorMessage;
    return fallbackResponse;
  }
};

//----------------------****Get Supplier Address****-------------------------
export const getSupplierAddress = (data) => async (dispatch) => {
  dispatch({
    type: "GET_SUPPLIER_ADDRESS_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const supplierData = {
    actionType: "GetSupplierAddress",
    guid: data,
  };
  try {
    // let response = getSupplier_api;

    let response = await axiosDPFAPIClient.post(url, supplierData);
    let getSupplierResp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (getSupplierResp && getSupplierResp.results.length > 0) {
      if (
        getSupplierResp.results[0] &&
        getSupplierResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_SUPPLIER_ADDRESS_FAIL",
          payload:
            getSupplierResp.results[0].description ||
            "Error While Getting Supplier.",
        });
      }
      if (
        getSupplierResp.results[0] &&
        getSupplierResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_SUPPLIER_ADDRESS_SUCCESS",
          payload: getSupplierResp,
        });
      }
    } else {
      dispatch({
        type: "GET_SUPPLIER_ADDRESS_FAIL",
        payload: "Error While Getting Supplier.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Supplier.";
    dispatch({
      type: "GET_SUPPLIER_ADDRESS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Supplier Address****-------------------------
export const updateSupplierAddress =
  (data, postalAddress, companyAddress, abn) => async (dispatch) => {
    dispatch({
      type: "UPDATE_SUPPLIER_ADDRESS_INIT",
    });
    const url = "/DPFAPI/SupplierRequest";

    const supplierData = {
      actionType: "UpdateSupplierAddress",
      supplierDetails: data,
      postalAddress: postalAddress,
      companyAddress: companyAddress,
      abn: abn,
    };
    try {
      // let response = getSupplier_api;

      let response = await axiosDPFAPIClient.put(url, supplierData);
      let getSupplierResp =
        (response && response.data && response.data.SupplierResponse) || "";
      if (getSupplierResp && getSupplierResp.results.length > 0) {
        if (
          getSupplierResp.results[0] &&
          getSupplierResp.results[0].status === "Failed"
        ) {
          dispatch({
            type: "UPDATE_SUPPLIER_ADDRESS_FAIL",
            payload:
              getSupplierResp.results[0].description ||
              "Error While Getting Supplier.",
          });
        }
        if (
          getSupplierResp.results[0] &&
          getSupplierResp.results[0].status === "Success"
        ) {
          dispatch({
            type: "UPDATE_SUPPLIER_ADDRESS_SUCCESS",
            payload: getSupplierResp,
          });
        }
      } else {
        dispatch({
          type: "UPDATE_SUPPLIER_FAIL",
          payload: "Error While Getting Supplier.",
        });
      }
    } catch (err) {
      const error = err.message || "Error While Getting Supplier.";
      dispatch({
        type: "UPDATE_SUPPLIER_FAIL",
        payload: error,
      });
    }
  };
//----------------------****Get Supplier Details ****------------------------
export const getSupplierDetails = (guid) => async (dispatch) => {
  dispatch({
    type: "GET_SUPPLIER_DETAILS_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const supplierData = {
    actionType: "GetSupplierDetails",
    guid: guid,
  };
  try {
    let response = await axiosDPFAPIClient.post(url, supplierData);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";

    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Failed") {
        dispatch({
          type: "GET_SUPPLIER_DETAILS_FAIL",
          payload:
            resp.results[0].description ||
            "Error While Getting Supplier Details",
        });
      }
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "GET_SUPPLIER_DETAILS_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "GET_SUPPLIER_DETAILS_FAIL",
        payload: "Error While Getting Suppliers Details",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Suppliers Details";
    dispatch({
      type: "GET_SUPPLIER_DETAILS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Supplier summery *****-----------------------
export const getSupplierSummary = (supplierGuid) => async (dispatch) => {
  console.log(supplierGuid);
  dispatch({
    type: "GET_SUPPLIER_SUMMARY_INIT",
  });
  const dataObj = {
    actionType: "GetSupplierSummary",
    guid: supplierGuid
  };
  const url = "/DPFAPI/SupplierRequest";

  try {
    let response = await axiosDPFAPIClient.post(url, dataObj);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Failed") {
        dispatch({
          type: "GET_SUPPLIER_SUMMARY_FAIL",
          payload:
            resp.results[0].description ||
            "Error While Getting Supplier Summary.",
        });
      }
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "GET_SUPPLIER_SUMMARY_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "GET_SUPPLIER_SUMMARY_FAIL",
        payload: "Error While Getting Supplier Summary.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Supplier Summary.";
    dispatch({
      type: "GET_SUPPLIER_SUMMARY_FAIL",
      payload: error,
    });
  }
};
//----------------------****Update Supplier Details ****---------------------
export const updateSupplierDetails = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_SUPPLIER_DETAILS_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const supplierData = {
    actionType: "UpdateSupplierDetails",
    ...data,
  };
  try {
    let response = await axiosDPFAPIClient.post(url, supplierData);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";

    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Failed") {
        dispatch({
          type: "UPDATE_SUPPLIER_DETAILS_FAIL",
          payload:
            resp.results[0].description ||
            "Error While Updating Supplier Details",
        });
      }
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "UPDATE_SUPPLIER_DETAILS_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "UPDATE_SUPPLIER_DETAILS_FAIL",
        payload: "Error While Updating Supplier Details",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Supplier Details";
    dispatch({
      type: "UPDATE_SUPPLIER_DETAILS_FAIL",
      payload: error,
    });
  }
};
//--------------------getSupplierApprovalGroup-------------------------
export const primeBasicSupplier = () => async (dispatch) => {
  dispatch({
    type: "GET_PRIME_BASIC_SUPPLIER_INIT",
  });
  let url = `/DPFAPI/SupplierRequest?actionType=PrimeBasicSupplier`;
  try {
    let response = await axiosDPFAPIClient.get(url);

    let primeBasicSupplier =
      (response && response.data && response.data.SupplierResponse) || "";
    if (primeBasicSupplier && primeBasicSupplier.results.length > 0) {
      if (
        primeBasicSupplier.results[0] &&
        primeBasicSupplier.results[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_PRIME_BASIC_SUPPLIER_FAIL",
          payload:
            primeBasicSupplier.results[0].description ||
            "Error While Getting PO List.",
        });
      }
      if (
        primeBasicSupplier.results[0] &&
        primeBasicSupplier.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_PRIME_BASIC_SUPPLIER_SUCCESS",
          payload: primeBasicSupplier,
        });
      }
    } else {
      dispatch({
        type: "GET_PRIME_BASIC_SUPPLIER_FAIL",
        payload: "Error While Getting PO List.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting PO List.";
    dispatch({
      type: "GET_PRIME_BASIC_SUPPLIER_FAIL",
      payload: error,
    });
  }
};
//----------------------****Call prime Supplier Details ****-----------------
export const primeSupplierDetails = () => async (dispatch) => {
  dispatch({
    type: "PRIME_SUPPLIER_DETAILS_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const supplierData = {
    actionType: "PrimeSupplierDetails",
  };
  try {
    let response = await axiosDPFAPIClient.post(url, supplierData);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";

    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Failed") {
        dispatch({
          type: "PRIME_SUPPLIER_DETAILS_FAIL",
          payload:
            resp.results[0].description ||
            "Error While Calling the Prime Suppliers Details ",
        });
      }
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "PRIME_SUPPLIER_DETAILS_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "PRIME_SUPPLIER_DETAILS_FAIL",
        payload: "Error While Calling Prime Suppliers Details",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Calling Prime Suppliers Details";
    dispatch({
      type: "PRIME_SUPPLIER_DETAILS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Call single Supplier Details ****-----------------
export const getSingleSupplierDetails =
  (supplierGuid) => async (dispatch) => {
    dispatch({
      type: "GET_SINGLE_SUPPLIER_DETAILS_INIT",
    });
    const url = "/DPFAPI/SupplierRequest";

    const supplierData = {
      actionType: "GetSupplierDetails",
      guid: supplierGuid
    };

    try {
      let response = await axiosDPFAPIClient.post(url, supplierData);
      let resp =
        (response && response.data && response.data.SupplierResponse) || "";

      if (resp && resp.results.length > 0) {
        if (resp.results[1] && resp.results[1].status === "Failed") {
          dispatch({
            type: "GET_SINGLE_SUPPLIER_DETAILS_FAIL",
            payload:
              resp.results[1].description ||
              "Error While Calling the Prime Suppliers Details ",
          });
        }
        if (resp.results[1] && resp.results[1].status === "Success") {
          dispatch({
            type: "GET_SINGLE_SUPPLIER_DETAILS_SUCCESS",
            payload: resp,
          });
        }
      } else {
        dispatch({
          type: "GET_SINGLE_SUPPLIER_DETAILS_FAIL",
          payload: "Error While Calling Prime Suppliers Details",
        });
      }
    } catch (err) {
      const error =
        err.message || "Error While Calling Prime Suppliers Details";
      dispatch({
        type: "GET_SINGLE_SUPPLIER_DETAILS_FAIL",
        payload: error,
      });
    }
  };
//----------------------****Insert Supplier Details ****---------------------
export const insertSupplierDetails = (data) => async (dispatch) => {
  dispatch({
    type: "INSERT_SUPPLIER_DETAILS_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const supplierData = {
    actionType: "InsertSupplierDetails",
    ...data,
  };
  try {
    let response = await axiosDPFAPIClient.post(url, supplierData);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";

    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Failed") {
        dispatch({
          type: "INSERT_SUPPLIER_DETAILS_FAIL",
          payload:
            resp.results[0].description ||
            "Error While Inserting Supplier Details",
        });
      }
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "INSERT_SUPPLIER_DETAILS_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "INSERT_SUPPLIER_DETAILS_FAIL",
        payload: "Error While Inserting Supplier Details",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Inserting Supplier Details";
    dispatch({
      type: "INSERT_SUPPLIER_DETAILS_FAIL",
      payload: error,
    });
  }
};

//----------------------****Delete Supplier *****----------------------------
export const deleteSupplier = (activatedGuid) => async (dispatch) => {
  dispatch({
    type: "DELETE_SUPPLIER_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "DeleteSupplier",
    guid: activatedGuid
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "DELETE_SUPPLIER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DELETE_SUPPLIER_FAIL",
          payload:
            resp.results[0].description || "Error While Deleting supplier.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_SUPPLIER_FAIL",
        payload: "Error While Deleting supplier.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting supplier.";
    dispatch({
      type: "DELETE_SUPPLIER_FAIL",
      payload: error,
    });
  }
};
//----------------------****Send for Approval  *****------------------
export const sendForApproval = (supplierGuid) => async (dispatch) => {
  dispatch({
    type: "SEND_FOR_APPROVAL_SUPPLIER_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "SendForApproval",
    guidList: supplierGuid
  };

  try {
    let result = await axiosDPFAPIClient.post(url, data);
    let resp = (result && result.data && result.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "SEND_FOR_APPROVAL_SUPPLIER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "SEND_FOR_APPROVAL_SUPPLIER_FAIL",
          payload:
            resp.results[0].description ||
            "Error While Sending supplier For Approval.",
        });
      }
    } else {
      dispatch({
        type: "SEND_FOR_APPROVAL_SUPPLIER_FAIL",
        payload: "Error While Sending supplier For Approval.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Sending supplier For Approval.";
    dispatch({
      type: "SEND_FOR_APPROVAL_SUPPLIER_FAIL",
      payload: error,
    });
  }
};

//----------------------****Update Supplier ****-----------------------------
export const updateSupplier = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_SUPPLIER_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const supplierData = {
    actionType: "UpdateSupplier",
    ...data,
  };
  try {
    // let response = updateSupplier_api;

    let response = await axiosDPFAPIClient.post(url, supplierData);
    let updateSupplierResp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (updateSupplierResp && updateSupplierResp.results.length > 0) {
      if (
        updateSupplierResp.results[0] &&
        updateSupplierResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "UPDATE_SUPPLIER_FAIL",
          payload:
            updateSupplierResp.results[0].description ||
            "Error While Updating Supplier.",
        });
      }
      if (
        updateSupplierResp.results[0] &&
        updateSupplierResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "UPDATE_SUPPLIER_SUCCESS",
          payload: updateSupplierResp,
        });
      }
    } else {
      dispatch({
        type: "UPDATE_SUPPLIER_FAIL",
        payload: "Error While Updating Supplier.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Supplier.";
    dispatch({
      type: "UPDATE_SUPPLIER_FAIL",
      payload: error,
    });
  }
};
//---------------------****Insert Supplier ****------------------------------
export const insertSupplier = (data) => async (dispatch) => {
  dispatch({
    type: "INSERT_SUPPLIER_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const supplierData = {
    actionType: "InsertSupplier",
    ...data,
  };
  try {
    // let response = insertSupplier_api;

    let response = await axiosDPFAPIClient.post(url, supplierData);
    let insertSupplierResp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (insertSupplierResp && insertSupplierResp.results.length > 0) {
      if (
        insertSupplierResp.results[0] &&
        insertSupplierResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "INSERT_SUPPLIER_FAIL",
          payload:
            insertSupplierResp.results[0].description ||
            "Error While inserting Supplier.",
        });
      }
      if (
        insertSupplierResp.results[0] &&
        insertSupplierResp.results[0].status === "Success"
      ) {
        let insertedData = data;
        let obj = {
          insertedData,
          insertSupplierResp,
        };
        dispatch({
          type: "INSERT_SUPPLIER_SUCCESS",
          payload: obj,
        });
      }
    } else {
      dispatch({
        type: "INSERT_SUPPLIER_FAIL",
        payload: "Error While inserting Supplier.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While inserting Supplier.";
    dispatch({
      type: "INSERT_SUPPLIER_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get  Supplier's contacts ****--------------------
export const getSupplierContacts = (supplierGuid) => async (dispatch) => {
  dispatch({
    type: "GET_SUPPLIER_CONTACTS_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const supplierData = {
    actionType: "GetSupplierContacts",
    guid: supplierGuid
  };
  try {
    let response = await axiosDPFAPIClient.post(url, supplierData);
    let getSupplierContactsResp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (getSupplierContactsResp && getSupplierContactsResp.results.length > 0) {
      if (
        getSupplierContactsResp.results[0] &&
        getSupplierContactsResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_SUPPLIER_CONTACTS_FAIL",
          payload:
            getSupplierContactsResp.results[0].description ||
            "Error While Getting Suppliers Contacts.",
        });
      }
      if (
        getSupplierContactsResp.results[0] &&
        getSupplierContactsResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_SUPPLIER_CONTACTS_SUCCESS",
          payload: getSupplierContactsResp,
        });
      }
    } else {
      dispatch({
        type: "GET_SUPPLIER_CONTACTS_FAIL",
        payload: "Error While Getting Suppliers Contacts.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Suppliers Contacts.";
    dispatch({
      type: "GET_SUPPLIER_CONTACTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Update Supplier's Contact ****-------------------
export const updateSuppliersContact = (contact) => async (dispatch) => {
  dispatch({
    type: "UPDATE_SUPPLIER_CONTACT_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const contactData = {
    actionType: "UpdateSupplierContact",
    ...contact,
  };
  try {
    // let response = updateSupplier_api;

    let response = await axiosDPFAPIClient.post(url, contactData);
    let updateSuppliersContactResp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (
      updateSuppliersContactResp &&
      updateSuppliersContactResp.results.length > 0
    ) {
      if (
        updateSuppliersContactResp.results[0] &&
        updateSuppliersContactResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "UPDATE_SUPPLIER_CONTACT_FAIL",
          payload:
            updateSuppliersContactResp.results[0].description ||
            "Error While Updating Supplier's Contact.",
        });
      }
      if (
        updateSuppliersContactResp.results[0] &&
        updateSuppliersContactResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "UPDATE_SUPPLIER_CONTACT_SUCCESS",
          payload: updateSuppliersContactResp,
        });
      }
    } else {
      dispatch({
        type: "UPDATE_SUPPLIER_CONTACT_FAIL",
        payload: "Error While Updating Supplier's Contact.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Supplier's Contact.";
    dispatch({
      type: "UPDATE_SUPPLIER_CONTACT_FAIL",
      payload: error,
    });
  }
};
//----------------------****Update Supplier's Contact ****-------------------
export const addSuppliersContact = (contact) => async (dispatch) => {
  dispatch({
    type: "ADD_SUPPLIER_CONTACT_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const contactData = {
    actionType: "AddSupplierContact",
    ...contact,
  };
  try {
    let response = await axiosDPFAPIClient.post(url, contactData);
    let addSuppliersContactResp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (addSuppliersContactResp && addSuppliersContactResp.results.length > 0) {
      if (
        addSuppliersContactResp.results[0] &&
        addSuppliersContactResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "ADD_SUPPLIER_CONTACT_FAIL",
          payload:
            addSuppliersContactResp.results[0].description ||
            "Error While Adding Supplier's Contact.",
        });
      }
      if (
        addSuppliersContactResp.results[0] &&
        addSuppliersContactResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "ADD_SUPPLIER_CONTACT_SUCCESS",
          payload: addSuppliersContactResp,
        });
      }
    } else {
      dispatch({
        type: "ADD_SUPPLIER_CONTACT_FAIL",
        payload: "Error While Adding Supplier's Contact.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Adding Supplier's Contact.";
    dispatch({
      type: "ADD_SUPPLIER_CONTACT_FAIL",
      payload: error,
    });
  }
};
//----------------------****Delete Supplier's Contact ****-------------------
export const deleteSuppliersContact = (contactID) => async (dispatch) => {
  dispatch({
    type: "DELETE_SUPPLIER_CONTACT_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";

  const contactData = {
    actionType: "DeleteSupplierContact",
    contactID,
  };
  try {
    let response = await axiosDPFAPIClient.post(url, contactData);
    let deleteSuppliersContactResp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (
      deleteSuppliersContactResp &&
      deleteSuppliersContactResp.results.length > 0
    ) {
      if (
        deleteSuppliersContactResp.results[0] &&
        deleteSuppliersContactResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "DELETE_SUPPLIER_CONTACT_FAIL",
          payload:
            deleteSuppliersContactResp.results[0].description ||
            "Error While Deleting Supplier's Contact.",
        });
      }
      if (
        deleteSuppliersContactResp.results[0] &&
        deleteSuppliersContactResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "DELETE_SUPPLIER_CONTACT_SUCCESS",
          payload: deleteSuppliersContactResp,
        });
      }
    } else {
      dispatch({
        type: "DELETE_SUPPLIER_CONTACT_FAIL",
        payload: "Error While Deleting Supplier's Contact.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Supplier's Contact.";
    dispatch({
      type: "DELETE_SUPPLIER_CONTACT_FAIL",
      payload: error,
    });
  }
};
//----------------------****Add Supplier Attachments*****--------------------
export const addSupAttachments = (obj) => async (dispatch) => {
  dispatch({
    type: "ADD_SUP_ATTACHMENTS_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "AddAttachment",
    ...obj,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Failed") {
        dispatch({
          type: "ADD_SUP_ATTACHMENTS_FAIL",
          payload:
            resp.results[0].description || "Error While Uploading Attachments.",
        });
      }
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "ADD_SUP_ATTACHMENTS_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "ADD_SUP_ATTACHMENTS_FAIL",
        payload: "Error While Uploading Attachments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Uploading Attachments.";
    dispatch({
      type: "ADD_SUP_ATTACHMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Supplier's Attachment*****-------------------
export const getAttachment = (recordID) => async (dispatch) => {
  dispatch({
    type: "GET_SUP_ATTACHMENT_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "GetAttachment",
    recordID,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Failed") {
        dispatch({
          type: "GET_SUP_ATTACHMENT_FAIL",
          payload:
            resp.results[0].description || "Error While Getting Attachment.",
        });
      }
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "GET_SUP_ATTACHMENT_SUCCESS",
          payload: resp,
        });
      }
    } else {
      dispatch({
        type: "GET_SUP_ATTACHMENT_FAIL",
        payload: "Error While Getting Attachment.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Attachment.";
    dispatch({
      type: "GET_SUP_ATTACHMENT_FAIL",
      payload: error,
    });
  }
};
//----------------------****Delete Supplier Attachments*****-----------------
export const deleteSupAttachments = (recordID) => async (dispatch) => {
  dispatch({
    type: "DELETE_SUP_ATTACHMENTS_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "DeleteAttachment",
    recordID,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "DELETE_SUP_ATTACHMENTS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DELETE_SUP_ATTACHMENTS_FAIL",
          payload:
            resp.results[0].description || "Error While Deleting Attachments.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_SUP_ATTACHMENTS_FAIL",
        payload: "Error While Deleting Attachments.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Attachments.";
    dispatch({
      type: "DELETE_SUP_ATTACHMENTS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Unlock Supplier*****----------------------------
export const unlockSupplier = (selectedGuid) => async (dispatch) => {
  dispatch({
    type: "UNLOCK_SUPPLIER_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "UnlockSupplier",
    guid: selectedGuid,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "UNLOCK_SUPPLIER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "UNLOCK_SUPPLIER_FAIL",
          payload:
            resp.results[0].description || "Error While Unloking The Suppleir.",
        });
      }
    } else {
      dispatch({
        type: "UNLOCK_SUPPLIER_FAIL",
        payload: "Error While Unloking The Suppleir.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Unloking The Suppleir.";
    dispatch({
      type: "UNLOCK_SUPPLIER_FAIL",
      payload: error,
    });
  }
};
//----------------------****Approve Supplier*****----------------------------
export const approveSupplier = (selectedGuid) => async (dispatch) => {
  dispatch({
    type: "APPROVE_SUPPLIER_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "ApproveSupplier",
    guid: selectedGuid
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "APPROVE_SUPPLIER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "APPROVE_SUPPLIER_FAIL",
          payload:
            resp.results[0].description ||
            "Error While Approving The Suppleir.",
        });
      }
    } else {
      dispatch({
        type: "APPROVE_SUPPLIER_FAIL",
        payload: "Error While Approving The Suppleir.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Approving The Suppleir.";
    dispatch({
      type: "APPROVE_SUPPLIER_FAIL",
      payload: error,
    });
  }
};
//----------------------****move Supplier*****----------------------------
export const moveSupplier = (supplierGuid) => async (dispatch) => {
  dispatch({
    type: "MOVESUPPLIER_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "MoveSupplier",
    guidList: supplierGuid
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let resp =
      (response && response.data && response.data.SupplierResponse) || "";

    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "MOVESUPPLIER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "MOVESUPPLIER_FAIL",
          payload:
            resp.results[0].description || "Error While Move The Supplier.",
        });
      }
    } else {
      dispatch({
        type: "MOVESUPPLIER_FAIL",
        payload: "Error While Move The Supplier.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Move The Supplier.";
    dispatch({
      type: "MOVESUPPLIER_FAIL",
      payload: error,
    });
  }
};
//----------------------****decline Supplier*****----------------------------
export const declineSupplier = (supplierGuid) => async (dispatch) => {
  dispatch({
    type: "DECLINE_SUPPLIER_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "DeclineSupplier",
    guidList: supplierGuid
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let resp =
      (response && response.data && response.data.SupplierResponse) || "";

    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "DECLINE_SUPPLIER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "DECLINE_SUPPLIER_FAIL",
          payload:
            resp.results[0].description || "Error While Decline The Supplier.",
        });
      }
    } else {
      dispatch({
        type: "DECLINE_SUPPLIER_FAIL",
        payload: "Error While Decline The Supplier.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Decline The Supplier.";
    dispatch({
      type: "DECLINE_SUPPLIER_FAIL",
      payload: error,
    });
  }
};

//----------------------****hold Supplier*****----------------------------
export const holdSupplier = (supplierGuid) => async (dispatch) => {
  dispatch({
    type: "DECLINE_SUPPLIER_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "HoldSupplier",
    guidList: supplierGuid
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let resp =
      (response && response.data && response.data.SupplierResponse) || "";

    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "HOLD_SUPPLIER_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "HOLD_SUPPLIER_FAIL",
          payload:
            resp.results[0].description || "Error While Hold The Supplier.",
        });
      }
    } else {
      dispatch({
        type: "HOLD_SUPPLIER_FAIL",
        payload: "Error While Hold The Supplier.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Hold The Supplier.";
    dispatch({
      type: "HOLD_SUPPLIER_FAIL",
      payload: error,
    });
  }
};
//----------------------****Get Supplier Activity*****------------------------
export const getSupplierActivity = (selectedGuid) => async (dispatch) => {
  dispatch({
    type: "GET_SUPPLIER_ACTIVITY_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "GetSupplierActivity",
    guid: selectedGuid
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "GET_SUPPLIER_ACTIVITY_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "GET_SUPPLIER_ACTIVITY_FAIL",
          payload:
            resp.results[0].description ||
            "Error While Getting Suppleir Activity.",
        });
      }
    } else {
      dispatch({
        type: "GET_SUPPLIER_ACTIVITY_FAIL",
        payload: "Error While Getting Suppleir Activity.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Suppleir Activity.";
    dispatch({
      type: "GET_SUPPLIER_ACTIVITY_FAIL",
      payload: error,
    });
  }
};
//----------------------****Export Suppliers*****-----------------------------
export const exportSuppliers = (supplierID) => async (dispatch) => {
  dispatch({
    type: "EXPORT_SUPPLIERS_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "ExportSuppliers",
    guidList: supplierID,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "EXPORT_SUPPLIERS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "EXPORT_SUPPLIERS_FAIL",
          payload:
            resp.results[0].description || "Error While Exporting Suppleirs.",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_SUPPLIERS_FAIL",
        payload: "Error While Exporting Suppleirs.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Exporting Suppleirs.";
    dispatch({
      type: "EXPORT_SUPPLIERS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Paste Suppliers*****-----------------------------
export const pasteSuppliers = (excelData) => async (dispatch) => {
  dispatch({
    type: "PASTE_SUPPLIERS_INIT",
  });
  const url = "/DPFAPI/SupplierRequest";
  let data = {
    actionType: "PasteSuppliers",
    excelData,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let resp =
      (response && response.data && response.data.SupplierResponse) || "";
    if (resp && resp.results.length > 0) {
      if (resp.results[0] && resp.results[0].status === "Success") {
        dispatch({
          type: "PASTE_SUPPLIERS_SUCCESS",
          payload: resp,
        });
      } else {
        dispatch({
          type: "PASTE_SUPPLIERS_FAIL",
          payload:
            resp.results[0].description || "Error While Pasting Suppleirs.",
        });
      }
    } else {
      dispatch({
        type: "PASTE_SUPPLIERS_FAIL",
        payload: "Error While Pasting Suppleirs.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Pasting Suppleirs.";
    dispatch({
      type: "PASTE_SUPPLIERS_FAIL",
      payload: error,
    });
  }
};
//----------------------****Clear Supplier States In Store****----------------
export function clearSupplierStates() {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_SUPPLIER_STATES",
    });
  };
}

/**
 * @typedef {Object} SupplierResponse
 * @property {Array.<Result>} results - An array of result objects.
 * @property {Object} bankDetails - An object containing bank details of the supplier.
 */

/**
 * @typedef {Object} Result
 * @property {string} status - The status of the result (e.g., "Failed").
 * @property {string} description - A description of the result.
 */