/* eslint-disable default-case */
import { toast } from "react-toastify";
import store from "../Store/index";
import $ from "jquery";
import FileSaver from "file-saver";
import countryList from "country-list";
import { checkCompany } from "../Actions/UserActions/UserActions";
import axiosDPFAPIClient from "./axiosDPFAPIClient";
import axiosAPIGatewayClient from "./axiosAPIGatewayClient";

//a function that checks  api error
export function handleAPIErr(error, props) {
  if (
    error === "Session has expired. Please login again." ||
    error === "User has not logged in."
  ) {
    store.dispatch({
      type: "CLEAR_STATES_AFTER_LOGOUT",
    });
    props.history.push("/login");
    toast.error(error);
  } else if (error === "User has not logged into a production.") {
    toast.error(error);
    props.history.push("/login-table");
  } else {
    //Netork Error || api error
    toast.error(error);
  }
}

export function clearFormErrors(formErrors) {
  Object.keys(formErrors).map(function (key) {
    formErrors[key] = "";
  });
  return formErrors;
}
//DataTable Save Settings
export function handleSaveSettings(columns, name, pageLength) {
  //columns -> table columns, name -> table name
  let aoColumns =
    window
      .$("#" + name)
      .DataTable()
      .settings()[0].aoColumns || [];
  columns.map((c, i) => {
    if (c.hide) {
      let col = aoColumns.find((a) => a.sName === c.name);
      if (col) {
        window
          .$("#" + name)
          .DataTable()
          .column(col.idx)
          .visible(false);
      }
    } else {
      let col = aoColumns.find((a) => a.sName === c.name);
      if (col) {
        window
          .$("#" + name)
          .DataTable()
          .column(col.idx)
          .visible(true);
      }
    }
  });
  window
    .$("#" + name)
    .DataTable()
    .page.len(Number(pageLength))
    .draw();
  toast.success("Settings updated successfully.");
}
//DataTable Close Settings
export function handleCloseSettingModal(columns, name) {
  //columns -> table columns, name -> table name
  let data = JSON.parse(localStorage.getItem("DataTables_" + name));
  //update state to show which columns are hidden
  let cols =
    window
      .$("#" + name)
      .DataTable()
      .settings()[0].aoColumns || [];
  columns.map((c, i) => {
    let col = cols.find((a) => a.sName === c.name);
    if (col && !col.bVisible) {
      c.hide = true;
    } else {
      c.hide = false;
    }
  });
  return { pageLength: data.length || 10, columns };
}
//DataTable Main tables Initialization
export function tableSetting(columns, aoColumns, name) {
  // Clear search input values
  function clearSearchInput() {
    const dataTable = window.$("#" + name).DataTable();
    dataTable.search("").draw();
  }

  let pasted = true;
  let table;
  table = window.$("#" + name).DataTable({
    // language: {
    //   searchPlaceholder: "Search",
    // },
    dom: "Rlfrtip",
    aoColumns, //default table Columns
    stateSave: true,
    scrollX: true,
    stateSaveCallback: function (settings, data) {
      localStorage.setItem("DataTables_" + name, JSON.stringify(data));
    },
    stateLoadCallback: function (settings) {
      return JSON.parse(localStorage.getItem("DataTables_" + name));
    },
    order: [[1, "asc"]],
    colReorder: {
      fixedColumnsRight: 1,
      fixedColumnsLeft: 1,
    },
  });

  clearSearchInput();
  //update state to show which columns are hidden
  let cols =
    window
      .$("#" + name)
      .DataTable()
      .settings()[0].aoColumns || [];
  columns.map((c, i) => {
    let col = cols.find((a) => a.sName === c.name);
    if (col && !col.bVisible) {
      c.hide = true;
    } else {
      c.hide = false;
    }
  });

  let pageLength =
    window
      .$("#" + name)
      .DataTable()
      .page.len() || 10;
  return { pageLength };
}
//DataTable Main tables Initialization
export function tableSettingTest(columns, aoColumns, name) {
  // Clear search input values
  function clearSearchInput() {
    const dataTable = window.$("#" + name).DataTable();
    dataTable.search("").draw();
  }

  window.$("#" + name).DataTable({
    // language: {
    //   searchPlaceholder: "Search",
    // },
    dom: "Rlfrtip",
    aoColumns, //default table Columns
    stateSave: true,
    scrollX: true,
    destroy: true,
    stateSaveCallback: function (settings, data) {
      localStorage.setItem("DataTables_" + name, JSON.stringify(data));
    },
    stateLoadCallback: function (settings) {
      return JSON.parse(localStorage.getItem("DataTables_" + name));
    },
    order: [[1, "asc"]],
    colReorder: {
      fixedColumnsRight: 1,
      fixedColumnsLeft: 1,
    },
  });
  clearSearchInput();
  //update state to show which columns are hidden
  let cols =
    window
      .$("#" + name)
      .DataTable()
      .settings()[0].aoColumns || [];
  columns.map((c, i) => {
    let col = cols.find((a) => a.sName === c.name);
    if (col && !col.bVisible) {
      c.hide = true;
    } else {
      c.hide = false;
    }
  });

  let pageLength =
    window
      .$("#" + name)
      .DataTable()
      .page.len() || 10;

  return { pageLength };
}
//datatable filter card functionality
export function filterBox(name) {
  $(document).ready(function () {
    $("body").on("mouseover", `#${name}_filter label`, function (e) {
      // setTimeout(function () {
      $(`#object1`).remove();
      $(`#${name}_filter label`).append('<div id="object1"></div>');
      // }, 500);
    });

    $("body").on("mouseover", `#object1`, function (e) {
      e.preventDefault();
      e.stopPropagation();
    });

    $("body").on("click", "#object1", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $("#filter_dropdpwn1").toggle();
      $("#filter_dropdpwn2").hide();
    });
    $(".plus_icon-filter_bottom").click(function () {
      $("#filter_dropdpwn2").toggle();
    });
    $("body").on("click", ".pop-cros-1", function () {
      $("#filter_dropdpwn2").hide();
      $("#filter_dropdpwn1").hide();
    });
    $(".close_top_sec").click(function () {
      $(".user_setup_headerbox").hide();
    });
  });

  // $(document).ready(function () {
  // $("body").on("click", `.user_setup_main #${name}_filter`, function (e) {
  //   setTimeout(function () {
  //     $(`#${name}_filter label`).append('<div id="object1"></div>');
  //   }, 500);
  // });
  // $("body").on("click", ".user_setup_main #object1", function (e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   $("#filter_dropdpwn1").toggle();
  //   $("#filter_dropdpwn2").hide();
  // });
  // $(".plus_icon-filter_bottom").click(function () {
  //   $("#filter_dropdpwn2").toggle();
  // });
  // $("body").on("click", ".pop-cros-1", function () {
  //   $("#filter_dropdpwn2").hide();
  //   $("#filter_dropdpwn1").hide();
  // });
  // $(".close_top_sec").click(function () {
  //   $(".user_setup_headerbox").hide();
  // });
  // });
}
/*******Invoice/Document/Expense pdf zoom functionality********/
export function zoomIn(scal) {
  let scaling = "";
  let dropdownZoomingValue = "";
  let zoom = "";
  switch (scal) {
    case 1.3:
      scaling = 1.6;
      dropdownZoomingValue = { label: "10%", value: "10%" };
      zoom = "10%";
      break;
    case 1.6:
      scaling = 1.9;
      dropdownZoomingValue = { label: "15%", value: "15%" };
      zoom = "15%";
      break;
    case 1.9:
      scaling = 2.2;
      dropdownZoomingValue = { label: "20%", value: "20%" };
      zoom = "20%";
      break;
    case 2.2:
      scaling = 2.5;
      dropdownZoomingValue = { label: "25%", value: "25%" };
      zoom = "25%";
      break;
    case 2.5:
      scaling = 2.8;
      dropdownZoomingValue = { label: "30%", value: "30%" };
      zoom = "30%";
      break;
    case 2.8:
      scaling = 3.1;
      dropdownZoomingValue = { label: "35%", value: "35%" };
      zoom = "35%";
      break;
    case 3.1:
      scaling = 3.4;
      dropdownZoomingValue = { label: "40%", value: "40%" };
      zoom = "40%";
      break;
    case 3.4:
      scaling = 3.7;
      dropdownZoomingValue = { label: "45%", value: "45%" };
      zoom = "45%";
      break;
    case 3.7:
      scaling = 4;
      dropdownZoomingValue = { label: "50%", value: "50%" };
      zoom = "50%";
      break;
    case 4:
      scaling = 4.3;
      dropdownZoomingValue = { label: "55%", value: "55%" };
      zoom = "55%";
      break;
    case 4.3:
      scaling = 4.6;
      dropdownZoomingValue = { label: "60%", value: "60%" };
      zoom = "60%";
      break;
    case 4.6:
      scaling = 4.9;
      dropdownZoomingValue = { label: "65%", value: "65%" };
      zoom = "65%";
      break;
    case 4.9:
      scaling = 5.2;
      dropdownZoomingValue = { label: "70%", value: "70%" };
      zoom = "70%";
      break;
    case 5.2:
      scaling = 5.5;
      dropdownZoomingValue = { label: "75%", value: "75%" };
      zoom = "75%";
      break;
    case 5.5:
      scaling = 5.8;
      dropdownZoomingValue = { label: "80%", value: "80%" };
      zoom = "80%";
      break;
    case 5.8:
      scaling = 6.1;
      dropdownZoomingValue = { label: "85%", value: "85%" };
      zoom = "85%";
      break;
    case 6.1:
      scaling = 6.4;
      dropdownZoomingValue = { label: "90%", value: "90%" };
      zoom = "90%";
      break;
    case 6.4:
      scaling = 6.7;
      dropdownZoomingValue = { label: "95%", value: "95%" };
      zoom = "95%";
      break;
    case 6.7:
    case 7:
      scaling = 7;
      dropdownZoomingValue = { label: "100%", value: "100%" };
      zoom = "100%";
      break;
  }

  return {
    scale: scaling,
    dropdownZoomingValue,
    zoom,
  };
}
export function zoomOut(scal) {
  let scaling = "";
  let dropdownZoomingValue = "";
  let zoom = "";
  switch (scal) {
    case 1.6:
    case 1.3:
      scaling = 1.3;
      dropdownZoomingValue = { label: "5%", value: "5%" };
      zoom = "5%";
      break;
    case 1.9:
      scaling = 1.6;
      dropdownZoomingValue = { label: "10%", value: "10%" };
      zoom = "10%";
      break;
    case 2.2:
      scaling = 1.9;
      dropdownZoomingValue = { label: "15%", value: "15%" };
      zoom = "15%";
      break;
    case 2.5:
      scaling = 2.2;
      dropdownZoomingValue = { label: "20%", value: "20%" };
      zoom = "20%";
      break;
    case 2.8:
      scaling = 2.5;
      dropdownZoomingValue = { label: "25%", value: "25%" };
      zoom = "25%";
      break;
    case 3.1:
      scaling = 2.8;
      dropdownZoomingValue = { label: "30%", value: "30%" };
      zoom = "30%";
      break;
    case 3.4:
      scaling = 3.1;
      dropdownZoomingValue = { label: "35%", value: "35%" };
      zoom = "35%";
      break;
    case 3.7:
      scaling = 3.4;
      dropdownZoomingValue = { label: "40%", value: "40%" };
      zoom = "40%";
      break;
    case 4:
      scaling = 3.7;
      dropdownZoomingValue = { label: "45%", value: "45%" };
      zoom = "45%";
      break;
    case 4.3:
      scaling = 4;
      dropdownZoomingValue = { label: "50%", value: "50%" };
      zoom = "50%";
      break;
    case 4.6:
      scaling = 4.3;
      dropdownZoomingValue = { label: "55%", value: "55%" };
      zoom = "55%";
      break;
    case 4.9:
      scaling = 4.6;
      dropdownZoomingValue = { label: "60%", value: "60%" };
      zoom = "60%";
      break;
    case 5.2:
      scaling = 4.9;
      dropdownZoomingValue = { label: "65%", value: "65%" };
      zoom = "65%";
      break;
    case 5.5:
      scaling = 5.2;
      dropdownZoomingValue = { label: "70%", value: "70%" };
      zoom = "70%";
      break;
    case 5.8:
      scaling = 5.5;
      dropdownZoomingValue = { label: "75%", value: "75%" };
      zoom = "75%";
      break;
    case 6.1:
      scaling = 5.8;
      dropdownZoomingValue = { label: "80%", value: "80%" };
      zoom = "80%";
      break;
    case 6.4:
      scaling = 6.1;
      dropdownZoomingValue = { label: "85%", value: "85%" };
      zoom = "85%";
      break;
    case 6.7:
      scaling = 6.4;
      dropdownZoomingValue = { label: "90%", value: "90%" };
      zoom = "90%";
      break;
    case 7:
      scaling = 6.7;
      dropdownZoomingValue = { label: "95%", value: "95%" };
      zoom = "95%";
      break;
  }

  return {
    scale: scaling,
    dropdownZoomingValue,
    zoom,
  };
}
export function handleDropdownZooming(val) {
  let scaling = "";
  let dropdownZoomingValue = "";
  let zoom = "";
  switch (val) {
    case "5%":
      scaling = 1.3;
      dropdownZoomingValue = { label: "5%", value: "5%" };
      zoom = "5%";
      break;
    case "10%":
      scaling = 1.6;
      dropdownZoomingValue = { label: "10%", value: "10%" };
      zoom = "10%";
      break;
    case "15%":
      scaling = 1.9;
      dropdownZoomingValue = { label: "15%", value: "15%" };
      zoom = "15%";
      break;
    case "20%":
      scaling = 2.2;
      dropdownZoomingValue = { label: "20%", value: "20%" };
      zoom = "20%";
      break;
    case "25%":
      scaling = 2.5;
      dropdownZoomingValue = { label: "25%", value: "25%" };
      zoom = "25%";
      break;
    case "30%":
      scaling = 2.8;
      dropdownZoomingValue = { label: "30%", value: "30%" };
      zoom = "30%";
      break;
    case "35%":
      scaling = 3.1;
      dropdownZoomingValue = { label: "35%", value: "35%" };
      zoom = "35%";
      break;
    case "40%":
      scaling = 3.4;
      dropdownZoomingValue = { label: "40%", value: "40%" };
      zoom = "40%";
      break;
    case "45%":
      scaling = 3.7;
      dropdownZoomingValue = { label: "45%", value: "45%" };
      zoom = "45%";
      break;
    case "50%":
      scaling = 4;
      dropdownZoomingValue = { label: "50%", value: "50%" };
      zoom = "50%";
      break;
    case "55%":
      scaling = 4.3;
      dropdownZoomingValue = { label: "55%", value: "55%" };
      zoom = "55%";
      break;
    case "60%":
      scaling = 4.6;
      dropdownZoomingValue = { label: "60%", value: "60%" };
      zoom = "60%";
      break;
    case "65%":
      scaling = 4.9;
      dropdownZoomingValue = { label: "65%", value: "65%" };
      zoom = "65%";
      break;
    case "70%":
      scaling = 5.2;
      dropdownZoomingValue = { label: "70%", value: "70%" };
      zoom = "70%";
      break;
    case "75%":
      scaling = 5.5;
      dropdownZoomingValue = { label: "75%", value: "75%" };
      zoom = "75%";
      break;
    case "80%":
      scaling = 5.8;
      dropdownZoomingValue = { label: "80%", value: "80%" };
      zoom = "80%";
      break;
    case "85%":
      scaling = 6.1;
      dropdownZoomingValue = { label: "85%", value: "85%" };
      zoom = "85%";
      break;
    case "90%":
      scaling = 6.4;
      dropdownZoomingValue = { label: "90%", value: "90%" };
      zoom = "90%";
      break;
    case "95%":
      scaling = 6.7;
      dropdownZoomingValue = { label: "95%", value: "95%" };
      zoom = "95%";
      break;
    case "100%":
      scaling = 7;
      dropdownZoomingValue = { label: "100%", value: "100%" };
      zoom = "100%";
      break;
  }

  return {
    scale: scaling,
    dropdownZoomingValue,
    zoom,
  };
}
/*******END********/
/*******Document Form & Add New Invoice pdf zomom functionality********/
export function pdfViewerZoomIn(scal) {
  let scaling = "";
  let dropdownZoomingValue = "";
  let zoom = "";
  switch (scal) {
    case 0.5:
      scaling = 0.8;
      dropdownZoomingValue = { label: "10%", value: "10%" };
      zoom = "10%";
      break;
    case 0.8:
      scaling = 1.1;
      dropdownZoomingValue = { label: "15%", value: "15%" };
      zoom = "15%";
      break;
    case 1.1:
      scaling = 1.4;
      dropdownZoomingValue = { label: "20%", value: "20%" };
      zoom = "20%";
      break;
    case 1.4:
    case 1.3:
      scaling = 1.7;
      dropdownZoomingValue = { label: "25%", value: "25%" };
      zoom = "25%";
      break;
    case 1.7:
      scaling = 2;
      dropdownZoomingValue = { label: "30%", value: "30%" };
      zoom = "30%";
      break;
    case 2:
      scaling = 2.3;
      dropdownZoomingValue = { label: "35%", value: "35%" };
      zoom = "35%";
      break;
    case 2.3:
      scaling = 2.6;
      dropdownZoomingValue = { label: "40%", value: "40%" };
      zoom = "40%";
      break;
    case 2.6:
      scaling = 2.9;
      dropdownZoomingValue = { label: "45%", value: "45%" };
      zoom = "45%";
      break;
    case 2.9:
      scaling = 3.2;
      dropdownZoomingValue = { label: "50%", value: "50%" };
      zoom = "50%";
      break;
    case 3.2:
      scaling = 3.5;
      dropdownZoomingValue = { label: "55%", value: "55%" };
      zoom = "55%";
      break;
    case 3.5:
      scaling = 3.8;
      dropdownZoomingValue = { label: "60%", value: "60%" };
      zoom = "60%";
      break;
    case 3.8:
      scaling = 4.1;
      dropdownZoomingValue = { label: "65%", value: "65%" };
      zoom = "65%";
      break;
    case 4.1:
      scaling = 4.4;
      dropdownZoomingValue = { label: "70%", value: "70%" };
      zoom = "70%";
      break;
    case 4.4:
      scaling = 4.7;
      dropdownZoomingValue = { label: "75%", value: "75%" };
      zoom = "75%";
      break;
    case 4.7:
      scaling = 5;
      dropdownZoomingValue = { label: "80%", value: "80%" };
      zoom = "80%";
      break;
    case 5:
      scaling = 5.3;
      dropdownZoomingValue = { label: "85%", value: "85%" };
      zoom = "85%";
      break;
    case 5.3:
      scaling = 5.6;
      dropdownZoomingValue = { label: "90%", value: "90%" };
      zoom = "90%";
      break;
    case 5.6:
      scaling = 5.9;
      dropdownZoomingValue = { label: "95%", value: "95%" };
      zoom = "95%";
      break;
    case 5.9:
    case 6.2:
      scaling = 6.2;
      dropdownZoomingValue = { label: "100%", value: "100%" };
      zoom = "100%";
      break;
  }

  return {
    scale: scaling,
    dropdownZoomingValue,
    zoom,
  };
}
export function pdfViewerZoomOut(scal) {
  let scaling = "";
  let dropdownZoomingValue = "";
  let zoom = "";
  switch (scal) {
    case 0.8:
    case 0.5:
      scaling = 0.5;
      dropdownZoomingValue = { label: "5%", value: "5%" };
      zoom = "5%";
      break;
    case 1.1:
      scaling = 0.8;
      dropdownZoomingValue = { label: "10%", value: "10%" };
      zoom = "10%";
      break;
    case 1.4:
    case 1.3:
      scaling = 1.1;
      dropdownZoomingValue = { label: "15%", value: "15%" };
      zoom = "15%";
      break;
    case 1.7:
      scaling = 1.4;
      dropdownZoomingValue = { label: "20%", value: "20%" };
      zoom = "20%";
      break;
    case 2:
      scaling = 1.7;
      dropdownZoomingValue = { label: "25%", value: "25%" };
      zoom = "25%";
      break;
    case 2.3:
      scaling = 2;
      dropdownZoomingValue = { label: "30%", value: "30%" };
      zoom = "30%";
      break;
    case 2.6:
      scaling = 2.3;
      dropdownZoomingValue = { label: "35%", value: "35%" };
      zoom = "35%";
      break;
    case 2.9:
      scaling = 2.6;
      dropdownZoomingValue = { label: "40%", value: "40%" };
      zoom = "40%";
      break;
    case 3.2:
      scaling = 2.9;
      dropdownZoomingValue = { label: "45%", value: "45%" };
      zoom = "45%";
      break;
    case 3.5:
      scaling = 3.2;
      dropdownZoomingValue = { label: "50%", value: "50%" };
      zoom = "50%";
      break;
    case 3.8:
      scaling = 3.5;
      dropdownZoomingValue = { label: "55%", value: "55%" };
      zoom = "55%";
      break;
    case 4.1:
      scaling = 3.8;
      dropdownZoomingValue = { label: "60%", value: "60%" };
      zoom = "60%";
      break;
    case 4.4:
      scaling = 4.1;
      dropdownZoomingValue = { label: "65%", value: "65%" };
      zoom = "65%";
      break;
    case 4.7:
      scaling = 4.4;
      dropdownZoomingValue = { label: "70%", value: "70%" };
      zoom = "70%";
      break;
    case 5:
      scaling = 4.7;
      dropdownZoomingValue = { label: "75%", value: "75%" };
      zoom = "75%";
      break;
    case 5.3:
      scaling = 5;
      dropdownZoomingValue = { label: "80%", value: "80%" };
      zoom = "80%";
      break;
    case 5.6:
      scaling = 5.3;
      dropdownZoomingValue = { label: "85%", value: "85%" };
      zoom = "85%";
      break;
    case 5.9:
      scaling = 5.6;
      dropdownZoomingValue = { label: "90%", value: "90%" };
      zoom = "90%";
      break;
    case 6.2:
      scaling = 5.9;
      dropdownZoomingValue = { label: "95%", value: "95%" };
      zoom = "95%";
      break;
  }

  return {
    scale: scaling,
    dropdownZoomingValue,
    zoom,
  };
}
export function pdfViewerSelect(val) {
  let scaling = "";
  let dropdownZoomingValue = "";
  let zoom = "";
  switch (val) {
    case "5%":
      scaling = 0.5;
      dropdownZoomingValue = { label: "5%", value: "5%" };
      zoom = "5%";
      break;
    case "10%":
      scaling = 0.8;
      dropdownZoomingValue = { label: "10%", value: "10%" };
      zoom = "10%";
      break;
    case "15%":
      scaling = 1.1;
      dropdownZoomingValue = { label: "15%", value: "15%" };
      zoom = "15%";
      break;
    case "20%":
      scaling = 1.4;
      dropdownZoomingValue = { label: "20%", value: "20%" };
      zoom = "20%";
      break;
    case "25%":
      scaling = 1.7;
      dropdownZoomingValue = { label: "25%", value: "25%" };
      zoom = "25%";
      break;
    case "30%":
      scaling = 2;
      dropdownZoomingValue = { label: "30%", value: "30%" };
      zoom = "30%";
      break;
    case "35%":
      scaling = 2.3;
      dropdownZoomingValue = { label: "35%", value: "35%" };
      zoom = "35%";
      break;
    case "40%":
      scaling = 2.6;
      dropdownZoomingValue = { label: "40%", value: "40%" };
      zoom = "40%";
      break;
    case "45%":
      scaling = 2.9;
      dropdownZoomingValue = { label: "45%", value: "45%" };
      zoom = "45%";
      break;
    case "50%":
      scaling = 3.2;
      dropdownZoomingValue = { label: "50%", value: "50%" };
      zoom = "50%";
      break;
    case "55%":
      scaling = 3.5;
      dropdownZoomingValue = { label: "55%", value: "55%" };
      zoom = "55%";
      break;
    case "60%":
      scaling = 3.8;
      dropdownZoomingValue = { label: "60%", value: "60%" };
      zoom = "60%";
      break;
    case "65%":
      scaling = 4.1;
      dropdownZoomingValue = { label: "65%", value: "65%" };
      zoom = "65%";
      break;
    case "70%":
      scaling = 4.4;
      dropdownZoomingValue = { label: "70%", value: "70%" };
      zoom = "70%";
      break;
    case "75%":
      scaling = 4.7;
      dropdownZoomingValue = { label: "75%", value: "75%" };
      zoom = "75%";
      break;
    case "80%":
      scaling = 5;
      dropdownZoomingValue = { label: "80%", value: "80%" };
      zoom = "80%";
      break;
    case "85%":
      scaling = 5.3;
      dropdownZoomingValue = { label: "85%", value: "85%" };
      zoom = "85%";
      break;
    case "90%":
      scaling = 5.6;
      dropdownZoomingValue = { label: "90%", value: "90%" };
      zoom = "90%";
      break;
    case "95%":
      scaling = 5.9;
      dropdownZoomingValue = { label: "95%", value: "95%" };
      zoom = "95%";
      break;
    case "100%":
      scaling = 6.2;
      dropdownZoomingValue = { label: "100%", value: "100%" };
      zoom = "100%";
      break;
  }

  return {
    scale: scaling,
    dropdownZoomingValue,
    zoom,
  };
}
/*******END********/

/**
 * For some reason, the epoch time we get from the server is in seconds
 * The `new Date(epoch)` will always assume that the epoch is in milliseconds
 * So this will cause a issue. so checking if the epoch is in seconds, then multiply by 1000
 * @param {string|number} epoch
 * @returns {boolean} whether the epoch is in seconds
 */
export const isEpochInSeconds = (epoch) => {
  if (!epoch) throw new Error("Epoch is required");
  return epoch.toString().length < 13;
}

/**
 * Checks if the given value is a float or not
 * It adds additional check through regex
 * it will check if the value has a fractional part
 * @param {string|number} val
 * @returns {boolean}
 */
export const isFloat = (val) => {
  const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (!floatRegex.test(val)) return false;

  const parsedVal = parseFloat(val);
  
  return !(Number.isNaN(parsedVal)) && parsedVal % 1 !== 0;
}

/**
 * Returns if the value is an integer without any decimal
 * @param {string|number} val
 * @returns {boolean}
 */
export const isInt = (val) => {
  const intRegex = /^-?\d+$/;
  if (!intRegex.test(val)) return false;

  const intVal = parseInt(val, 10);
  return parseFloat(val) === intVal && !Number.isNaN(intVal);
};


/*******Downloads Attachments********/
export async function downloadAttachments(resp, fileName, showImage = false) {
  if (resp.contentType == "application/pdf") {
    if (resp.isDownload) {
      //download pdf
      const a = document.createElement("a");
      a.href = `data:application/pdf;base64,${resp.attachment}`;
      a.download = resp.fileName || "download.pdf";
      a.click();
    } else {
      //display pdf
      const blob = await dataURItoBlob(resp.attachment);
      const blob_url = URL.createObjectURL(blob);
      console.log("blob url", blob_url);
      let pdfWindow = await window.open("about:blank");
      if (pdfWindow) {
        pdfWindow.document
          .open()
          .write(
            "<html><head><title>Preview</title></head><body style='margin:0; text-align: center;'><style>body{margin:0;} .previewLoading { display:none; } </style><iframe width='100%' height='100%' style='border:0;' src='" +
              encodeURI(blob_url) +
              "'></iframe></body></html>"
          );
      } else {
        toast.error("Please Allow Browser Pop-ups!");
      }
    }
  } else if (resp.contentType == "application/vnd.ms-excel") {
    let blob = new Blob([s2ab(atob(resp.attachment))], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });

    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName || "download.xlsx";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (resp.contentType == "application/xml") {
    const a = document.createElement("a");

    a.href = `data:application/xml;base64,${resp.attachment}`;
    a.download = fileName || "download.xml";
    a.click();
  } else if (resp.contentType == "application/msword") {
    let a = document.createElement("a"); //Create <a>
    a.href =
      "data:application/vnd.openxmlformats-officedocument.wordprocessing;base64," +
      resp.attachment;
    a.download = fileName || "download.docx"; //File name Here
    a.click(); //Downloaded file
  } else if (resp.contentType == "application/vnd.ms-powerpoint") {
    let a = document.createElement("a"); //Create <a>
    a.href =
      "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," +
      resp.attachment;
    a.download = fileName || "download.ppt"; //File name Here
    a.click(); //Downloaded file
  } else if (resp.contentType == "text/csv") {
    const csv = atob(resp.attachment);
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    FileSaver.saveAs(csvData, fileName + ".csv" || "attachment.csv");
  } else {
    //image
    if (!showImage) {
      let newWindow = window.open();
      if (newWindow) {
        newWindow.document
          .open()
          .write(
            "<html><head><title>Preview</title></head><body style='margin:0; text-align: center;'><style>body{margin:0;} .previewLoading { display:none; } </style><iframe width='100%' height='100%' style='border:0;' src='data:image/jpeg;base64, " +
              encodeURI(resp.attachment) +
              "'></iframe></body></html>"
          );
      } else {
        toast.error("Please Allow Browser Pop-ups!");
      }
    } else {
      let a = document.createElement("a"); //Create <a>
      a.href = "data:image/png;base64," + resp.attachment;
      a.download = fileName || "attachment"; //File name Here
      a.click(); //Downloaded file
    }
  }
}
/*******Excell string to array buffer********/
function s2ab(s) {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}
/******Convert any Attachment to base64********/
export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

//************ */Drag and Drop Files************
export function addDragAndDropFileListners(id, uploadAttachment) {
  const dropArea = document.getElementById(id);
  if (dropArea) {
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
    });
    ["dragenter", "dragover"].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });
    ["dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, unHightLight, false);
    });
    dropArea.addEventListener(
      "drop",
      (e) => handleDrop(e, uploadAttachment),
      false
    );
  }
}
export function removeDragAndDropFileListners(id, uploadAttachment) {
  const dropArea = document.getElementById(id);
  if (dropArea) {
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dropArea.removeEventListener(eventName, preventDefaults, false);
    });
    ["dragenter", "dragover"].forEach((eventName) => {
      dropArea.removeEventListener(eventName, highlight, false);
    });
    ["dragleave", "drop"].forEach((eventName) => {
      dropArea.removeEventListener(eventName, unHightLight, false);
    });
    dropArea.removeEventListener(
      "drop",
      (e) => handleDrop(e, uploadAttachment),
      false
    );
  }
}
function preventDefaults(e) {
  e.preventDefault();
  e.stopPropagation();
}
function highlight() {
  const ele = document.querySelector(".upload-label");
  if (ele) {
    ele.style.backgroundColor = "#e9e9e9";
    ele.style.border = "2px dotted #999";
  }
}
function unHightLight() {
  const ele = document.querySelector(".upload-label");
  if (ele) {
    ele.style.backgroundColor = "#f6f6f6";
    ele.style.border = "unset";
  }
}
function handleDrop(e, uploadAttachment) {
  const dt = e.dataTransfer;
  const { files } = dt;
  uploadAttachment(files);
}
// ***************END*************

//Advanced List handlers
export function handleValueOptions(
  type,
  val,
  item,
  index,
  advancedList,
  clonedAdvancedList,
  valueField
) {
  //type -> list, date, checkbox, input fields
  //val -> value/event
  //item -> current row that is going to edit
  //index -> index of the row that is being edit

  let valueFieldName = "value";
  if (type === "MultiSelect" || type === "multiList"){
    valueFieldName = "value"
  }
  if (valueField) {
    valueFieldName = valueField;
  }

  if (type === "list") {
    item[valueFieldName] = val.value;
    advancedList[index] = item;
  }  else if (type === "MultiSelect" ||type === "multiList") {
    item[valueFieldName] = val ? val.map((val) => val.label).join(",") : "";
    advancedList[index] = item;
  } else if (type === "date") {
    let startDate = new Date();
    startDate.setDate(val.getDate());
    startDate.setMonth(val.getMonth());
    startDate.setFullYear(val.getFullYear());
    item[valueFieldName] = new Date(startDate).getTime();
    advancedList[index] = item;
  } else if (type === "checkbox") {
    item[valueFieldName] = val.target.checked ? "Y" : "N";
    advancedList[index] = item;
  } else {
    //input fields
    item[valueFieldName] = val.target.value;
    advancedList[index] = item;
  }
  //also update data in cloned advanced list
  if (clonedAdvancedList) {
    let foundIndex = clonedAdvancedList.findIndex((x) => x.id == item.id);
    if (foundIndex > -1) {
      clonedAdvancedList[foundIndex] = item;
    }

    return {
      advancedList,
      clonedAdvancedList,
    };
  } else {
    return {
      basicList: advancedList,
    };
  }
}

export function handleValueOptionsWithoutTimezone(
  type,
  val,
  item,
  index,
  advancedList,
  clonedAdvancedList,
  valueField
) {
  //type -> list, date, checkbox, input fields
  //val -> value/event
  //item -> current row that is going to edit
  //index -> index of the row that is being edit

  let valueFieldName = "value";
  if (type === "MultiSelect" || type === "multiList"){
    valueFieldName = "value"
  }
  if (valueField) {
    valueFieldName = valueField;
  }

  if (type === "list") {
    item[valueFieldName] = val.value;
    advancedList[index] = item;
  }  else if (type === "MultiSelect" ||type === "multiList") {
    item[valueFieldName] = val ? val.map((val) => val.label).join(",") : "";
    advancedList[index] = item;
  } else if (type === "date") {
    const dateObject = new Date(val);
    dateObject.setHours(0, 0, 0, 0);
    const utcTimestamp = dateObject.getTime() - dateObject.getTimezoneOffset() * 60000;
    item[valueFieldName] = utcTimestamp;
    advancedList[index] = item;
  } else if (type === "checkbox") {
    item[valueFieldName] = val.target.checked ? "Y" : "N";
    advancedList[index] = item;
  } else {
    //input fields
    item[valueFieldName] = val.target.value;
    advancedList[index] = item;
  }
  //also update data in cloned advanced list
  if (clonedAdvancedList) {
    let foundIndex = clonedAdvancedList.findIndex((x) => x.id == item.id);
    if (foundIndex > -1) {
      clonedAdvancedList[foundIndex] = item;
    }

    return {
      advancedList,
      clonedAdvancedList,
    };
  } else {
    return {
      basicList: advancedList,
    };
  }
}

export function handleHideUnhideRows(
  item,
  tableID,
  advnLstKey,
  mainLstt,
  clonedMainList,
  showHiddenRows = false
) {
  //advnLstKey -> key in which  the setting of the table is going to saved
  //tableID -> id of the table
  //mainLstt -> orignal list
  //clonedMainList -> a copy of mainLstt

  let mainList = JSON.parse(JSON.stringify(mainLstt));
  let table = window.$(tableID).DataTable();
  table.destroy();

  if (!item.hidden) {
    //hide row
    let list = mainList.filter((l) => l.id != item.id);

    item.hidden = true;
    let foundIndex = clonedMainList.findIndex((x) => x.id == item.id);
    clonedMainList[foundIndex] = item;
    //also save this setting on Local Storage
    let advList = JSON.parse(localStorage.getItem(advnLstKey) || "[]");
    if (advList && advList.length > 0) {
      let check = true;
      advList.map((al, i) => {
        if (
          al.category === item.category &&
          al.alescription === item.description &&
          al.valueType === item.valueType
        ) {
          check = false;
        }
      });

      if (check) {
        let obj = {
          category: item.category,
          description: item.description,
          valueType: item.valueType,
        };
        advList.push(obj);

        localStorage.setItem(advnLstKey, JSON.stringify(advList));
      }
    } else {
      //advList doesn't contain in local storage

      let advList = [];
      let obj = {
        category: item.category,
        description: item.description,
        valueType: item.valueType,
      };
      advList.push(obj);

      localStorage.setItem(advnLstKey, JSON.stringify(advList));
    }

    mainList = list;
    showHiddenRows = false;
  } else {
    //un-hide row
    item.hidden = false;

    let _foundIndex = mainList.findIndex((x) => x.id == item.id);
    mainList[_foundIndex] = item;

    let foundIndex = clonedMainList.findIndex((x) => x.id == item.id);
    clonedMainList[foundIndex] = item;

    //also remove this setting on Local Storage
    let savedData = localStorage.getItem(advnLstKey);
    if (savedData) {
      let advList = JSON.parse(localStorage.getItem(advnLstKey) || "[]");
      if (advList.length > 0) {
        let lstArr = [];
        advList.map((d, i) => {
          if (
            !(
              d.category === item.category &&
              d.description === item.description &&
              d.valueType === item.valueType
            )
          ) {
            lstArr.push(d);
          }
        });

        if (lstArr.length > 0) {
          localStorage.setItem(advnLstKey, JSON.stringify(lstArr));
        } else {
          localStorage.removeItem(advnLstKey);
        }
      }
    }
  }

  return {
    advancedList: mainList,
    clonedAdvancedList: clonedMainList,
    showHiddenRows,
  };
}

export const fuzzyMatch = (target, query) => {
  const normalizedTarget = target.toLowerCase();
  const normalizedQuery = query.toLowerCase();
  const targetWords = normalizedTarget.split(/\s+/);

  for (const queryWord of normalizedQuery.split(/\s+/)) {
      let found = false;
      for (const targetWord of targetWords) {
          if (targetWord.includes(queryWord)) {
              found = true;
              break;
          }
      }
      if (!found) {
          return false;
      }
  }

  return true;
}

//DataTable Flags tables Initialization
export function tableFlagsSetting(columns, aoColumns, name) {
  window.$("#" + name).DataTable({
    // language: {
    //   searchPlaceholder: "Search",
    // },
    dom: "Rlfrtip",
    aoColumns, //default table Columns
    stateSave: true,
    stateSaveCallback: function (settings, data) {
      localStorage.setItem("DataTables_" + name, JSON.stringify(data));
    },
    stateLoadCallback: function (settings) {
      return JSON.parse(localStorage.getItem("DataTables_" + name));
    },
    order: [[1, "asc"]],
    colReorder: {
      fixedColumnsRight: 1,
      fixedColumnsLeft: 1,
    },
    searching: false,
    paging: false,
    info: false,
    columnDefs: [
      {
        targets: [0, -1],
        orderable: false,
      },
    ],
  });

  //update state to show which columns are hidden
  let cols =
    window
      .$("#" + name)
      .DataTable()
      .settings()[0].aoColumns || [];
  columns.map((c, i) => {
    let col = cols.find((a) => a.sName === c.name);
    if (col && !col.bVisible) {
      c.hide = true;
    } else {
      c.hide = false;
    }
  });

  let pageLength =
    window
      .$("#" + name)
      .DataTable()
      .page.len() || 10;
  return { pageLength };
}

//DataTable Save Flags Settings
export function handleSaveFlagsSettings(columns, name) {
  //columns -> table columns, name -> table name
  let aoColumns =
    window
      .$("#" + name)
      .DataTable()
      .settings()[0].aoColumns || [];
  columns.map((c, i) => {
    if (c.hide) {
      let col = aoColumns.find((a) => a.sName === c.name);
      if (col) {
        window
          .$("#" + name)
          .DataTable()
          .column(col.idx)
          .visible(false);
      }
    } else {
      let col = aoColumns.find((a) => a.sName === c.name);
      if (col) {
        window
          .$("#" + name)
          .DataTable()
          .column(col.idx)
          .visible(true);
      }
    }
  });
  toast.success("Settings updated successfully.");
}
//DataTable Close Flags Settings
export function handleCloseFlagsSettingModal(columns, name) {
  //columns -> table columns, name -> table name
  let data = JSON.parse(localStorage.getItem("DataTables_" + name));
  //update state to show which columns are hidden
  let cols =
    window
      .$("#" + name)
      .DataTable()
      .settings()[0].aoColumns || [];
  columns.map((c, i) => {
    let col = cols.find((a) => a.sName === c.name);
    if (col && !col.bVisible) {
      c.hide = true;
    } else {
      c.hide = false;
    }
  });
  return { columns };
}

function dataURItoBlob(dataURI) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: "application/pdf" });
  return blob;
}

export function identifyUpdateOrAdd(payload) {
  if (payload.includes("0 users inserted")) {
    return "updated";
  } else {
    return "inserted";
  }
}

/**
 * Returns a list of country names and their codes
 */
export function getCountryCodeList() {
  const collator = new Intl.Collator(undefined, {
    sensitivity: "base",
  });
  const sortedCountryList = countryList
    .getData()
    .map((cl, i) => ({
      label: cl.name + " (" + cl.code + ")",
      value: cl.code,
    }))
    .sort(function (a, b) {
      return collator.compare(a.label, b.label);
    });

  return sortedCountryList;
}

export function getActivatedTab() {
  let userType = localStorage.getItem("userType");
  userType = userType ? userType.toLowerCase() : "";
  if (userType === "approver") {
    return "Approve"
  } else {
    return "Draft"
  }
}

/**
 * Does common validation to the object to determine if it has results or not
 * used by both isFailedRequest and isSuccessRequest
 * @param {GenericAPIResponse} response 
 * @returns {boolean}
 */
const isValidResultObject = (response) => {
  if (typeof response !== "object" || response === null) {
    console.warn('Response is not a valid object');
    return false;
  }
  const keys = Object.keys(response);
  const hasResults = keys.includes("results");
  const hasResult = keys.includes("result");
  if (!hasResults && !hasResult) {
    console.warn('Response does not have results or result');
    return false;
  }
  const results = hasResults ? response.results : response.result;
  if (!Array.isArray(results) || results.length === 0) {
    console.log('Response results is not an array or empty');
    return false;
  }
  return true;
}

/**
 * Determines if the API request has failed or not. In all the
 * past implementations, we have just checked if the results array's first
 * element's status is "Failed" or not. but this will be bit robust by checking
 * all the elements of the results array to see if any of them are "Failed".
 * In someplaces the it is results and some places it is result, so will handle that too.
 * This is also case insensitive by converting everything to lowercase. if there is no results or if results are empty
 * it will return false
 * @param {GenericAPIResponse} response The API Response
 */
export const isFailedRequest = (response) => {
  if (!isValidResultObject(response)) {
    console.warn('Not a valid result object');
    return false;
  }
  const keys = Object.keys(response);
  const hasResults = keys.includes("results");
  const results = hasResults ? response.results : response.result;
  return results.some((result) => {
    if (typeof result !== "object" || result === null || !result.hasOwnProperty('status')) {
      console.log('Response result is not a valid object', result);
      return false;
    }
    return result.status.toLowerCase() === "failed";
  });
}

/**
 * Determines if the API request has Success or not.
 * all the elements of the results array to see if any of them are "Failed".
 * In someplaces the it is results and some places it is result, so will handle that too.
 * This is also case insensitive by converting everything to lowercase. if there is no results or if results are empty
 * it will return false. sometimes we might need to check if it success or not
 * @param {GenericAPIResponse} response The API Response
 */
export const isSuccessRequest = (response) => {
  if (!isValidResultObject(response)) {
    console.warn('Not a valid result object');
    return false;
  }
  const keys = Object.keys(response);
  const hasResults = keys.includes("results");
  const results = hasResults ? response.results : response.result;
  return results.every((result) => {
    if (typeof result !== "object" || result === null || !result.hasOwnProperty('status')) {
      console.log('Response result is not a valid object', result);
      return false;
    }
    return result.status.toLowerCase() === "success";
  });
}

/**
 * Determines if the API request has Has Warning or not.
 * any of the elements of the results array to see if any of them are "Warning".
 * In someplaces the it is results and some places it is result, so will handle that too.
 * This is also case insensitive by converting everything to lowercase. if there is no results or if results are empty
 * it will return false. sometimes we might need to check if it success or not
 * @param {GenericAPIResponse} response The API Response
 */
export const isWarningRequest = (response) => {
  if (!isValidResultObject(response)) {
    console.warn('Not a valid result object');
    return false;
  }
  const keys = Object.keys(response);
  const hasResults = keys.includes("results");
  const results = hasResults ? response.results : response.result;
  return results.some((result) => {
    if (typeof result !== "object" || result === null || !result.hasOwnProperty('status')) {
      console.log('Response result is not a valid object', result);
      return false;
    }
    return result.status.toLowerCase() === "warning";
  });
}

/**
 * Gets the messages type from API response
 * @param {GenericAPIResponse} response
 * @param {'success'|'warning'|'failed'} type The type of message, you want to get
 * @returns {string}
 */
export const getMessageDescription = (response, type = 'success') => {
  if (!isValidResultObject(response)) {
    console.warn('Not a valid result object for toast');
    return "Unable to get response message";
  }
  const keys = Object.keys(response);
  const hasResults = keys.includes("results");
  const results = hasResults ? response.results : response.result;
  const filteredOutResult = results?.filter((result) => result?.status?.toLowerCase() === type);
  let message = '';
  for (const result of filteredOutResult) {
    const { description } = result || '';
    if (message.length === 0) {
      message += `${description}`;
    } else {
      message += `, ${description} `;
    }
  }
  return message;
}

export const getToastMessage = (response) => {
  if (!isValidResultObject(response)) {
    console.warn('Not a valid result object for toast');
    return "Unable to get response message";
  }
  const keys = Object.keys(response);
  const hasResults = keys.includes("results");
  const results = hasResults ? response.results : response.result;
  let message = '';
  for (const result of results) {
    const { description } = result || '';
    if (message.length === 0) {
      message += `${description}`;
    } else {
      message += `, ${description} `;
    }
  }
  return message;
}

/**
 * This method will check the given company id against AU and UK backends and
 * setup the URLs and IDs according to the region. This will return false if the
 * given company id is not found in either AU or UK backends and returns true otherwise.
 */
export async function setupBackendRegion({ companyID, selectedRegion }) {
  const apiUrlAU = window.config.API_URL_AU;
  const apiUrlUK = window.config.API_URL_UK;
  let region = selectedRegion ?? "UK";

  // detect region using the company id
  if (!selectedRegion) {
    let checkCompanyResult = await checkCompany(apiUrlAU, companyID);

    if (!checkCompanyResult) {
      checkCompanyResult = await checkCompany(apiUrlUK, companyID);
      region = checkCompanyResult ? "UK" : null;
    } else {
      region = "AU";
    }
  } else {
    region = selectedRegion;
  }

  if (!region) {
    // both AU and UK returned false for the given company id so return error
    console.log('both AU and UK returned false for the given company id so return error');
    console.log('provided params', { companyID, selectedRegion });
    console.log('region', region);
    toast.error("Company ID not found.");
    return false;
  }

  // set the URLs and IDs depending on the region
  localStorage.setItem("API_URL", region === "AU" ? apiUrlAU : apiUrlUK);
  localStorage.setItem(
    "API_GATEWAY_URL",
    window.config[`API_GATEWAY_URL_${region}`]
  );
  localStorage.setItem(
    "COGNITO_USER_POOL_ID",
    window.config[`USER_POOL_ID_${region}`]
  );
  localStorage.setItem(
    "COGNITO_CLIENT_APP_ID",
    window.config[`COGNITO_CLIENT_APP_ID_${region}`]
  );
  localStorage.setItem(
    "COGNITO_PROXY_URL",
    window.config[`COGNITO_PROXY_URL_${region}`]
  );
  localStorage.setItem(
    "COGNITO_CLIENT_APP_DOMAIN",
    window.config[`COGNITO_CLIENT_APP_DOMAIN_${region}`]
  );
  localStorage.setItem(
    "COGNITO_CLIENT_APP_REDIRECT_URI",
    window.config[`COGNITO_CLIENT_APP_REDIRECT_URI_${region}`]
  );
  localStorage.setItem("AWS_REGION", window.config[`AWS_REGION_${region}`]);

  // Set WEBSOCKET_API_URL based on the region
  localStorage.setItem(
    "WEBSOCKET_API_URL",
    window.config[`WEBSOCKET_API_URL_${region}`]
  );

  axiosDPFAPIClient.defaults.baseURL = localStorage.getItem("API_URL");
  axiosAPIGatewayClient.defaults.baseURL = localStorage.getItem("API_GATEWAY_URL");

  return true;
}

/**
 * Returns whether the given string is a valid string or not
 * even 'undefined' is not a string. The function also has a `preserveType` option
 * Which will check if the given string is a string or not and also won't convert it
 * to string to check. this will be useful when checking if the given value is a object.
 * eg: when you get a moment object, passing through String() constructor will convert it to a string
 * but you want to check if it is a string or not right.
 * @param {*} str
 * @param {boolean} preserveType
 * @returns {boolean}
 */
export const isValidString = (str, preserveType = false) => {
  const stringifiedValue = preserveType ? str : String(str);
  const invalidTypes = {
    undefined: false,
    null: false,
    NaN: false,
    0: false,
    false: false,
    '': false,
  }
  const typeCheck = preserveType ? typeof str === 'string' : true;
  return invalidTypes[stringifiedValue] !== false && typeCheck;
}

/**
 * Returns the first valid string from the given arguments
 * @param  {...any} args The arguments to check
 * @returns {string}
 */
export const getValidString = (...args) => {
  // Filter out invalid strings
  const validStrings = args.filter((arg) => isValidString(arg));

  // Return the first valid string (or an empty string if none found)
  return validStrings.length > 0 ? validStrings[0] : '';
};

export const validateChartSort = (chartSort, defaultChartSorts) => {
  let isChartSortValid = true;

  if (
    localStorage.getItem("forceValidChartCodePO").toLowerCase() === "n" ||
    localStorage.getItem("userType").toLowerCase() === "admin"
  ) {
    return isChartSortValid;
  }

  const chartSortSegments = chartSort.split(".");
  if (chartSortSegments.length !== 3) {
    isChartSortValid = false;
    return isChartSortValid;
  }

  const episode = chartSortSegments.pop();
  const location = chartSortSegments.pop();
  const currency = chartSortSegments.pop();

  if (!defaultChartSorts.episode.find((e) => e.code === episode)) {
    isChartSortValid = false;
    return isChartSortValid;
  }

  if (!defaultChartSorts.location.find((l) => l.code === location)) {
    isChartSortValid = false;
    return isChartSortValid;
  }

  if (!defaultChartSorts.currency.find((c) => c.code === currency)) {
    isChartSortValid = false;
    return isChartSortValid;
  }

  return isChartSortValid;
};

export const validateChartCode = (chartCode, defaultChartCodes) => {
  let isChartCodeValid = true;

  if (
    localStorage.getItem("forceValidChartCodePO").toLowerCase() === "n" ||
    localStorage.getItem("userType").toLowerCase() === "admin"
  ) {
    return isChartCodeValid;
  }

  if (!defaultChartCodes.find((c) => c.code === chartCode)) {
    isChartCodeValid = false;
    return isChartCodeValid;
  }
  return isChartCodeValid;
};

export const downloadZipAttachment = (
  attachment,
  fileName = "attachments.zip"
) => {
  const linkSource = `data:text/plain;base64,${attachment}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};
//End
export function formatDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits for day
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits for month
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

export function isSafari() {
  var ua = navigator.userAgent.toLowerCase(); 
  return (ua.indexOf("safari") !== -1) && !(ua.indexOf("chrome") > -1);
}

export function removeBlockingIframe(key = 'Generic') {
  const isBlockingIframe = document.querySelector('body > iframe');

  if (isBlockingIframe !== null) {
    console.log(`${key}: Blocking iframe detected & removed!`);
    isBlockingIframe?.remove();
  }
}

/**
 * Extracts error message from API response's message string
 * @param {string} errorMessage
 * @returns {string}
 */
export function extractErrorMessage(errorMessage) {
  // Return generic error message if falsy value is provided
  if (!errorMessage) return 'Unable to perform action';
  // Return whatever is provided when it is not a string
  if (typeof errorMessage !== 'string') return errorMessage;
  const lowerCasedErrorMessage = errorMessage?.toLowerCase()?.trim();
  const lowerCasedSearchString = 'usermigration failed with error';
  const searchStringIndex = lowerCasedErrorMessage?.indexOf(lowerCasedSearchString);
  if (searchStringIndex !== -1) {
    const message = errorMessage?.slice(searchStringIndex + lowerCasedSearchString.length)?.trim();
    return message;
  }
  return errorMessage;
}

/**
 * Formats the numbers by inserting commas as thousand separators
 * @param {string|number} numberValue
 * @returns {string}
 */
export function formatNumberWithCommas(numberValue) {
  const parsedNumber = Number(numberValue);
  if (isNaN(parsedNumber)) {
    console.log('formatNumberWithCommas: number is not a number. raw param:', numberValue, 'parsed number', parsedNumber);
    return "";
  };
  return parsedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Groups an array of objects based on a specified property.
 *
 * @param {Object[]} array - The array of objects to be grouped.
 * @param {string} property - The property to group by (e.g., 'category').
 * @param {string} groupKey - The key under which the grouped objects should be stored (e.g., 'values') Defaults to 'values'.
 * @returns {Object[]} - An array of grouped objects.
 *
 */
export function groupBy(array, property, groupKey = 'values') {
  if (!Array.isArray(array)) {
    console.log('groupBy: array is not an array. raw param:', array);
    return [];
  }
  return array.reduce((acc, obj) => {
      // Find the group where the object should be added
      let group = acc.find(g => g[property] === obj[property]);
      
      // If the group doesn't exist, create a new one
      if (!group) {
          group = { [property]: obj[property], [groupKey]: [] };
          acc.push(group);
      }
      
      // Add the object to the group
      group[groupKey].push(obj);
      
      return acc;
  }, []);
}

/**
 * @typedef {Object} GenericAPIResponse
 * @property {Array.<ResultsObject>?} results
 * @property {Array.<ResultsObject>?} result 
 */

/**
 * @typedef {Object} ResultsObject
 * @property {'Success'|'Failed'} status
 * @property {string} description
 */