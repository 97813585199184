import React, { Component, Fragment, createContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Document, Page, pdfjs } from "react-pdf";

import $ from "jquery";
import Header from "../Common/Header/Header";
import TopNav from "../Common/TopNav/TopNav";
import Activity from "../Modals/Activity/Activity";
import Changes from "../Modals/Changes/Changes";
import Attachments from "../Modals/Attachments/Attachments";
import Comments from "../Modals/Comments/Comments";

import Dropdown from "react-bootstrap/Dropdown";
import Post from "../Modals/Post/Post";
import Import from "../Modals/Import/Import";
import Report from "../Modals/Report/Report";
import { options } from "../../Constants/Constants";
import * as SupplierActions from "../../Actions/SupplierActtions/SupplierActions";
import SupplierActivity from "../Modals/SupplierActivity/SupplierActivity";
import * as Helpers from "../../Utils/Helpers";
import { data } from "currency-codes";
import { downloadFileFromPresignedUrl, getBase64FromPreSignedS3Url, isRemoteStorage, uploadFileListToS3 } from "../../Utils/S3StorageHelper";
const uuidv1 = require("uuid/v1");

class Suppliers extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,

      suppliersList: [],
      filteredSuppliersList: [],
      activeSupplier: "",
      toggleRightSidebar: true,
      multipleSupplierIDs: [],
      sortFilter: "name",
      sortFilterCheck: "DESC",
      clonedSuppliersList: [],
      suppliersListSearch: "",

      suppliersActivity: [], //a suppliers activity,
      columns: [],
      pageLength: 10,
      openSettingsModal: false,
      openActivityModal: false,
      // openDeclineModal: false,
      // openDeleteModa: false,
      // openMoveModal: false,
      // openPostModal: false,
      supplierTallies: [],
      activeSupplierTallis: "",
      showSupplierTallisTabPane: "draft",
      //---------  get below states data from getSupplierSummary function
      id: "",
      tran: "",
      supplierID: "",
      supplierType: "",
      currency: "",
      code: "",
      userName: "",
      accountType: "",
      supplierName: "",
      approvalGroup: "",
      approvalsGroups: [],
      approvalDate: "",
      approvers: [],
      email: "",
      taxID: "",
      taxCode: "",
      supplierComments: [],
      attachments: [],
      attachmentSize: 0,
      activity: [],
      previewList: [],
      locked: "",

      scaling: 3.4,
      dropdownZoomingValue: { label: "40%", value: "40%" },
      toggleRightSidebar: true,
      rotate: 0,
      flags: [], //restructured flags according to select dropdown to just show in Line Items Modal ,comming from get api (tracking codes)
      clonedFlags: [], //a copy of flags
      numPagesArr: [], //it contains number of pages of each PDF
      pageNumber: 1,
      checked: false,
      activatedGuid: "",
      supplierGuid:"",
    };
  }

  async componentDidMount() {
    //right hand side bar setting with PO Image
    window.addEventListener(
      "resize",
      () => {
        $(".mm_ordr1").addClass("mm_order_pdfMain");

        if ($(window).width() > 991) {
          this.setState({ toggleRightSidebar: true });
        }
        if ($(window).width() <= 991) {
          this.setState({ toggleRightSidebar: false });
        }
      },
      false
    );
    // end

    //focus search input field by pressing Tab key
    document.onkeydown = function (evt) {
      evt = evt || window.event;
      if (evt.keyCode == 9) {
        evt.preventDefault();
        let id = document.getElementById("POListSearchId");
        if (id) {
          document.getElementById("POListSearchId").focus();
        }
      }
    };

    $(document).ready(function () {
      var vw = $(window).width();
      var nav = $(".navbar.fixed-top").height();
      var underNav = $(".order-tabs").height();
      var wh = $(window).height();
      var h = wh - nav - 60;
      var rsb = wh - nav - underNav - 20;
      var pdfDiv = wh - nav - underNav - 80;
      var pdfWid = vw - 740;
      $("#overload_image").css("width", pdfWid);
      $("#order--dynamic--height").css("height", h);
      $(".side-attachments,.side-attachments-2").css("height", rsb);
      $("#maped_image").css("height", pdfDiv);
      $(window).on("load", function () {
        var vw = $(window).width();
        var nav = $(".navbar.fixed-top").height();
        var underNav = $(".order-tabs").height();
        var wh = $(window).height();
        var h = wh - nav - 60;
        var rsb = wh - nav - underNav - 20;
        var pdfDiv = wh - nav - underNav - 80;
        var pdfWid = vw - 740;
        $("#overload_image").css("width", pdfWid);
        $("#order--dynamic--height").css("height", h);
        $(".side-attachments,.side-attachments-2").css("height", rsb);
        $("#maped_image").css("height", pdfDiv);
      });
      $(window).resize(function () {
        var vw = $(window).width();
        var nav = $(".navbar.fixed-top").height();
        var underNav = $(".order-tabs").height();
        var wh = $(window).height();
        var h = wh - nav - 60;
        var rsb = wh - nav - underNav - 20;
        var pdfDiv = wh - nav - underNav - 80;
        var pdfWid = vw - 740;
        $("#overload_image").css("width", pdfWid);
        $("#order--dynamic--height").css("height", h);
        $(".side-attachments,.side-attachments-2").css("height", rsb);
        $("#maped_image").css("height", pdfDiv);
      });
    });
    $("#expand").on("click", function (e) {
      e.preventDefault();
      $(".maped_image").addClass("mm_pdf_img");
    });

    $(".cus-arro-div2").on("click", function (e) {
      e.preventDefault();
      $(".order_pdf_new").toggleClass("order_left_auto");
    });
    $("#full_screen").on("click", function (e) {
      e.preventDefault();
      $(".explore_img").addClass("fit_top_bottom");
    });
    // end
    // sideBarAccord
    $(".invoice-inherit").click(function () {
      $(".invoice-inherit .sideBarAccord1 ").toggleClass("rotate_0");
    });
    $(".sideBarAccord").click(function () {
      $(this).toggleClass("rorate_0");
    });
    $(".export_crd").click(function () {
      $(".export_crd .sideBarAccord1").toggleClass("rotate_0");
    });
    $(".invoice-inherit2").click(function () {
      $(".sideBarAccord2 ").toggleClass("rotate_0");
    });
    let {
      tallies,
      dashboard,
      tallType,
      addEditSupplierCheck,
      addEditSupplierTran,
    } =
      (this.props.history &&
        this.props.history.location &&
        this.props.history.location.state) ||
      "";

    let abc =
      (this.props.history &&
        this.props.history.location &&
        this.props.history.location.state) ||
      "";

    if (dashboard && tallType) {
      //when user click on supplier Tallies on Dashboard
      await this.getSupplierTallies(tallType, true);
    } else {
      if (
        // tallies &&
        // (tallies === "Draft" || tallies === "Approve") &&
        addEditSupplierCheck &&
        addEditSupplierTran
      ) {
        /*Check When Edit supplier and then user Save or Cancel that edit, 
    then load the same supplier user just edited?.*/
        await this.getSupplierTallies(tallies, true);
      } else {
        await this.getSupplierTallies();
      }
    }
  }

  clearStates = () => {
    this.setState({
      supplierID: "", //tran of current selected Supplier
      multipleSupplierIDs: [], //when user selects multiple Suppliers to perform different functionality
    });
  };

  // get Supplier talleis
  getSupplierTallies = async (type, check) => {
    this.setState({ checked: false });
    //check -> when a user Perform some actions like send for approval, Approve, Declined OR after creating new PO etc then update PO Tallies
    this.setState({ isLoading: true });
    let isSupplierTallies = false; //to check if redux store containe po tallies then dont call API again
    let _getSupplierTallies = this.props.supplier.getSupplierTallies || [];

    if (_getSupplierTallies.length === 0 || check) {
      await this.props.getSupplierTallies(); // get poTallies
    } else {
      isSupplierTallies = true;
    }
    let supplierTally = "";

    let { activeSupplierTallis, showSupplierTallisTabPane } = this.state;
    let supplierTalliesArr = [];

    //success case of supplier tallies
    if (this.props.supplier.getSupplierTalliesSuccess || isSupplierTallies) {
      // toast.success(this.props.supplier.getSupplierTalliesSuccess);
      let supplierTallies = this.props.supplier.getSupplierTallies || [];
      let opTypes = [];

      let userType = localStorage.getItem("userType");
      userType = userType ? userType.toLowerCase() : "";

      if (userType == "operator") {
        opTypes = ["draft", "pending", "declined", "approved", "all"];
      } else if (userType == "approver") {
        opTypes = ["approve", "hold", "pending", "declined", "approved", "all"];
      } else if (userType == "op/approver") {
        opTypes = [
          "draft",
          "approve",
          "hold",
          "pending",
          "declined",
          "approved",
          "all",
        ];
      }

      if (opTypes.length > 0) {
        opTypes.map((t, i) => {
          let obj = supplierTallies.find(
            (tl) => tl.type && tl.type.toLowerCase() === t
          );
          if (obj) {
            supplierTalliesArr.push(obj);
          }
        });
      } else {
        supplierTalliesArr = supplierTallies;
      }

      let _type = "";

      if (type) {
        _type = type;
      } else if (supplierTalliesArr.length > 0) {
        _type = supplierTalliesArr[0].type;
      }

      supplierTalliesArr.map(async (s, i) => {
        if (s.type === _type) {
          let id = uuidv1();
          s.id = id;
          supplierTally = s;
          activeSupplierTallis = id;
          showSupplierTallisTabPane = s.type;
        } else {
          s.id = uuidv1();
        }
        return s;
      });
    }
    //error case of PO tallies
    if (this.props.supplier.getSupplierTalliesError) {
      Helpers.handleAPIErr(
        this.props.supplier.getSupplierTalliesError,
        this.props
      );
    }

    this.setState({
      isLoading: false,
      supplierTallies: supplierTalliesArr,
      activeSupplierTallis,
      showSupplierTallisTabPane,
    });
    if (supplierTally) {
      await this.getSuppliersList(supplierTally);
    }
    this.props.clearSupplierStates();
  };

  getSuppliersList = async (supplierTally) => {
    this.setState({
      isLoading: true,
    });
    this.clearStates();
    await this.props.getSuppliersList("", "", "", supplierTally);
    let { supplierID, activeSupplier, currency, code } = this.state;
    let supplierGuid;
    if (this.props.supplier.getSuppliersListSuccess || "") {
      // toast.success(this.props.supplier.getSuppliersListSuccess);
      let suppliersList = this.props.supplier.getSuppliersList || [];

      if (suppliersList.length > 0) {
        suppliersList.map((s, i) => {
          s.id = uuidv1();
          s.checked = false;
          return s;
        });
        activeSupplier = suppliersList[0].id;
        supplierID = suppliersList[0].supplierID;
        currency = suppliersList[0].currency;
        code = suppliersList[0].code;
        supplierGuid = suppliersList[0].guid;
      }
      let { addEditSupplierTran, addEditSupplierCheck } =
        (this.props.history &&
          this.props.history.location &&
          this.props.history.location.state) ||
        "";
      /*Check When Add/Edit Supplier and then user Save or Cancel that edit, 
        then load the same  Supplier user just edited/created?.*/
      let checkTC = "";
      if (
        // tallies &&
        // (tallies === "Draft" || tallies === "Approve") &&
        addEditSupplierCheck &&
        addEditSupplierTran
      ) {
        checkTC = suppliersList.find(
          (l) => l.supplierID === addEditSupplierTran
        );
      }
      if (checkTC) {
        activeSupplier = checkTC.id;
        code = checkTC.code;
        currency = checkTC.currency;

        this.getSupplierSummary({ guid: supplierGuid });
      } else {
        this.getSupplierSummary({ guid: supplierGuid });
      }
      this.setState({
        suppliersList: suppliersList || [],
        clonedSuppliersList: suppliersList || [], //a copy of suppliers list
        isLoading: false,
        activeSupplier,
        supplierID,
        currency,
        code,
        supplierGuid:supplierGuid
      });
      // this.handleSortSupplierLists("name");

      var elmnt = document.getElementById(activeSupplier);
      if (elmnt) {
        elmnt.scrollIntoView();
      }
      // ------END-------
    }
    //error case of Get Suppliers List
    if (this.props.supplier.getSuppliersListError) {
      Helpers.handleAPIErr(
        this.props.supplier.getSuppliersListError,
        this.props
      );
    }
  };

  // getSupplierDetails = (supplier) => {
  //   let { activeSupplier, supplierID, currency, code } = this.state
  //   activeSupplier = supplier.id;
  //   supplierID = supplier.supplierID;
  //   currency = supplier.currency;
  //   code = supplier.code;
  //   let data = {
  //     currency,
  //     code,
  //   };
  //   this.getSupplierSummary(data);
  //   this.setState({ activeSupplier, supplierID, currency, code });
  // }

  getSupplierSummary = async (data) => {
    this.setState({
      isLoading: true,
      activeSupplier: data.id,
      supplierGuid: data.guid,
      // code: data.code,
      // supplierID: data.supplierID
    });
    await this.props.getSupplierSummary(data.guid);

    if (this.props.supplier.getSupplierSummarySuccess) {
      let supplierSummary = this.props.supplier.getSupplierSummary || "";

      let id = supplierSummary.id || "";
      let tran = supplierSummary.tran || "";
      let supplierID = supplierSummary.id || "";
      let supplierType = supplierSummary.supplierType || "";
      let currency = supplierSummary.currency || "";
      let code = supplierSummary.supplierCode || "";
      let userName = supplierSummary.userName || "";
      let accountType = supplierSummary.accountType || "";
      let supplierName = supplierSummary.supplierName || "";
      let approvalGroup = supplierSummary.approvalGroup || "";

      let approvalDate = supplierSummary.approvalDate || "";
      let approvers = supplierSummary.approvers || [];
      let email = supplierSummary.email || "";
      let taxID = supplierSummary.taxID || "";
      let taxCode = supplierSummary.taxCode || "";
      let supplierComments = supplierSummary.comments || [];
      let attachments = supplierSummary.attachments || [];
      let activatedGuid = supplierSummary.guid || "";
      let attachmentSize = 0;
      let approvalsGroups =
        JSON.parse(JSON.stringify(supplierSummary.approvalOptions)) || [];
      approvalsGroups.map((m) => {
        m.checked = false;
        m.id = uuidv1();
        return m;
      });
      attachments.map((a, i) => {
        attachmentSize += Number(a.fileSize) || 0;
      });
      let activity = supplierSummary.activity || [];
      let previewList = supplierSummary.previewList || [];
      let locked = supplierSummary.locked || "";

       if (isRemoteStorage()) {
         previewList = await Promise.all(
           previewList.map(async (p) => {
             p.file = await getBase64FromPreSignedS3Url(p.documentPresignedURL);
             return p;
           })
         );
       }

      this.setState({
        id,
        tran,
        supplierID,
        supplierType,
        currency,
        code,
        userName,
        accountType,
        supplierName,
        approvalGroup,
        approvalsGroups,
        approvalDate,
        approvers,
        email,
        taxID,
        taxCode,
        supplierComments,
        attachments,
        attachmentSize,
        activity,
        previewList,
        locked,
        activatedGuid: activatedGuid
      });
    }
    //error case of Get Supplier Preview
    if (this.props.supplier.getSupplierPreviewError) {
      Helpers.handleAPIErr(
        this.props.supplier.getSupplierPreviewError,
        this.props
      );
    }
    this.setState({ isLoading: false });
    this.props.clearSupplierStates();
  };

  handleCheckbox = (e, data, index) => {
    this.setState({ checked: !this.state.checked });
    let { suppliersList, multipleSupplierIDs } = this.state;
    let { name, checked } = e.target;
    let allSelected = false;
    if (data === "allCheck" && name === "checkboxAll") {
      let multipleSupplierIDsCopy = [];
      if (checked) {
        allSelected = true;
        suppliersList.map((m) => {
          m.checked = true;
          multipleSupplierIDsCopy.push(m.supplierID);
          return m;
        });
      } else {
        allSelected = false;
        suppliersList.map((m) => {
          m.checked = false;
          return m;
        });
      }
      multipleSupplierIDs = [...multipleSupplierIDsCopy];
    } else {
      if (checked) {
        suppliersList.map((m) => {
          if (m.id === data.id) {
            m.checked = true;
          }
          return m;
        });
        multipleSupplierIDs.push(data.supplierID);
      } else {
        suppliersList.map((m) => {
          if (m.id === data.id) {
            m.checked = false;
          }
          return m;
        });
        multipleSupplierIDs = multipleSupplierIDs.filter(
          (m) => m !== data.supplierID
        );
      }
    }
    this.setState({ suppliersList, multipleSupplierIDs });
  };

  //sorting on supplier's list
  handleSortSupplierLists = async (name) => {
    let { sortFilterCheck, sortFilter, clonedSuppliersList } = this.state;

    if (sortFilter != name) {
      sortFilterCheck = "DESC";
    }

    if (sortFilterCheck === "DESC") {
      sortFilterCheck = "ASC";
    } else {
      sortFilterCheck = "DESC";
    }

    let suppliersListFilterdData = clonedSuppliersList.sort(function (a, b) {
      let valueA = a[name].toString().toUpperCase();
      let valueB = b[name].toString().toUpperCase();
      //for ascending order
      if (sortFilterCheck === "ASC") {
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
        // codes must be equal
      } else {
        //for descending order

        if (valueA > valueB) {
          return -1;
        }
        if (valueA < valueB) {
          return 1;
        }
        return 0;
        // codes must be equal
      }
    });

    this.setState({
      suppliersList: suppliersListFilterdData,
      sortFilter: name,
      sortFilterCheck,
    });
  };

  suppliersListSearch = (e) => {
    let searchedText = e.target.value;
    const clonedSuppliersList = JSON.parse(
      JSON.stringify(this.state.clonedSuppliersList)
    );
    let { suppliersList, activeSupplier } = this.state;

    if (!searchedText) {
      suppliersList = clonedSuppliersList;
      // activeSupplier = suppliersList[0].id;
    } else {
      let suppliersListFilterdData = [];
      suppliersListFilterdData = clonedSuppliersList.filter((c) => {
        return (
          c.name.toUpperCase().includes(searchedText.toUpperCase()) ||
          c.email.toUpperCase().includes(searchedText.toUpperCase())
        );
      });
      suppliersList = suppliersListFilterdData;
    }
    this.setState({ suppliersList, suppliersListSearch: searchedText }, () => {
      var elmnt = document.getElementById(activeSupplier);
      if (elmnt) {
        elmnt.scrollIntoView();
      }
    });
  };

  draftEditSupplier = (name) => {
    let { multipleSupplierIDs, supplierID, currency, code, supplierGuid } = this.state;

    if (name === "newSupplier") {
      this.props.history.push("/new-supplier2", {
        tran: "newSupplier",
        supplierID,
        currency: currency,
        code: code,
        supplierGuid: supplierGuid
      });
    } else {
      this.props.history.push("/new-supplier2", {
        supplierID,
        currency: currency,
        code: code,
        supplierGuid: supplierGuid
      });
    }
  };

  // -------------- delete supplier-------------
  deleteSupplier = async () => {
    let { currency, code, supplierID, multipleSupplierIDs } = this.state;

    let _trans = "";
    if (multipleSupplierIDs.length > 0) {
      if (multipleSupplierIDs.length == 1) {
        _trans = multipleSupplierIDs[0];
      } else {
        toast.error("Only One Supplier can be Delete at a Time!");
      }
    } else {
      _trans = supplierID;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });

      let supplierDetails = {
        currency,
        code,
      };
      await this.props.deleteSupplier(this.state.activatedGuid);
      if (this.props.supplier.deleteSupplierSuccess) {
        toast.success(this.props.supplier.deleteSupplierSuccess);
        let {
          supplierID,
          suppliersList,
          supplierTallies,
          activeSupplier,
          activeSupplierTallis,
          showSupplierTallisTabPane,
        } = this.state;

        if (suppliersList.length === 1) {
          await this.clearStates();
          //decrease the tallies count also
          supplierTallies.map((t, i) => {
            if (
              showSupplierTallisTabPane.toLocaleLowerCase() ===
              t.type.toLocaleLowerCase()
            ) {
              if (t.count === 1) {
                t.count = 0;
              } else {
                t.count = t.count - 1;
              }
            }
            return t;
          });
          this.setState({
            activeSupplierTallis,
            showSupplierTallisTabPane,
            supplierTallies,
          });
        } else if (suppliersList.length > 1) {
          if (_trans === supplierID) {
            //when user delete the current selected Supplier
            //there are two cases if the user deletes the first Supplier in the list  then active the very next otherwise highlight Supplier above the deleted Supplier
            let foundIndex = suppliersList.findIndex(
              (l) => l.id === activeSupplier
            );
            if (foundIndex != -1 && foundIndex === 0) {
              let sup = suppliersList[foundIndex + 1];
              if (sup) {
                let data = {
                  currency: sup.currency,
                  code: sup.code,
                  id: sup.id,
                  guid: sup.guid,
                };
                await this.getSupplierSummary(data);
              }
            } else {
              let sup = suppliersList[foundIndex - 1];
              if (sup) {
                let data = {
                  currency: sup.currency,
                  code: sup.code,
                  id: sup.id,
                  guid: sup.guid
                };
                await this.getSupplierSummary(data);
              }
            }
            let list = suppliersList.filter((l) => l.supplierID != _trans);
            //decrease the tallies count also
            supplierTallies.map((t, i) => {
              if (
                showSupplierTallisTabPane.toLocaleLowerCase() ===
                t.type.toLocaleLowerCase()
              ) {
                if (t.count === 1) {
                  t.count = 0;
                } else {
                  t.count = t.count - 1;
                }
              }
              return t;
            });
            this.setState({
              suppliersList: list,
              clonedSuppliersList: list,
              supplierTallies,
              multipleSupplierIDs: [],
            });
          } else {
            //when user delete other Supplier by checking the check box
            let list = suppliersList.filter((l) => l.supplierID != _trans);
            //decrease the tallies count also
            supplierTallies.map((t, i) => {
              if (
                showSupplierTallisTabPane.toLocaleLowerCase() ===
                t.type.toLocaleLowerCase()
              ) {
                if (t.count === 1) {
                  t.count = 0;
                } else {
                  t.count = t.count - 1;
                }
              }
              return t;
            });
            this.setState({
              suppliersList: list,
              clonedSuppliersList: list,
              supplierTallies,
              multipleSupplierIDs: [],
            });
          }
        }
      }
      if (this.props.supplier.deleteSupplierError) {
        Helpers.handleAPIErr(
          this.props.supplier.deleteSupplierError,
          this.props
        );
      }
    }
    this.setState({
      isLoading: false,
    });
    // this.clearStates()
  };

  // -------------- send for approval supplier-------------=-----------

  sendForApproval = async () => {
    let { currency, code, supplierID, multipleSupplierIDs, supplierGuid } = this.state;

    let _trans = "";
    if (multipleSupplierIDs.length > 0) {
      if (multipleSupplierIDs.length == 1) {
        _trans = multipleSupplierIDs[0];
      } else {
        toast.error("Only One Supplier can be Send for approval at a Time!");
      }
    } else {
      _trans = supplierID;
    }

    if (supplierGuid) {
      this.setState({
        isLoading: true,
      });
      await this.props.sendForApproval([supplierGuid]);
      if (this.props.supplier.sendForApprovalSupplierSuccess) {
        toast.success(this.props.supplier.sendForApprovalSupplierSuccess);
        let {
          // supplierID,
          suppliersList,
          supplierTallies,
          activeSupplier,
          activeSupplierTallis,
          showSupplierTallisTabPane,
        } = this.state;

        if (suppliersList.length === 1) {
          await this.getSupplierTallies(showSupplierTallisTabPane, true);
          this.setState({
            multipleSupplierIDs: [],
            suppliersListSearch: "",
          });
          // supplierTallies.map((t, i) => {
          //   if (
          //     showSupplierTallisTabPane.toLocaleLowerCase() ===
          //     t.type.toLocaleLowerCase()
          //   ) {
          //     if (t.count === 1) {
          //       t.count = 0;
          //     } else {
          //       t.count = t.count - 1;
          //     }
          //   }
          //   return t;
          // });
          // this.setState({
          //   activeSupplierTallis,
          //   showSupplierTallisTabPane,
          //   supplierTallies,
          // });
        } else if (suppliersList.length > 1) {
          if (_trans === supplierID) {
            //when user delete the current selected Supplier
            //there are two cases if the user deletes the first Supplier in the list  then active the very next otherwise highlight Supplier above the deleted Supplier
            let foundIndex = suppliersList.findIndex(
              (l) => l.id === activeSupplier
            );
            if (foundIndex != -1 && foundIndex === 0) {
              let sup = suppliersList[foundIndex + 1];
              if (sup) {
                let data = {
                  currency: sup.currency,
                  code: sup.code,
                  id: sup.id,
                  guid: sup.guid
                };
                await this.getSupplierSummary(data);
              }
            } else {
              let sup = suppliersList[foundIndex - 1];
              if (sup) {
                let data = {
                  currency: sup.currency,
                  code: sup.code,
                  id: sup.id,
                  guid: sup.guid
                };
                await this.getSupplierSummary(data);
              }
            }
            let list = suppliersList.filter((l) => l.supplierID != _trans);
            //decrease the tallies count also
            supplierTallies.map((t, i) => {
              if (
                showSupplierTallisTabPane.toLocaleLowerCase() ===
                t.type.toLocaleLowerCase()
              ) {
                if (t.count === 1) {
                  t.count = 0;
                } else {
                  t.count = t.count - 1;
                }
              }
              return t;
            });
            this.setState({
              suppliersList: list,
              clonedSuppliersList: list,
              supplierTallies,
              multipleSupplierIDs: [],
            });
          } else {
            //when user delete other Supplier by checking the check box
            let list = suppliersList.filter((l) => l.supplierID != _trans);
            //decrease the tallies count also
            supplierTallies.map((t, i) => {
              if (
                showSupplierTallisTabPane.toLocaleLowerCase() ===
                t.type.toLocaleLowerCase()
              ) {
                if (t.count === 1) {
                  t.count = 0;
                } else {
                  t.count = t.count - 1;
                }
              }
              return t;
            });
            this.setState({
              suppliersList: list,
              clonedSuppliersList: list,
              supplierTallies,
              multipleSupplierIDs: [],
            });
          }
        }
      }
      if (this.props.supplier.sendForApprovalSupplierError) {
        Helpers.handleAPIErr(
          this.props.supplier.sendForApprovalSupplierError,
          this.props
        );
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  // -------------- unlock supplier-------------=-----------

  unlockSupplier = async () => {
    let {
      currency,
      code,
      multipleSupplierIDs,
      supplierID,
      suppliersList,
      activeSupplier,
    } = this.state;
    let _trans = "";
    if (multipleSupplierIDs.length > 0) {
      if (multipleSupplierIDs.length == 1) {
        _trans = multipleSupplierIDs[0];
      } else {
        toast.error("Only One supplier can be Unlock at a Time!");
      }
    } else {
      _trans = supplierID;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });

      let supplierDetails = {
        currency,
        code,
      };

      await this.props.unlockSupplier(supplierDetails);

      if (this.props.supplier.unlockSupplierSuccess) {
        toast.success(this.props.supplier.unlockSupplierSuccess);
        // activeSupplier =suppliersList[0].id;
      }

      if (this.props.supplier.unlockSupplierError) {
        Helpers.handleAPIErr(
          this.props.supplier.unlockSupplierError,
          this.props
        );
      }
    }

    this.setState({
      isLoading: false,
      // suppliersList,
      // activeSupplier
    });
  };
  //-----------------move aupplier-------------------------
  moveSupplier = async () => {
    let {
      currency,
      code,
      multipleSupplierIDs,
      supplierID,
      suppliersList,
      activeSupplier,
      supplierGuid
    } = this.state;
    let _trans = "";
    if (multipleSupplierIDs.length > 0) {
      if (multipleSupplierIDs.length == 1) {
        _trans = multipleSupplierIDs[0];
      } else {
        toast.error("Only One supplier can be Move at a Time!");
      }
    } else {
      _trans = supplierID;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });


      await this.props.moveSupplier([supplierGuid]);

      if (this.props.supplier.moveSupplierSuccess) {
        toast.success(this.props.supplier.moveSupplierSuccess);
        // activeSupplier =suppliersList[0].id;
      }

      if (this.props.supplier.moveSupplierError) {
        Helpers.handleAPIErr(this.props.supplier.moveSupplierError, this.props);
      }
    }

    this.setState({
      isLoading: false,
      // suppliersList,
      // activeSupplier
    });
  };

  //-----------------decline aupplier-------------------------

  declineSupplier = async (reason) => {
    let {
      currency,
      code,
      multipleSupplierIDs,
      supplierID,
      suppliersList,
      activeSupplier,
      supplierGuid
    } = this.state;
    let _trans = "";
    if (multipleSupplierIDs.length > 0) {
      if (multipleSupplierIDs.length == 1) {
        _trans = multipleSupplierIDs[0];
      } else {
        toast.error("Only One supplier can be Decline at a Time!");
      }
    } else {
      _trans = supplierID;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });

      await this.props.declineSupplier([supplierGuid]);

      if (this.props.supplier.declineSupplierSuccess) {
        toast.success(this.props.supplier.declineSupplierSuccess);
        await this.getSupplierTallies(
          this.state.showSupplierTallisTabPane,
          true
        ); //to refresh the list

        // activeSupplier =suppliersList[0].id;
      }

      if (this.props.supplier.declineSupplierError) {
        Helpers.handleAPIErr(
          this.props.supplier.declineSupplierError,
          this.props
        );
      }
    }
    this.setState({
      isLoading: false,
    });
  };
  //-----------------hold aupplier-------------------------
  holdSupplier = async (reason) => {
    let {
      currency,
      code,
      multipleSupplierIDs,
      supplierID,
      suppliersList,
      activeSupplier,
      supplierGuid
    } = this.state;
    let _trans = "";
    if (multipleSupplierIDs.length > 0) {
      if (multipleSupplierIDs.length == 1) {
        _trans = multipleSupplierIDs[0];
      } else {
        toast.error("Only One supplier can be Decline at a Time!");
      }
    } else {
      _trans = supplierID;
    }

    if (_trans) {
      this.setState({
        isLoading: true,
      });

      await this.props.holdSupplier([supplierGuid]);

      if (this.props.supplier.holdSupplierSuccess) {
        toast.success(this.props.supplier.holdSupplierSuccess);
        await this.getSupplierTallies(
          this.state.showSupplierTallisTabPane,
          true
        ); //to refresh the list

        // activeSupplier =suppliersList[0].id;
      }

      if (this.props.supplier.holdSupplierError) {
        Helpers.handleAPIErr(this.props.supplier.holdSupplierError, this.props);
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  //-------------------------------------export Suppliers-------------------------

  exportSuppliers = async (name) => {
    let { multipleSupplierIDs } = this.state;

    if (name == "EXCEL") {
      if (multipleSupplierIDs.length > 0) {
        this.setState({ isLoading: true });
        await this.props.exportSuppliers(multipleSupplierIDs);
        this.setState({ isLoading: false });

        if (this.props.supplier.exportSuppliersSuccess) {
          toast.success(this.props.supplier.exportSuppliersSuccess);

          let obj = {
            contentType: "application/vnd.ms-excel",
            attachment: this.props.supplier.exportSuppliers || "",
          };
          Helpers.downloadAttachments(obj, "suppliers");
        }
        if (this.props.supplier.exportSuppliersError) {
          Helpers.handleAPIErr(
            this.props.supplier.exportSuppliersError,
            this.props
          );
        }
        this.props.clearSupplierStates();
      } else {
        toast.error("Please Select Suppliers First!");
      }
    } else if (name === "PSL") {
    }
  };

  //--------------------------approve supplier----------------------

  approveSupplier = async () => {
    let {
      currency,
      code,
      multipleSupplierIDs,
      supplierID,
      suppliersList,
      activeSupplier,
      supplierGuid
    } = this.state;

    let _trans = "";
    if (multipleSupplierIDs.length > 0) {
      if (multipleSupplierIDs.length == 1) {
        _trans = multipleSupplierIDs[0];
      } else {
        toast.error("Only One supplier can be Unlock at a Time!");
      }
    } else {
      _trans = supplierID;
    }

    if (_trans) {
      this.setState({ isLoading: true });

      await this.props.approveSupplier(supplierGuid);
      if (this.props.supplier.approveSupplierSuccess) {
        // toast.success(this.props.supplier.approveSupplierSuccess);
        await this.getSupplierTallies(
          this.state.showSupplierTallisTabPane,
          true
        );
      }
      if (this.props.supplier.approveSupplierError) {
        Helpers.handleAPIErr(
          this.props.supplier.approveSupplierError,
          this.props
        );
      }
      this.props.clearSupplierStates();
      this.setState({ isLoading: false });
    }
  };

  //------------------------get supplier activity---------------------
  getSupplierActivity = async () => {
    let { currency, code, suppliersActivity } = this.state;
    if (suppliersActivity.length > 0) {
      this.openModal("openActivityModal");
      return;
    }

    currency = currency || "";
    if (currency && code) {
      let obj = {
        supplierDetails: {
          currency,
          code,
        },
      };
      this.setState({ isLoading: true });
      await this.props.getSupplierActivity(obj);
      this.setState({ isLoading: false });

      if (this.props.supplier.getSupplierActivitySuccess) {
        toast.success(this.props.supplier.getSupplierActivitySuccess);

        let suppliersActivity = this.props.supplier.getSupplierActivity || [];

        this.setState(
          {
            suppliersActivity,
          },
          () => {
            this.openModal("openActivityModal");
          }
        );
      }
      if (this.props.supplier.getSupplierActivityError) {
        Helpers.handleAPIErr(
          this.props.supplier.getSupplierActivityError,
          this.props
        );
      }
      this.props.clearSupplierStates();
    } else {
      toast.error("Code or Currency is missing!");
    }
  };

  openModal = (name) => {
    this.setState({ [name]: true }, () => {
      if (
        name === "openActivityModal" ||
        name === "openPaymentsModal" ||
        name === "openTransactionModal"
      ) {
        let columns = [];
        let aoColumns = [];
        let modalName = "";
        if (name === "openActivityModal") {
          columns = [
            { name: "User", hide: false },
            { name: "Description", hide: false },
            { name: "Date & Time", hide: false },
            { name: "IP Address", hide: false },
          ];

          //adding the column names
          columns.map((c) => aoColumns.push({ sName: c.name }));
          aoColumns[columns.length] = { sName: "menus" };

          //adding the column names
          aoColumns[0] = { sName: "User" };
          aoColumns[1] = { sName: "Description" };
          aoColumns[2] = { sName: "Date & Time" };
          aoColumns[3] = { sName: "IP Address" };
          aoColumns[4] = { sName: "menus" };
          modalName = "supplierActivity";
        } else if (name === "openPaymentsModal") {
          columns = [
            { name: "Cheque", hide: false },
            { name: "ChqDate", hide: false },
            { name: "Status", hide: false },
            { name: "Amount", hide: false },
            { name: "Cu", hide: false },
            { name: "Pres", hide: false },
            { name: "PresDate", hide: false },
            { name: "Bank", hide: false },
            { name: "PosPay", hide: false },
          ];
          //adding the column names
          aoColumns[0] = { sName: "checkbox" };
          columns.map((c) => aoColumns.push({ sName: c.name }));
          aoColumns[columns.length + 1] = { sName: "menus" };

          modalName = "payments";
        } else if (name === "openTransactionModal") {
          columns = [
            { name: "Amount", hide: false },
            { name: "Cu", hide: false },
            { name: "Invoice", hide: false },
            { name: "InvDate", hide: false },
            { name: "DueDate", hide: false },
            { name: "Reference", hide: false },
            { name: "Bank", hide: false },
            { name: "Sep", hide: false },
            { name: "Hold", hide: false },
            { name: "Cheque", hide: false },
            { name: "ChqDate", hide: false },
            { name: "PresDate", hide: false },
            { name: "Period", hide: false },
            { name: "Voucher", hide: false },
            { name: "Batch", hide: false },
            { name: "Tran", hide: false },
          ];
          //adding the column names
          aoColumns[0] = { sName: "checkbox" };
          columns.map((c) => aoColumns.push({ sName: c.name }));
          aoColumns[columns.length + 1] = { sName: "menus" };
          modalName = "transactions";
        }
        let result = Helpers.tableSetting(columns, aoColumns, modalName);
        this.setState({ ...result, columns });
      }
    });
  };

  closeModal = (name) => {
    let { columns, pageLength, checkAll } = this.state;
    if (
      name === "openActivityModal" ||
      name === "openPaymentsModal" ||
      name === "openTransactionModal"
    ) {
      columns = [];
      pageLength = "";
      checkAll = false;
    }
    this.setState({ [name]: false, columns, pageLength, checkAll });
  };

  handleChangeSettings = (e, i) => {
    const { name, value } = e.target;
    if (name === "pageLength") {
      this.setState({ pageLength: value });
    } else {
      let { columns } = this.state;
      columns[i].hide = e.target.checked;
      this.setState({ columns });
    }
  };

  handleSaveSettings = (name) => {
    let { columns, pageLength } = this.state;
    Helpers.handleSaveSettings(columns, name, pageLength);
    this.closeModal("openSettingsModal");
  };

  handleCloseSettingModal = (name) => {
    let { columns } = this.state;
    let result = Helpers.handleCloseSettingModal(columns, name);
    this.setState({ ...result }, () => {
      this.closeModal("openSettingsModal");
    });
  };
  handleRightSidebar = () => {
    this.setState((prevState, props) => ({
      toggleRightSidebar: !prevState.toggleRightSidebar,
    }));
  };

  // openModal = async (name) => {
  //   await this.moveSupplier()
  //   this.setState({ [name]: true });
  // };

  closeModal = (name) => {
    this.setState({
      [name]: false,
    });
  };

  zoomIn = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue } = Helpers.zoomIn(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  zoomOut = async () => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");

    let { scaling } = this.state;

    let { scale, dropdownZoomingValue } = Helpers.zoomOut(scaling);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          scaling == 2.5 ||
          scaling == 2.2 ||
          scaling == 1.9 ||
          scaling == 1.6 ||
          scaling == 1.3
        ) {
          if ($(window).width() >= 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleDropdownZooming = async (data) => {
    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_new").removeClass("full_screen_convas");
    let value = data.value;

    let { scale, dropdownZoomingValue } = Helpers.handleDropdownZooming(value);

    this.setState(
      {
        scaling: scale,
        dropdownZoomingValue,
      },
      () => {
        if (
          value == "25%" ||
          value == "20%" ||
          value == "15%" ||
          value == "10%" ||
          value == "5%"
        ) {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").addClass("invoice_pdf_new1");
          }
        } else {
          if ($(window).width() < 2045) {
            $(".invoice_pdf_new").removeClass("invoice_pdf_new1");
          }
        }
      }
    );
  };

  handleRightSidebar = () => {
    this.setState((prevState, props) => ({
      toggleRightSidebar: !prevState.toggleRightSidebar,
    }));
  };

  handleHorizontalArrow = () => {
    $(".invoice_pdf_new").addClass("invoice_carousel_pdf");

    $(".invoice_pdf_new").removeClass("full_screen_convas");

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.4,
        dropdownZoomingValue: { label: "40%", value: "40%" },
      });
    }
  };

  onLoadSuccessPage = (page) => {
    var vw = $(window).width();
    var vw = $(".side-attachments").width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var h = wh - nav - 60;
    var rsb = wh - nav - underNav - 20;
    // var pdfDiv = wh - nav - underNav - 80
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;
    $("#order--dynamic--height").css("height", h);
    $(".side-attachments,.side-attachments-2").css("height", rsb);
    // $('#maped_image').css('height', pdfDiv)
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);
  };

  handlePDFRotate = () => {
    this.setState({ rotate: this.state.rotate + 90 });
  };

  onDocumentLoadSuccess = (data, index) => {
    this.setState({ isLoading: true });
    let numPages = data.numPages;
    let { numPagesArr } = this.state;
    numPagesArr[index] = numPages;

    if (index === 0) {
      this.setState({ numPages });
    }
    this.setState({ numPagesArr, isLoading: false }, () => {
      this.settPreviewArrows();
    });
  };

  goToPrevPage = () => {
    let { pageNumber } = this.state;
    if (pageNumber - 1 >= 1) {
      this.setState({ pageNumber: pageNumber - 1 });
    }
  };

  goToNextPage = () => {
    let { pageNumber, numPages } = this.state;
    if (pageNumber + 1 <= numPages) {
      this.setState({ pageNumber: pageNumber + 1 });
    }
  };

  onSlideChange = () => {
    //carusal get active slide
    this.setState({ pageNumber: 1, rotate: 0 });
    //it takes time to render in DOM
    setTimeout(() => {
      var currentIndex = $(".carousel-item.active").attr("id");
      let numPages = this.state.numPagesArr[currentIndex] || 1;
      this.setState({ numPages });
      this.settPreviewArrows();
    }, 700);
  };

  settPreviewArrows = () => {
    // Make the arrows be a bit smarter. Dont appear
    //if only one attachment, hide the left or right arrow if on "first" or "last" document.
    if ($(".carousel-item.active").attr("id") === "0") {
      $(".carousel-control-prev").hide();
      $(".carousel-control-next").show();
    } else if ($(".carousel-inner .carousel-item:last").hasClass("active")) {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").hide();
    } else {
      $(".carousel-control-prev").show();
      $(".carousel-control-next").show();
    }
  };

  handleHorizontalCross = () => {
    $(".mm_invoice_div").addClass("over_auto_remove");
    $(".invoice_pdf_new").addClass("full_screen_convas");
    var vw = $(window).width();
    var nav = $(".navbar.fixed-top").height();
    var underNav = $(".order-tabs").height();
    var wh = $(window).height();
    var pdfDiv = wh - nav;
    var wh = wh - nav - underNav;

    $(".invoice_pdf_new").removeClass("invoice_carousel_pdf");
    $(".invoice_pdf_canvas.invoice_pdf_new").css("height", wh);

    if ($(window).width() > 1500) {
      this.setState({
        scaling: 7,
        dropdownZoomingValue: { label: "100%", value: "100%" },
      });
    } else if ($(window).width() <= 1500) {
      this.setState({
        scaling: 3.1,
        dropdownZoomingValue: { label: "35%", value: "35%" },
      });
    }
  };

  //call get expense list API
  toggleTeamIcon = (check) => {
    localStorage.setItem("teamExpense", check);
    this.setState({ viewTeam: check }, () => {
      let { activeExpTallies, showExpTalliesTabPane } = this.state;
      let obj = {
        id: activeExpTallies,
        type: showExpTalliesTabPane,
      };
      this.getExpenseList(obj);
    });
  };

  onExport = (name) => {
    if (name === "LIST") {
      this.exportList();
    } else {
      //Envelope
      this.exportEnvelope();
    }
  };
  //add attachment
  addAttachment = async (fileList) => {
    let { supplierID, currency, code, supplierGuid } = this.state;
    if (supplierID) {
      this.setState({ isLoading: true });
      let data = {
        fileName: fileList[0]?.fileName,
        attachment: fileList[0]?.attachment,
        supplierID,
        guid: supplierGuid,
      };

      if (isRemoteStorage()) {
        await uploadFileListToS3(fileList, async (uploadedFiles) => {
          const obj = {
            guid: supplierGuid,
            fileName: uploadedFiles[0].fileName,
            documentGUID: uploadedFiles[0].documentGuid,
            primaryAttachment: "Y",
          };

           await this.props.addSupAttachments(obj);
           if (this.props.supplier.addSupAttachmentsSuccess) {
             toast.success(this.props.supplier.addSupAttachmentsSuccess);
             let attachments = this.props.supplier.addSupAttachments || [];
             let attachmentSize = 0;
             attachments.map((a, i) => {
               attachmentSize += Number(a.fileSize) || 0;
             });
             this.setState({ attachments, attachmentSize });
           }
           if (this.props.supplier.addSupAttachmentsError) {
             Helpers.handleAPIErr(
               this.props.supplier.addSupAttachmentsError,
               this.props
             );
           }
           this.props.clearSupplierStates();
          });
          this.setState({ isLoading: false });
      } else {
        await this.props.addSupAttachments(data);
        if (this.props.supplier.addSupAttachmentsSuccess) {
          toast.success(this.props.supplier.addSupAttachmentsSuccess);
          let attachments = this.props.supplier.addSupAttachments || [];
          let attachmentSize = 0;
          attachments.map((a, i) => {
            attachmentSize += Number(a.fileSize) || 0;
          });
          this.setState({ attachments, attachmentSize });
        }
        if (this.props.supplier.addSupAttachmentsError) {
          Helpers.handleAPIErr(
            this.props.supplier.addSupAttachmentsError,
            this.props
          );
        }
        this.props.clearSupplierStates();
        this.setState({ isLoading: false });
      }
    } else {
      toast.error("Please Select an Supplier");
    }
  };
  //get Attachment
  getAttachment = async (recordID, type, fileName) => {
    this.setState({ isLoading: true });

    await this.props.getAttachment(recordID);
    if (this.props.supplier.getSupAttachmentSuccess) {
      let resp = this.props.supplier.getSupAttachment;
       if (resp.documentPresignedURL) {
         downloadFileFromPresignedUrl(resp.documentPresignedURL);
       } else {
         Helpers.downloadAttachments(resp, fileName, true);
       }
    }
    if (this.props.supplier.getSupAttachmentError) {
      Helpers.handleAPIErr(
        this.props.supplier.getSupAttachmentError,
        this.props
      );
    }
    this.props.clearSupplierStates();
    this.setState({ isLoading: false });
  };

  handleApprovalsFilters = (e, obj) => {
    let checked = e.target.checked;
    obj.checked = checked;

    let approvalsGroups = this.state.approvalsGroups;
    let foundIndex = approvalsGroups.findIndex((a) => a.id == obj.id);
    approvalsGroups[foundIndex] = obj;

    this.setState({
      approvalsGroups,
    });
    let check = false;
    let count = 0;
    approvalsGroups.map((a, i) => {
      if (!a.checked) {
        count += 1;
      }
    });
    if (approvalsGroups.length === count) {
      check = true;
    }
    const clonedSuppliersList = JSON.parse(
      JSON.stringify(this.state.clonedSuppliersList)
    );

    if (check) {
      //all checkboxes are uncheck
      this.setState(
        {
          suppliersList: clonedSuppliersList,
          filteredSuppliersList: clonedSuppliersList,
        }
        // ,
        // () => {
        //   this.handleSortInvoiceLists(this.state.sortFilterInvc);
        // }
      );
    } else {
      let filterdData = [];

      approvalsGroups.map((a, i) => {
        let suppliersListFilterdData = [];
        if (a.checked) {
          suppliersListFilterdData = clonedSuppliersList.filter((c) => {
            return (
              c.approvalGroup &&
              c.approvalGroup.toUpperCase() === a.groupName.toUpperCase()
            );
          });
        }
        filterdData.push(...suppliersListFilterdData);
      });

      this.setState(
        {
          suppliersList: filterdData,
          filteredSuppliersList: filterdData,
        }
        // ,
        // () => {
        //   this.handleSortInvoiceLists(this.state.sortFilterInvc);
        // }
      );
    }
  };

  // move to previous Supplier
  moveToPrevInvoice = async () => {
    let { getInvoicesList, activeInvoice } = this.state;
    let foundIndex = getInvoicesList.findIndex((l) => l.id === activeInvoice);

    if (foundIndex != -1 && foundIndex != 0) {
      let inv = getInvoicesList[foundIndex - 1];
      if (inv) {
        await this.getInvoice(inv);
      }
    }
  };

  // move to next Supplier
  moveToNextInvoice = async () => {
    let { getInvoicesList, activeInvoice } = this.state;
    let foundIndex = getInvoicesList.findIndex((l) => l.id === activeInvoice);

    if (foundIndex != -1) {
      let inv = getInvoicesList[foundIndex + 1];
      if (inv) {
        await this.getInvoice(inv);
      }
    }
  };

  render() {
    let { showSupplierTallisTabPane } = this.state;
    let tab =
      (showSupplierTallisTabPane && showSupplierTallisTabPane.toLowerCase()) ||
      "";

    return (
      <>
        {this.state.isLoading ? <div className="se-pre-con"></div> : ""}

        <div className="dashboard">
          {/* top nav bar */}
          <Header props={this.props} supplier={true} />
          {/* end */}

          {/* body part */}

          <div className="dashboard_body_content dash__invoice--content">
            {/* top Nav menu*/}
            <TopNav />
            {/* end */}

            {/* side menu po*/}
            <aside
              className="side-nav suppliers_side_nav side__content--invoice"
              id="show-side-navigation12"
            >
              <div className="cus-arro-div2">
                <img
                  src="images/arrow-r.png"
                  className=" img-fluid cus-arro-r"
                  alt="user"
                />
              </div>
              <div className="search">
                <div className="row">
                  <div className="col-auto mb-2 pr-0">
                    <div className="form-group remember_check custom-checkbox-ui">
                      <input
                        type="checkbox"
                        id={"supplier"}
                        // checked={l.checked}
                        name="checkboxAll"
                        checked={this.state.allSelected}
                        onChange={(e) => this.handleCheckbox(e, "allCheck")}
                      />
                      <label
                        htmlFor={"supplier"}
                        className="mr-0 custom-box"
                      ></label>
                    </div>
                  </div>
                  <div className="col-auto pr-md-0 align-self-center ml-1">
                    <Dropdown
                      alignRight="false"
                      drop="down"
                      className="analysis-card-dropdwn float-right user_drop_options custom-my-radio "
                    >
                      <Dropdown.Toggle
                        variant="sucess"
                        id="dropdown-basic"
                        className="custom-angle-down"
                      >
                        <img src="images/angle-down.png" alt="arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          {" "}
                          <div
                            onClick={() => this.handleSortSupplierLists("name")}
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="name1"
                              name="name"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "name"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="name1"
                            >
                              Name
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() => this.handleSortSupplierLists("code")}
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="code2"
                              name="code"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "code"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="code2"
                            >
                              Code
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() =>
                              this.handleSortSupplierLists("currency")
                            }
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="currency3"
                              name="currency"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "currency"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="currency3"
                            >
                              Currency
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() => this.handleSortSupplierLists("city")}
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="city4"
                              name="city"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "city"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="city4"
                            >
                              City
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() =>
                              this.handleSortSupplierLists("state")
                            }
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="state5"
                              name="state"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "state"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="state5"
                            >
                              State
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() =>
                              this.handleSortSupplierLists("postcode")
                            }
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="postcode6"
                              name="postcode"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "postcode"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="postcode6"
                            >
                              Postcode
                            </label>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="#/action-2"
                          className="f-20 flex-container-inner"
                        >
                          <div
                            onClick={() =>
                              this.handleSortSupplierLists("country")
                            }
                            className="custom-control custom-radio flex-container-inner"
                          >
                            <input
                              type="radio"
                              className="custom-control-input flex-container-inner-input"
                              id="country7"
                              name="country"
                              onChange={() => {}}
                              checked={this.state.sortFilter === "country"}
                            />
                            <label
                              className="custom-control-label flex-container-inner-input"
                              htmlFor="country7"
                            >
                              Country
                            </label>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <img
                          src="images/search-icon.png"
                          className="mx-auto"
                          alt="search-icon"
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="What are you looking for"
                      aria-label="What are you looking for"
                      aria-describedby="basic-addon1"
                      name="POListSearch"
                      id="POListSearchId"
                      value={this.state.suppliersListSearch}
                      onChange={this.suppliersListSearch}
                      onKeyDown={this.onPOListSearch}
                    />
                  </div>
                </div>
              </div>
              <ul className="suppliers_list">
                {this.state.suppliersList.map((s, i) => {
                  return (
                    <li
                      key={i}
                      className={
                        this.state.activeSupplier == s.id
                          ? "active cursorPointer"
                          : "cursorPointer"
                      }
                      id={s.id}
                    >
                      {" "}
                      <div className="row">
                        <div className="col-auto mb-2 pr-0">
                          <div className="form-group remember_check">
                            <input
                              type="checkbox"
                              id={"sup" + i}
                              name="checkbox"
                              checked={s.checked}
                              onChange={(e) => this.handleCheckbox(e, s, i)}
                            />
                            <label htmlFor={"sup" + i} className="mr-0"></label>
                          </div>
                        </div>
                        <div
                          onClick={() => this.getSupplierSummary(s)}
                          className="col-10 pl-0"
                        >
                          <div className="supplier_data">
                            <h4>{s.name}</h4>
                            <p>{s.email}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </aside>
            {/* {/ end /} */}

            <section id="contents" className="supplier pr-0 pt-0">
              <div className="body_content ordermain-padi body__invoice--top">
                <div className="container-fluid pl-0 ">
                  <div className="main_wrapper " id="order--dynamic--height">
                    <div className="row d-flex pl-15">
                      <div className="col-12 w-100 order-tabs p-md-0">
                        {/* PO Tallies */}
                        <div className="nav_tav_ul">
                          <ul className="nav nav-tabs">
                            {this.state.supplierTallies.map((t, i) => {
                              return (
                                <li
                                  key={i}
                                  className="cursorPointer nav-item"
                                  onClick={() =>
                                    this.getSupplierTallies(t.type)
                                  }
                                >
                                  <a
                                    className={
                                      this.state.activeSupplierTallis === t.id
                                        ? "nav-link active"
                                        : "nav-link"
                                    }
                                  >
                                    {t.type}{" "}
                                    <span className="stats">{t.count}</span>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="bg-gry w-100 float-left mm_top_nav">
                          <div className="w-100 float-left mm_lr_pad">
                            <div className="mm_tab_left order_left_icons">
                              <div className="tab-content">
                                {tab === "draft" && (
                                  <div className="tab-pane container active">
                                    <ul>
                                      <li
                                        className="cursorPointer"
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.draftEditSupplier(
                                                "newSupplier"
                                              )
                                            : () => {}
                                        }
                                        // onClick={() =>
                                        //   this.props.history.push(
                                        //     "/new-supplier2", {tran: "newSupplier"}
                                        //   )
                                        // }
                                      >
                                        <img
                                          src="images/add.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#">New</Link>{" "}
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.draftEditSupplier("")
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#">Edit</Link>{" "}
                                      </li>
                                      {/* <li className="cursorPointer">
                                        <img
                                          src="images/copy1.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Copy </Link>
                                      </li> */}

                                      <li
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.deleteSupplier()
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/delete.svg"
                                          className="invoice-delete-icon img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Delete </Link>
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.sendForApproval()
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/send.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Send </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "approve" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.approveSupplier()
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/tick.png"
                                          className="img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Approve </Link>
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.holdSupplier()
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/move.png"
                                          className=" img-fluid"
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Hold </Link>
                                      </li>
                                      <li
                                        onClick={() =>
                                          // this.openModal("openDeclineModal")
                                          this.state.suppliersList.length > 0
                                            ? this.declineSupplier()
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/decline.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Decline </Link>
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.draftEditSupplier("")
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "declined" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    {!false && (
                                      <ul>
                                        <li
                                          className="cursorPointer"
                                          onClick={() =>
                                            this.state.suppliersList.length > 0
                                              ? this.moveSupplier()
                                              : () => {}
                                          }
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Move </Link>
                                        </li>
                                      </ul>
                                    )}
                                  </div>
                                )}
                                {tab === "hold" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.approveSupplier()
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/tick.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Approve </Link>
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.declineSupplier()
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/decline.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Decline </Link>
                                      </li>
                                      <li
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.draftEditSupplier()
                                            : () => {}
                                        }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/pencill.png"
                                          className=" img-fluid "
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Edit </Link>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                {tab === "pending" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    {!false && (
                                      <ul>
                                        <li
                                          onClick={() =>
                                            this.state.suppliersList.length > 0
                                              ? this.moveSupplier()
                                              : () => {}
                                          }
                                          // onClick={() =>
                                          //   false
                                          //     ? this.openModal("")
                                          //     : this.openModal(
                                          //       "openMoveToDraftModal"
                                          //     )
                                          // }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/move.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Move </Link>
                                        </li>

                                        {/* <li
                                          onClick={() =>
                                            false
                                              ? this.openModal("")
                                              : this.openModal("openCopyModal")
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/copy1.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Copy </Link>
                                        </li> */}
                                      </ul>
                                    )}
                                  </div>
                                )}
                                {tab === "approved" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      {/* {!false && (
                                        <li
                                          onClick={() =>
                                            this.openModal("openCopyModal")
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/copy1.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Copy </Link>
                                        </li>
                                      )} */}
                                      <li
                                        onClick={() =>
                                          this.state.suppliersList.length > 0
                                            ? this.draftEditSupplier()
                                            : () => {}
                                        }
                                        // onClick={() =>
                                        //   this.openModal("openCloseModal")
                                        // }
                                        className="cursorPointer"
                                      >
                                        <img
                                          src="images/decline.png"
                                          className="top_0 img-fluid"
                                          alt="user"
                                        />{" "}
                                        <Link to="#"> Close </Link>
                                      </li>
                                      {!false && (
                                        <li
                                          onClick={() =>
                                            this.openModal("openModifyModal")
                                          }
                                          className="cursorPointer"
                                        >
                                          <img
                                            src="images/pencill.png"
                                            className=" img-fluid "
                                            alt="user"
                                          />{" "}
                                          <Link to="#"> Modify </Link>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                                {tab === "all" && (
                                  <div
                                    className={
                                      1 === 0
                                        ? "tab-pane container"
                                        : "tab-pane container active"
                                    }
                                  >
                                    <ul>
                                      <li></li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="mm_tab_center order_right_icons">
                              <div className="slider-panel">
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomOut}
                                    src="images/minus.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.zoomIn}
                                    src="images/add.png"
                                    className=" img-fluid float-left"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Select
                                  className="width-selector"
                                  value={this.state.dropdownZoomingValue}
                                  classNamePrefix="custon_select-selector-inner"
                                  options={options}
                                  onChange={this.handleDropdownZooming}
                                  theme={(theme) => ({
                                    ...theme,
                                    border: 0,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f2f2f2",
                                      primary: "#f2f2f2",
                                    },
                                  })}
                                />
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalCross}
                                    src="images/fulls.png"
                                    className="img-fluid float-left"
                                    alt="user"
                                    id="full_screen"
                                  />{" "}
                                </Link>
                                <Link to="#" className="zom-img">
                                  <img
                                    onClick={this.handleHorizontalArrow}
                                    src="images/twoarow.png"
                                    className="img-fluid float-left"
                                    alt="user"
                                    id="expand"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right ml-md-5 pl-2 pr-2 mr-0 more-d mt-0"
                                >
                                  <img
                                    src="images/more.png"
                                    className=" img-fluid"
                                    alt="user"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mt-0"
                                  onClick={this.moveToNextPO}
                                >
                                  <img
                                    src="images/arow-r.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    href="#demo"
                                    data-slide="next"
                                  />{" "}
                                </Link>
                                <Link
                                  to="#"
                                  className="zom-img float-right mtop-1"
                                  onClick={this.moveToPrevPO}
                                >
                                  <img
                                    src="images/arow-l.png"
                                    className=" img-fluid lr-arrow-up"
                                    alt="user"
                                    href="#demo"
                                    data-slide="prev"
                                  />{" "}
                                </Link>
                                <div className="side-attachments-2 height-2 mm_order_sidebar aside__right--height">
                                  {/* <div
                                    onClick={this.getPOLog}
                                    className="main-sec-attach main-bg"
                                  >
                                    PO Log
                                  </div>
                                  <div
                                    onClick={this.downloadPreview}
                                    className="main-sec-attach main-bg"
                                  >
                                    Download Copy
                                    <img
                                      src="images/downlod.png"
                                      className=" img-fluid float-right fa"
                                      alt="user"
                                    />
                                  </div>

                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="invoice-inherit"
                                      data-toggle="collapse"
                                      data-target="#Exclude"
                                    >
                                      <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                                      Exclude
                                    </span>
                                  </div>
                                  <div className="collapse show" id="Exclude">
                                    <div className="pl-2 mb-3">
                                      <div className="form-group remember_check">
                                        <input
                                          type="checkbox"
                                          id="item2"
                                          name="zero"
                                          checked={this.state.zero}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                [e.target.name]:
                                                  e.target.checked,
                                              },
                                              () => {
                                                this.handleExclude();
                                              }
                                            );
                                          }}
                                        />
                                        <label htmlFor="item2">
                                          {" "}
                                          <span className="text-mar">Zero</span>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="pl-2 mb-3">
                                      <div className="form-group remember_check">
                                        <input
                                          type="checkbox"
                                          id="item23"
                                          name="close"
                                          checked={this.state.close}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                [e.target.name]:
                                                  e.target.checked,
                                              },
                                              () => {
                                                this.handleExclude();
                                              }
                                            );
                                          }}
                                        />
                                        <label htmlFor="item23">
                                          {" "}
                                          <span className="text-mar">
                                            Close
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="invoice-inherit2"
                                      data-toggle="collapse"
                                      data-target="#Approvalsa"
                                    >
                                      <span className="fa fa-angle-up float-left mr-2 sideBarAccord2"></span>
                                      Approvals
                                    </span>
                                  </div>
                                  <div
                                    className="collapse show"
                                    id="Approvalsa"
                                  >
                                    {this.state.approvalsGroups.map((a, i) => {
                                      return (
                                        <div key={i} className="pl-2 mb-3">
                                          <div className="form-group remember_check d-flex">
                                            <div className="checkSide">
                                              <input
                                                type="checkbox"
                                                id={i}
                                                name={a.groupName}
                                                checked={a.checked}
                                                onChange={(e) =>
                                                  this.handleApprovalsFilters(
                                                    e,
                                                    a
                                                  )
                                                }
                                              />
                                              <label htmlFor={i}> </label>
                                            </div>
                                            <span className="text-mar">
                                              {a.option}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="fa fa-angle-up float-left mr-2 sideBarAccord"
                                      data-toggle="collapse"
                                      data-target="#Changes"
                                    ></span>
                                    <span
                                      className="name_attached font-weight-bold"
                                      onClick={this.getPOChanges}
                                    >
                                      Changes
                                      <span className="ml-3 font-weight-bold">
                                        0
                                      </span>
                                    </span>
                                  </div>
                                  <div className="collapse show" id="Changes">
                                    {[].map((c, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="main-sec-attach1"
                                        >
                                          <p className="m-clr s-bold mb-0">
                                            {c.userName}
                                          </p>
                                          {c.description}
                                          <p className="gry-clr mb-0">
                                            {c.date} {c.time}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="fa fa-angle-up float-left mr-2 sideBarAccord"
                                      data-toggle="collapse"
                                      data-target="#Activity"
                                    ></span>
                                    <span
                                      className="name_attached font-weight-bold"
                                      onClick={this.getSupplierActivity}
                                    >
                                      Activity
                                    </span>
                                  </div>
                                  <div className="collapse show" id="Activity">
                                    {this.state.activity.map((a, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="main-sec-attach1"
                                        >
                                          {a.description}
                                          <p className="gry-clr mb-0">
                                            {a.dateTime}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="export_crd"
                                      data-toggle="collapse"
                                      data-target="#export"
                                    >
                                      <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                                      Export
                                    </span>
                                  </div>
                                  <div className="collapse show" id="export">
                                    {["EXCEL"].map((op, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="pl-2 mb-3"
                                          onClick={() =>
                                            this.exportSuppliers(op)
                                          }
                                        >
                                          <div className="form-group remember_check d-flex">
                                            <span className="text-mar cursorPointer ml-38">
                                              {op}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div
                                    onClick={() =>
                                      this.openModal("openReportModal")
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Reports
                                  </div>

                                  {tab === "draft" ? (
                                    <div
                                      onClick={() =>
                                        this.openModal("openImportModal")
                                      }
                                      className="main-sec-attach main-bg"
                                    >
                                      Import
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    onClick={() =>
                                      this.openModal("openPostModal")
                                    }
                                    className="main-sec-attach main-bg"
                                  >
                                    Post
                                  </div>
                                  <div className="main-sec-attach main-bg">
                                    <span
                                      className="invoice-inherit"
                                      data-toggle="collapse"
                                      data-target="#batchlist"
                                    >
                                      <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                                      Batch List
                                    </span>
                                  </div>
                                  <div className="collapse show" id="batchlist">
                                    <div className="pl-2 mb-3">
                                      <div className="text-right pb-2 pr-4">
                                        <a class="mr-3" href="#">
                                          <img
                                            src="images/add.png"
                                            class=" img-fluid "
                                            alt="user"
                                          />
                                        </a>
                                        <a class="" href="#">
                                          <img
                                            src="images/delete.svg"
                                            class="invoice-delete-icon img-fluid "
                                            alt="user"
                                          />
                                        </a>
                                      </div>
                                      <table className="table table-bordered mb-0 order-collapse-table">
                                        <tbody>
                                          <tr>
                                            <th>
                                              <div class="form-group remember_check">
                                                <input
                                                  type="checkbox"
                                                  id="oleftcheck1"
                                                />
                                                <label htmlFor="oleftcheck1"></label>
                                              </div>
                                            </th>
                                            <th>Description</th>
                                            <th>Batch</th>
                                            <th></th>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div class="form-group remember_check">
                                                <input
                                                  type="checkbox"
                                                  id="oleftcheck2"
                                                />
                                                <label htmlFor="oleftcheck2"></label>
                                              </div>
                                            </td>
                                            <td>API Batch</td>
                                            <td>2</td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div class="form-group remember_check">
                                                <input
                                                  type="checkbox"
                                                  id="oleftcheck3"
                                                />
                                                <label htmlFor="oleftcheck3"></label>
                                              </div>
                                            </td>
                                            <td>Draft Invoice</td>
                                            <td>3</td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div class="form-group remember_check">
                                                <input
                                                  type="checkbox"
                                                  id="oleftcheck4"
                                                />
                                                <label htmlFor="oleftcheck4"></label>
                                              </div>
                                            </td>
                                            <td>Pending Invoices</td>
                                            <td>4</td>
                                            <td></td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <div class="form-group remember_check">
                                                <input
                                                  type="checkbox"
                                                  id="oleftcheck5"
                                                />
                                                <label htmlFor="oleftcheck5"></label>
                                              </div>
                                            </td>
                                            <td>Approved Invoices</td>
                                            <td>5</td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="img-section-t col-12 pl-0 h-100">
                      <div
                        className={
                          this.state.toggleRightSidebar
                            ? "pdf__height--content mm_ordr1 order_pdf_new h-100"
                            : "pdf__height--content mm_ordr1 order_pdf_expand1 m_auto h-100"
                        }
                        id="overload_image"
                      >
                        <div
                          id="maped_image"
                          className="order_pfd over_auto_remove"
                          style={{ background: "#fff" }}
                        >
                          {this.state.suppliersList.length > 0 && (
                            <div
                              id="demo"
                              className={
                                this.state.toggleRightSidebar
                                  ? " carousel slide invoice_carousel mm_invoice_div over_auto_remove"
                                  : " carousel slide invoice_carousel "
                              }
                              // data-ride="carousel"
                              data-interval={false}
                            >
                              <ul className="carousel-indicators">
                                <li
                                  data-target="#demo"
                                  data-slide-to="0"
                                  className="active"
                                ></li>
                                <li data-target="#demo" data-slide-to="1"></li>
                                <li data-target="#demo" data-slide-to="2"></li>
                              </ul>

                              <div className="carousel-inner">
                                {this.state.previewList.length > 0
                                  ? this.state.previewList.map((p, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className={
                                            i === 0
                                              ? "carousel-item active "
                                              : "carousel-item "
                                          }
                                          id={i}
                                        >
                                          <div className="text-center">
                                            <div className="invoice_pdf_canvas invoice_pdf_new pdf--buttons pdf__height--content expensis-home">
                                              {p.file ? (
                                                <>
                                                  <Document
                                                    file={
                                                      "data:application/pdf;base64," +
                                                      p.file
                                                    }
                                                    onLoadSuccess={(data) =>
                                                      this.onDocumentLoadSuccess(
                                                        data,
                                                        i
                                                      )
                                                    }
                                                    rotate={this.state.rotate}
                                                  >
                                                    <Page
                                                      pageNumber={
                                                        this.state.pageNumber
                                                      }
                                                      scale={this.state.scaling}
                                                      height="372"
                                                      onLoadSuccess={
                                                        this.onLoadSuccessPage
                                                      }
                                                    />
                                                  </Document>
                                                  <div className="page-controls">
                                                    <button
                                                      type="button"
                                                      disabled=""
                                                      onClick={
                                                        this.goToPrevPage
                                                      }
                                                    >
                                                      ‹
                                                    </button>
                                                    <span>
                                                      {this.state.pageNumber} of{" "}
                                                      {this.state.numPages}
                                                    </span>{" "}
                                                    <button
                                                      type="button"
                                                      onClick={
                                                        this.goToNextPage
                                                      }
                                                    >
                                                      ›
                                                    </button>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : "No Preview Found"}

                                {this.state.previewList.length > 1 && (
                                  <>
                                    <a
                                      className="carousel-control-prev"
                                      href="#demo"
                                      data-slide="prev"
                                      onClick={this.onSlideChange}
                                    >
                                      <i>
                                        <span className="carousel-control-prev-icon"></span>
                                      </i>
                                    </a>
                                    <a
                                      className="carousel-control-next"
                                      href="#demo"
                                      data-slide="next"
                                      onClick={this.onSlideChange}
                                    >
                                      <i>
                                        <span className="carousel-control-next-icon"></span>
                                      </i>
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        id="right-sidbar"
                        className="side-attachments mm_order_side aside__right--height"
                      >
                        {" "}
                        <div
                          onClick={this.handleRightSidebar}
                          className="cus-arro-div"
                        >
                          <img
                            src="images/arrow-r.png"
                            className=" img-fluid cus-arro-r"
                            alt="user"
                          />
                        </div>
                        {/* <div className="side-attack">
                          <div
                            onClick={() => this.openModal("openReportModal")}
                            className="main-sec-attach main-bg"
                          >
                            Reports
                          </div>
                          <div className="main-sec-attach main-bg">
                            <span
                              className="export_crd"
                              data-toggle="collapse"
                              data-target="#export"
                            >
                              <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                              Export
                            </span>
                          </div>
                          <div className="collapse show" id="export">
                            {["EXCEL", "PSL"].map((op, i) => {
                              return (
                                <div
                                  onClick={() => this.exportSuppliers(op)}
                                  key={i}
                                  className="pl-2 mb-3"
                                >
                                  <div className="form-group remember_check d-flex">
                                    <span className="text-mar cursorPointer ml-38">
                                      {op}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {tab === "draft" ? (
                            <div
                              onClick={() => this.openModal("openImportModal")}
                              className="main-sec-attach main-bg"
                            >
                              Import
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className="main-sec-attach main-bg"
                            onClick={this.unlockSupplier}
                          >
                            Unlock
                          </div>
                          <div
                            className="main-sec-attach main-bg"
                            onClick={this.approveSupplier}
                          >
                            Approve
                          </div>
                          <div
                            className="main-sec-attach main-bg"
                            onClick={this.getSupplierActivity}
                          >
                            Activity
                          </div>
                          <div
                            className="main-sec-attach main-bg"
                            onClick={this.getPayments}
                          >
                            Payments
                          </div>
                          <div
                            className="main-sec-attach main-bg"
                            onClick={this.getTransactions}
                          >
                            Transactions
                          </div>
                        </div> */}
                        <div className="side-attack">
                          <div className="main-sec-attach main-bg">
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Attachments_Sup"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openAttachmentsModal")
                              }
                            >
                              Attachments
                              <span className="ml-3 font-weight-bold">
                                {this.state.attachments.length}
                              </span>
                              <p className="float-right mr-3">
                                <img
                                  src="images/add.png"
                                  class=" img-fluid sidebarr_plus "
                                  alt="user"
                                />
                              </p>
                            </span>
                          </div>
                          <div className="collapse show" id="Attachments_Sup">
                            {this.state.attachments.map((a, i) => {
                              return (
                                <div
                                  onClick={() =>
                                    this.getAttachment(
                                      a.recordID,
                                      a.fileType,
                                      a.fileName
                                    )
                                  }
                                  key={i}
                                  className="main-sec-attach"
                                >
                                  {a.fileName}{" "}
                                  <span className="fa fa-angle-right"></span>
                                </div>
                              );
                            })}
                          </div>
                          <div className="main-sec-attach main-bg">
                            <span
                              className="invoice-inherit"
                              data-toggle="collapse"
                              data-target="#Approvals"
                            >
                              <span className="fa fa-angle-up float-left mr-2 sideBarAccord1"></span>
                              <span className="name_attached">Approvals</span>
                            </span>
                          </div>
                          <div className="collapse show" id="Approvals">
                            <div className="main-sec-mid">
                              {this.state.approvalGroup}
                            </div>
                            {this.state.approvers.map((a, i) => {
                              return (
                                <div
                                  key={i}
                                  className="main-sec-attach cus-check"
                                >
                                  <div className="form-group remember_check">
                                    {a.status === "Approved" ? (
                                      <input type="checkbox" id={i} checked />
                                    ) : (
                                      ""
                                    )}
                                    {a.status === "Declined" ? (
                                      <i
                                        className="fa fa-times circle-check1 float-left ml-1"
                                        aria-hidden="true"
                                      ></i>
                                    ) : a.status === "Current" ? (
                                      <i
                                        className="fa fa-circle-thin circle-check float-left ml-1"
                                        aria-hidden="true"
                                        style={{ marginTop: "3px" }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                    <label htmlFor={i}>
                                      {" "}
                                      <span
                                        className={
                                          a.status === "Declined"
                                            ? "order-right-color2 selected mm_lcapp"
                                            : a.status === "Current"
                                            ? "order-right-color1 selected mm_lcapp"
                                            : "text-mar"
                                        }
                                      >
                                        {a.approverName}
                                      </span>
                                      {a.status === "Declined" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (user declined)
                                        </span>
                                      ) : a.status === "Current" ? (
                                        <span className="current-approver mm_approre1">
                                          {" "}
                                          (current approver)
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="main-sec-attach main-bg">
                            <span
                              className="fa fa-angle-up float-left mr-2 sideBarAccord"
                              data-toggle="collapse"
                              data-target="#Comments"
                            ></span>
                            <span
                              className="name_attached"
                              onClick={() =>
                                this.openModal("openCommentsModal")
                              }
                            >
                              Comments
                              <span className="ml-3 font-weight-bold">0</span>
                              <a class="float-right mr-3" href="#">
                                <img
                                  src="images/add.png"
                                  class=" img-fluid sidebarr_plus "
                                  alt="user"
                                />
                              </a>
                            </span>
                          </div>
                          <div className="collapse show" id="Comments">
                            {this.state.supplierComments.map((c, i) => {
                              return (
                                <div key={i} className="main-sec-attach1">
                                  <p className="m-clr s-bold mb-0">
                                    {c.userName}
                                  </p>
                                  {c.comment}
                                  <p className="gry-clr mb-0">
                                    {c.date} {c.time}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* end */}
        </div>
        <SupplierActivity
          openModal={this.openModal}
          closeModal={this.closeModal}
          stateData={this.state}
          handleChangeSettings={this.handleChangeSettings}
          handleSaveSettings={() => this.handleSaveSettings("supplierActivity")}
          handleCloseSettingModal={() =>
            this.handleCloseSettingModal("supplierActivity")
          }
        />
        <Report
          openReportModal={this.state.openReportModal}
          closeModal={this.closeModal}
          reportType="Suppliers"
          locationProps={this.props}
        />
        <Attachments
          openAttachmentsModal={this.state.openAttachmentsModal}
          closeModal={this.closeModal}
          attachments={this.state.attachments}
          attachmentSize={this.state.attachmentSize}
          addAttachment={this.addAttachment}
          getAttachment={this.getAttachment}
          draft={tab === "draft" ? true : false} //to hide/show "Drag Files in or Click to Upload" box
        />
        <Comments
          openCommentsModal={this.state.openCommentsModal}
          closeModal={this.closeModal}
          comments={this.state.supplierComments}
          addComment={() => {}}
          tab={
            this.state.showPOTallisTabPane &&
            this.state.showPOTallisTabPane.toLowerCase()
          }
        />
        {/*  <Decline
          openDeclineModal={this.state.openDeclineModal}
          closeModal={this.closeModal}
          onDecline={this.declineInvoice}
        />
        <Delete
          openDeleteModal={this.state.openDeleteModal}
          closeModal={this.closeModal}
          onDelete={this.deleteInvoice}
        />
        <Move
        closeModal={this.closeModal}
        stateDate={this.state}
      moveBatch={this.moveBatch}/> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  supplier: state.supplier,
});

export default connect(mapStateToProps, {
  getSupplierTallies: SupplierActions.getSupplierTallies,
  getSuppliersList: SupplierActions.getSuppliersList,
  deleteSupplier: SupplierActions.deleteSupplier,
  sendForApproval: SupplierActions.sendForApproval,
  unlockSupplier: SupplierActions.unlockSupplier,
  moveSupplier: SupplierActions.moveSupplier,
  declineSupplier: SupplierActions.declineSupplier,
  holdSupplier: SupplierActions.holdSupplier,
  exportSuppliers: SupplierActions.exportSuppliers,
  clearSupplierStates: SupplierActions.clearSupplierStates,
  approveSupplier: SupplierActions.approveSupplier,
  getSupplierActivity: SupplierActions.getSupplierActivity,
  getSupplierSummary: SupplierActions.getSupplierSummary,
  getAttachment: SupplierActions.getAttachment,
  addSupAttachments: SupplierActions.addSupAttachments,
  deleteSupAttachments: SupplierActions.deleteSupAttachments,
})(Suppliers);
