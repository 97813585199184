import Axios from "axios";
import axiosDPFAPIClient from "../../Utils/axiosDPFAPIClient";
import { setProductJWTTokens } from "../../Utils/authHelpers";
import { getMessageDescription, getValidString, isInt, isSuccessRequest, isValidString } from "../../Utils/Helpers";
import axiosAPIGatewayClient from "../../Utils/axiosAPIGatewayClient";
import { data } from "jquery";

Axios.defaults.withCredentials = true;

// ---------------------****User Actions****-----------------------------

//----****Check Company ::> to check which API url will used in all APP****---
export const checkCompany = async (apiUrl, companyID) => {
  let url = apiUrl + "/DPFAPI/UserRequest";
  let data = {
    actionType: "CheckCompany",
    companyID,
  };
  try {
    const response = await Axios.post(url, data);

    let checkCompanyResp =
      (response && response.data && response.data.UserResponse) || "";
    if (checkCompanyResp && checkCompanyResp.results.length > 0) {
      if (
        checkCompanyResp.results[0] &&
        checkCompanyResp.results[0].status === "Failed"
      ) {
        return false;
      }
      if (
        checkCompanyResp.results[0] &&
        checkCompanyResp.results[0].status === "Success"
      ) {
        return true;
      }
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

//----****Check cognito status for the given company ID****---
export const checkCognitoStatusInTenant = async (tenantId) => {
  let url = `auth/cognito-status?tenantId=${tenantId}`;

  try {
    const response = await axiosAPIGatewayClient.get(url);
    return response.data;
  } catch (ex) {
    return false;
  }
};

export const checkCognitoStatusInCompanyId = async (companyID) => {
  let url = `auth/cognito-status?companyID=${companyID}`;

  try {
    const response = await axiosAPIGatewayClient.get(url);
    return response.data;
  } catch (ex) {
    return false;
  }
};

//----**** Get SSO identity provider of the given company Id****---
export const getIdentityProviderOfTenant = (tenantId) => async (dispatch) => {
  dispatch({
    type: "GET_IDENTITY_PROVIDER_INIT",
  });

  let url = `auth/getidentityprovider?tenantId=${encodeURIComponent(tenantId)}`;

  try {
    return await axiosAPIGatewayClient.get(url);
  } catch (ex) {
    dispatch({
      type: "GET_IDENTITY_PROVIDER_FAIL",
      payload: ex,
    });
  }
};

//----**** Get SSO identity provider of the given company Id****---
export const getAccessTokenFromCode = (code) => async (dispatch) => {
  dispatch({
    type: "GET_ACCESS_TOKEN_FROM_CODE_INIT",
  });
  const redirectUri = localStorage.getItem("COGNITO_CLIENT_APP_REDIRECT_URI");

  let url = `auth/getaccesstokenfromcode?code=${code}&redirect_uri=${redirectUri}`;

  try {
    const apiResponse = await axiosAPIGatewayClient.get(url);
    return apiResponse;
  } catch (ex) {
    console.log('We got an error while gettingAPITokenFromCode: ', ex);
    dispatch({
      type: "GET_IDENTITY_PROVIDER_FAIL",
      payload: ex,
    });
  }
};

//----****LogIn User****-----------
export const logInUser = (userData) => async (dispatch) => {
  dispatch({
    type: "LOG_IN_USER_INIT",
  });
  // const url = localStorage.getItem("API_URL") + "/DPFAPI/UserRequest";
  const url = "/DPFAPI/UserRequest";

  try {
    let response = await axiosDPFAPIClient.post(url, userData);

    let userLoginResp =
      (response && response.data && response.data.UserResponse) || "";
    if (userLoginResp && userLoginResp.results.length > 0) {
      if (
        userLoginResp.results[0] &&
        userLoginResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "LOG_IN_USER_FAIL",
          payload:
            userLoginResp.results[0].description || "Error While Logging",
        });
      }
      if (
        userLoginResp.results[0] &&
        userLoginResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "LOG_IN_USER_SUCCESS",
          payload: userLoginResp,
        });
      }
    } else {
      dispatch({
        type: "LOG_IN_USER_FAIL",
        payload: "Error While Logging",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Logging";
    dispatch({
      type: "LOG_IN_USER_FAIL",
      payload: error,
    });
  }
};

//----****LogIn SSO User****-----------
export const logInSSOUser = (companyID) => async (dispatch) => {
  dispatch({
    type: "LOG_IN_USER_INIT",
  });
  const url = "/auth/sso-session";

  try {
    const isBetaEnv = `${window.config?.betaEnv}`;

    let response = await axiosAPIGatewayClient.post(
      url,
      { companyID, isBetaEnv },
      { withCredentials: true }
    );

    dispatch({
      type: "LOG_IN_USER_SUCCESS",
      payload: response.data,
    });
  } catch (err) {
    const error = err.message || "Error While Logging";
    dispatch({
      type: "LOG_IN_USER_FAIL",
      payload: error,
    });
  }
};

export const getUserCompanies = async () => {
  try {
    const SSOAuthTenantId = localStorage.getItem("SSOAuthTenantId");
    const url = `/auth/user/companies?SSOAuthTenantId=${SSOAuthTenantId}`;

    const response = await axiosAPIGatewayClient.get(url);
    return response.data.companies;
  } catch (error) {
    return null;
  }
};

export const requestResetPincode = async (data, dispatch) => {

  dispatch({ type: "REQUEST_PIN_CODE_INIT" });

  const url = "/DPFAPI/UserRequest";
  const fallbackResponse = {
    status: "Failed",
    description: "Error While Requesting Pin Code",
    maskedEmail: "",
  }

  try {
    const response = await axiosDPFAPIClient.post(url, data);
    const reqPinCodeResp = response?.data?.UserResponse || {};

    if (reqPinCodeResp?.results?.length > 0) {
      const result = reqPinCodeResp.results[0];

      if (result?.status === "Failed") {
        dispatch({
          type: "REQUEST_PIN_CODE_FAIL",
          payload: result?.description || "Error While Requesting Pin Code",
        });
        return fallbackResponse;
      } else if (result?.status === "Success") {
        dispatch({ type: "REQUEST_PIN_CODE_SUCCESS", payload: reqPinCodeResp });
        fallbackResponse.status = "Success";
        fallbackResponse.description = result?.description || "Pin Code Requested Successfully";
        fallbackResponse.maskedEmail = reqPinCodeResp?.userContact || "";
        return fallbackResponse;
      }
    } else {
      dispatch({
        type: "REQUEST_PIN_CODE_FAIL",
        payload: "Error While Requesting Pin Code",
      });
      fallbackResponse.description = "No Response From Server";
      return fallbackResponse;
    }
  } catch (err) {
    const error = err.message || "Error While Requesting Pin Code";
    dispatch({ type: "REQUEST_PIN_CODE_FAIL", payload: error });
    fallbackResponse.description = error;
    return fallbackResponse;
  }
};

/**
 * Sends 2FA PIN to the user's email for non cognito user
 * tags: sendOTP, send2FAPIN, pinCode, RequestPinCode
 * @param {NonCognito2FAData} data The data to send PIN
 */
export const request2FANonCognito = async (data) => {
  const url = "/DPFAPI/UserRequest";
  const fallbackResponse = {
    UserResponse: {
      results: [
        {
            status: "Failed",
            description: "Unable to send pincode"
        }
      ],
      userContact: "",
    }
  };
  try {
    const response = await axiosAPIGatewayClient.post(url, data);
    const { UserResponse } = response?.data || fallbackResponse;
    fallbackResponse.UserResponse = UserResponse;
    return fallbackResponse;
  } catch (error) {
    const errorMessage = error?.message || "Unable to send pincode";
    fallbackResponse.UserResponse.results[0].description = errorMessage;
    return fallbackResponse;
  }
}

/**
 * Gets the basis options and caches them in session storage if not present or invalid
 * @returns {Promise<Basis[]>}
 */
export const getBasis = async () => {
  const cachedBasis = sessionStorage.getItem("basisOptions");
  const parsedBasis = JSON.parse(cachedBasis);
  if (Array.isArray(parsedBasis)) {
    const isValidBasis = parsedBasis.every(
      (a) => isValidString(a.prompt)
      && isValidString(a.type)
      && isInt(a.rate)
    );
    if (isValidBasis) {
      return parsedBasis;
    }
  }
  const url = `/DPFAPI/SetupRequest?actionType=GetBasisOptions`;
  const fallbackObject = {
    result: [
      {
        status: "Failed",
        description: "Unable to get the Basis. Try again"
      }
    ],
    basisOptions: []
  }
  if (axiosDPFAPIClient?.defaults?.baseURL === null) {
    return [];
  }
  try {
    let response = await axiosDPFAPIClient.get(url);
    const SetupResponse = response?.data?.SetupResponse || fallbackObject;
    const { status, description } = SetupResponse?.result[0];
    if (status === "Success") {
      sessionStorage.setItem("basisOptions", JSON.stringify(SetupResponse?.basisOptions));
      return SetupResponse?.basisOptions;
    }
    console.warn('Failed to get the approval group!', description);
    return [];
  } catch (error) {
    console.error('Error while getting Basis', error);
    return [];
  }
}

//----****Request pin code****-----
export const requestPinCode = (data) => async (dispatch) => {
  dispatch({
    type: "REQUEST_PIN_CODE_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let reqPinCodeResp =
      (response && response.data && response.data.UserResponse) || "";
    if (reqPinCodeResp && reqPinCodeResp.results.length > 0) {
      if (
        reqPinCodeResp.results[0] &&
        reqPinCodeResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "REQUEST_PIN_CODE_FAIL",
          payload:
            reqPinCodeResp.results[0].description ||
            "Error While Requesting Pin Code",
        });
      }
      if (
        reqPinCodeResp.results[0] &&
        reqPinCodeResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "REQUEST_PIN_CODE_SUCCESS",
          payload: reqPinCodeResp,
        });
      }
    } else {
      dispatch({
        type: "REQUEST_PIN_CODE_FAIL",
        payload: "Error While Requesting Pin Code",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Requesting Pin Code";
    dispatch({
      type: "REQUEST_PIN_CODE_FAIL",
      payload: error,
    });
  }
};

/**
 * Verifies if the PIN code entered is valid or not for non cognito user
 * This will also logs in the user if it is valid.
 * @param {VerifyPinCodeData} data
 */
export const verifyPinCodeWithoutDispatch = async (data) => {
  const url = "/DPFAPI/UserRequest";
  const fallbackResponse = {
    UserResponse: {
      results: [
        {
          status: "Failed",
          description: "Error While Verifying Pin Code"
        }
      ]
    }
  };
  try {
    const response = await axiosDPFAPIClient.post(url, data, {
      withCredentials: true,
    });
    const { UserResponse } = response?.data || fallbackResponse;
    fallbackResponse.UserResponse = UserResponse;
    return fallbackResponse;
  } catch (error) {
    console.log('Error while verifying pincode', error);
    const errorMessage = error?.message || "Unable to Verify pincode";
    fallbackResponse.UserResponse.results[0].description = errorMessage;
    return fallbackResponse;
  }
}

//----****Verify pin code****------
export const verifyPinCode = (data) => async (dispatch) => {
  dispatch({
    type: "VERIFY_PIN_CODE_INIT",
  });

  const url = "/DPFAPI/UserRequest";

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let verifyPinCodeResp =
      (response && response.data && response.data.UserResponse) || "";
    if (verifyPinCodeResp && verifyPinCodeResp.results.length > 0) {
      if (
        verifyPinCodeResp.results[0] &&
        verifyPinCodeResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "VERIFY_PIN_CODE_FAIL",
          payload:
            verifyPinCodeResp.results[0].description ||
            "Error While Verifying Pin Code",
        });
      }
      if (
        verifyPinCodeResp.results[0] &&
        verifyPinCodeResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "VERIFY_PIN_CODE_SUCCESS",
          payload:
            verifyPinCodeResp.results[0].description ||
            "Successfully Verify Pin Code",
        });
      }
    } else {
      dispatch({
        type: "VERIFY_PIN_CODE_FAIL",
        payload: "Error While Verifying Pin Code",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Verifying Pin Code";
    dispatch({
      type: "VERIFY_PIN_CODE_FAIL",
      payload: error,
    });
  }
};

export const resetPasswordNonCognitoFlow = async (data) => {

  try {
    const response = await axiosDPFAPIClient.post("/DPFAPI/UserRequest", data);
    const result = response?.data?.UserResponse?.results?.[0] || {};
    const { status = "Failed", description = "Error While Reseting Password." } = result;

    if (status === "Failed") {
      return {
        status,
        description,
      };
    } else {
      return {
        status,
        description,
      };
    }
  } catch (err) {
    return {
      status: "Failed",
      description: err?.message || "Error While Reseting Password.",
    }
  }
};

//----****Reset Password****-------
export const resetPassword = (data) => async (dispatch) => {
  dispatch({
    type: "RESET_PASSWORD_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let resetPassResp =
      (response && response.data && response.data.UserResponse) || "";
    if (resetPassResp && resetPassResp.results.length > 0) {
      if (
        resetPassResp.results[0] &&
        resetPassResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "RESET_PASSWORD_FAIL",
          payload:
            resetPassResp.results[0].description ||
            "Error While Reseting Password.",
        });
      }
      if (
        resetPassResp.results[0] &&
        resetPassResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "RESET_PASSWORD_SUCCESS",
          payload:
            resetPassResp.results[0].description ||
            "Successfully Reset Your Password.",
        });
      }
    } else {
      dispatch({
        type: "RESET_PASSWORD_FAIL",
        payload: "Error While Reseting Password.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Reseting Password.";
    dispatch({
      type: "RESET_PASSWORD_FAIL",
      payload: error,
    });
  }
};

export const logoutAndDisconnectWS = async (dispatch) => {
  if (dispatch) {
    dispatch({
      type: "LOG_OUT_USER_INIT",
    });
  }
  const url = "/DPFAPI/UserRequest";
  const data = {
    actionType: "LogoutUser",
  };
  const fallbackResponse = {
    UserResponse: {
      results: [
        {
          status: "Failed",
          description: "Error While Logout.",
        }
      ],
      data: undefined,
    },
  };
  try {
    const response = await axiosDPFAPIClient.post(url, data);
    const { UserResponse } = response?.data || fallbackResponse;
    if (isSuccessRequest(UserResponse)) {
      localStorage.removeItem("companyID");
      sessionStorage.clear();
      const toastMessage = getMessageDescription(UserResponse, 'success');
      if (dispatch) {
        dispatch({
          type: "LOG_OUT_USER_SUCCESS",
          payload: toastMessage || "Successfully Logout.",
        });
      }
    } else {
      const failedMessage = getMessageDescription(UserResponse, "failed");
      const warningMessage = getMessageDescription(UserResponse, "warning");
      const errorMessage = failedMessage || warningMessage;
      if (dispatch) {
        dispatch({
          type: "LOG_OUT_USER_FAIL",
          payload: errorMessage || "Error While Logout.",
        });
      }
    }
    fallbackResponse.UserResponse = UserResponse;
    return fallbackResponse;
  } catch (error) {
    console.log('[logoutAndDisconnectWS]: Error while Logout', error);
    const errorMessage = error?.message || "Error While Logout user.";
    fallbackResponse.UserResponse.results[0].description = errorMessage;
    if (dispatch) {
      dispatch({
        type: "LOG_OUT_USER_FAIL",
        payload: error?.message || "Error While Logout.",
      });
    }
    return fallbackResponse;
  } finally {
    if (dispatch) {
      dispatch({
        type: "CLEAR_STATES_AFTER_LOGOUT",
      });
    }
  }
}

//----****LogOut User****----------
export const logOutUser = () => async (dispatch) => {
  dispatch({
    type: "LOG_OUT_USER_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let data = {
    actionType: "LogoutUser",
  };
  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let logoutResp =
      (response && response.data && response.data.UserResponse) || "";
    if (logoutResp && logoutResp.results.length > 0) {
      if (logoutResp.results[0] && logoutResp.results[0].status === "Failed") {
        dispatch({
          type: "LOG_OUT_USER_FAIL",
          payload: logoutResp.results[0].description || "Error While Logout.",
        });
      }
      localStorage.removeItem("companyID");
      sessionStorage.clear();
      if (logoutResp.results[0] && logoutResp.results[0].status === "Success") {
        dispatch({
          type: "LOG_OUT_USER_SUCCESS",
          payload: logoutResp.results[0].description || "Successfully Logout.",
        });
      }
    } else {
      dispatch({
        type: "LOG_OUT_USER_FAIL",
        payload: "Error While Logout.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Logout.";
    dispatch({
      type: "LOG_OUT_USER_FAIL",
      payload: error,
    });
  }
};

export const logoutUserWithoutDispatch = async () => {
  const url = "/DPFAPI/UserRequest";
  let data = {
    actionType: "LogoutUser",
  };
  const fallabackResponse = {
    UserResponse: {
      results: [
        {
          status: "Failed",
          description: "Unable to logout",
        }
      ],
    }
  };
  if (!axiosAPIGatewayClient.defaults.url) {
    console.log('[logoutUserWithoutDispatch]: No Base URL found, skipping logout network call');
    fallabackResponse.UserResponse.results.push({
      status: "Failed",
      description: "No Base URL found, skipping logout network call",
    })
    return fallabackResponse;
  }
  try {
    const response = await axiosDPFAPIClient.post(url, data);
    const { UserResponse = fallabackResponse.UserResponse } = response?.data || fallabackResponse;
    fallabackResponse.UserResponse = UserResponse;
    return fallabackResponse;
  } catch (error) {
    const errorMessage = error?.message || "Error While Logout user.";
    fallabackResponse.UserResponse.results[0].description = errorMessage;
    return fallabackResponse;
  }
}


//----****GetProductions****--------
export const getProductions = () => async (dispatch) => {
  dispatch({
    type: "GET_PRODUCTIONS_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=GetProductions";

  try {
    let response = await axiosDPFAPIClient.get(url);
    let getProductionsResp =
      (response && response.data && response.data.UserResponse) || "";
    if (getProductionsResp && getProductionsResp.results.length > 0) {
      if (
        getProductionsResp.results[0] &&
        getProductionsResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_PRODUCTIONS_FAIL",
          payload:
            getProductionsResp.results[0].description ||
            "Error While Getting Productions.",
        });
      }
      if (
        getProductionsResp.results[0] &&
        getProductionsResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_PRODUCTIONS_SUCCESS",
          payload: getProductionsResp,
        });
      }
    } else {
      dispatch({
        type: "GET_PRODUCTIONS_FAIL",
        payload: "Error While Getting Productions.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Productions.";
    dispatch({
      type: "GET_PRODUCTIONS_FAIL",
      payload: error,
    });
  }
};
//----****Get LogIn Production****------
export const logInProduction = (productionName, companyId) => async (dispatch) => {
  dispatch({
    type: "LOG_IN_PRODUCTION_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  const data = {
    actionType: "LoginProduction",
    productionName,
  };
  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let getLoginProductionResp =
      (response && response.data && response.data.UserResponse) || "";
    if (getLoginProductionResp && getLoginProductionResp.results.length > 0) {
      if (
        getLoginProductionResp.results[0] &&
        getLoginProductionResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "LOG_IN_PRODUCTION_FAIL",
          payload:
            getLoginProductionResp.results[0].description ||
            "Error While Login Production.",
        });
      }
      if (
        getLoginProductionResp.results[0] &&
        getLoginProductionResp.results[0].status === "Success"
      ) {
        getLoginProductionResp.companyId = companyId;
        dispatch({
          type: "LOG_IN_PRODUCTION_SUCCESS",
          payload: getLoginProductionResp,
        });
      }
    } else {
      dispatch({
        type: "LOG_IN_PRODUCTION_FAIL",
        payload: "Error While Login Production.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Login Production.";
    dispatch({
      type: "LOG_IN_PRODUCTION_FAIL",
      payload: error,
    });
  }
};
//----****Get LogIn Production through the API Gateway****------
export const getStorageType = (production) => async (dispatch) => {
  const url = `auth/tenant?showName=${production}`;
  try {
    const response = await axiosAPIGatewayClient.get(url);
    if (response?.data?.status === "Success") {
      dispatch({
        type: "GET_STORAGE_SUCCESS",
        payload: response?.data,
      });
    } else {
      dispatch({
        type: "GET_STORAGE_FAILED",
        payload: "Failed to get storage type",
      });
    }
  } catch (ex) {
    console.log("error: ", ex);
    dispatch({
      type: "GET_STORAGE_FAILED",
      payload: "Failed to get storage type",
    });
  }
};

export const loginInToProduction =
  async (production, role, companyId, dispatch) => {
    const savedCompanyId = localStorage.getItem("companyID");
    const url = "auth/productsignin";
    const loginProdData = {
      production,
      role,
      companyID: getValidString(companyId, savedCompanyId),
    };
    const APIresponse = {
      status: "Failed",
      description: "Failed to login to production",
      data: null,
    }

    try {
      const response = await axiosAPIGatewayClient.post(url, loginProdData);
      const data = response?.data || {};
      if (data?.access_token) {
        const { accessToken, refreshToken } = data;
        setProductJWTTokens(accessToken, refreshToken);
        dispatch({ type: "LOGIN_API_GATEWAY_PRODUCTION_SUCCESS" });
        dispatch(setJWTSessionToken(data.access_token));
        APIresponse.data = data;
        APIresponse.status = "Success";
        APIresponse.description = "Successfully logged in to production";
        return APIresponse;
      }
      APIresponse.status = data?.status || "Failed";
      APIresponse.description = data?.message || "Failed to login";
      return APIresponse;
    } catch (ex) {
      console.error("error: ", ex);
      dispatch({
        type: "LOGIN_API_GATEWAY_PRODUCTION_FAILED",
        payload: ex.message || "Failed to login",
      });
      APIresponse.data = ex;
      APIresponse.status = "Failed";
      APIresponse.description = ex?.message || "Failed to login";
      return APIresponse;
    }
  };

//----****Get LogIn Production through the API Gateway****------

export const loginAPIGatewayProduction =
  (production, role, companyId) => async (dispatch) => {
    const savedCompanyId = localStorage.getItem("companyID");
    const url = "auth/productsignin";
    const loginProdData = {
      production,
      role,
      companyID: getValidString(companyId, savedCompanyId),
    };

    try {
      const response = await axiosAPIGatewayClient.post(url, loginProdData);
      if (response && response.data && response.data.access_token) {
        const { accessToken, refreshToken } = response.data;
        setProductJWTTokens(accessToken, refreshToken);
        dispatch({ type: "LOGIN_API_GATEWAY_PRODUCTION_SUCCESS" });
        dispatch(setJWTSessionToken(response.data.access_token));
      }
    } catch (ex) {
      console.log("error: ", ex);
      dispatch({
        type: "LOGIN_API_GATEWAY_PRODUCTION_FAILED",
        payload: ex.message ?? "Failed to login",
      });
    }
  };

//----****Calling the SetJWTSessionToken end point soon after the login production****------
export const setJWTSessionToken = (token) => async (dispatch) => {
  dispatch({
    type: "LOG_IN_PRODUCTION_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  const data = {
    actionType: "SetJWTSessionToken",
    token,
  };
  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let getLoginProductionResp =
      (response && response.data && response.data.UserResponse) || "";
    if (getLoginProductionResp && getLoginProductionResp.results.length > 0) {
      if (
        getLoginProductionResp.results[0] &&
        getLoginProductionResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "SAVE_TOKEN_INIT",
          payload:
            getLoginProductionResp.results[0].description ||
            "Error While Login Production.",
        });
      }
      if (
        getLoginProductionResp.results[0] &&
        getLoginProductionResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "SAVE_TOKEN_INIT_SUCCESS",
          payload: getLoginProductionResp,
        });
      }
    } else {
      dispatch({
        type: "SAVE_TOKEN_INIT_FAIL",
        payload: "Error While Saving Access Token.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Saving Access Token.";
    dispatch({
      type: "SAVE_TOKEN_INIT_FAIL",
      payload: error,
    });
  }
};
//----****Get Accounts Details****--------
export const getAccountDetails = (a) => async (dispatch) => {
  dispatch({
    type: "GET_ACCOUNT_DETAILS_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=GetAccountDetails";
  try {
    let response = await axiosDPFAPIClient.get(url);

    let getAccountDetailsResp =
      (response && response.data && response.data.UserResponse) || "";
    if (getAccountDetailsResp && getAccountDetailsResp.results.length > 0) {
      if (
        getAccountDetailsResp.results[0] &&
        getAccountDetailsResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_ACCOUNT_DETAILS_FAIL",
          payload:
            getAccountDetailsResp.results[0].description ||
            "Error While Getting Account Details.",
        });
      }
      if (
        getAccountDetailsResp.results[0] &&
        getAccountDetailsResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_ACCOUNT_DETAILS_SUCCESS",
          payload: getAccountDetailsResp,
        });
      }
    } else {
      dispatch({
        type: "GET_ACCOUNT_DETAILS_FAIL",
        payload: "Error While Getting Account Details.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Account Details.";
    dispatch({
      type: "GET_ACCOUNT_DETAILS_FAIL",
      payload: error,
    });
  }
};
//----****Update Accounts Details****--------
export const updateAccountDetails = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_ACCOUNT_DETAILS_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let userData = {
    actionType: "UpdateAccountDetails",
    accountDetails: {
      ...data,
      signature:
        data.sigType === "Drawn"
          ? data.signature?.split(",")[1]
          : data.signature,
      avatar: data.avatar?.split(",")[1],
    },
  };

  try {
    let response = await axiosDPFAPIClient.post(url, userData);
    let updateAccountDetailsResp =
      (response && response.data && response.data.UserResponse) || "";
    if (
      updateAccountDetailsResp &&
      updateAccountDetailsResp.results.length > 0
    ) {
      if (
        updateAccountDetailsResp.results[0] &&
        updateAccountDetailsResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "UPDATE_ACCOUNT_DETAILS_FAIL",
          payload:
            updateAccountDetailsResp.results[0].description ||
            "Error While Updating Account Details.",
        });
      }
      if (
        updateAccountDetailsResp.results[0] &&
        updateAccountDetailsResp.results[0].status === "Success"
      ) {
        let obj = {
          updatedData: data,
          updateAccountDetailsResp,
        };
        dispatch({
          type: "UPDATE_ACCOUNT_DETAILS_SUCCESS",
          payload: obj,
        });
      }
    } else {
      dispatch({
        type: "UPDATE_ACCOUNT_DETAILS_FAIL",
        payload: "Error While Updating Account Details.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Account Details.";
    dispatch({
      type: "UPDATE_ACCOUNT_DETAILS_FAIL",
      payload: error,
    });
  }
};
//----****Get Default Values****--------
export const getDefaultValues = () => async (dispatch) => {
  dispatch({
    type: "GET_DEFAULT_VALUES_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=GetDefaultValues";

  try {
    let response = await axiosDPFAPIClient.get(url);
    let getDefaultValuesResp =
      (response && response.data && response.data.UserResponse) || "";
    if (getDefaultValuesResp && getDefaultValuesResp.results.length > 0) {
      if (
        getDefaultValuesResp.results[0] &&
        getDefaultValuesResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_DEFAULT_VALUES_SUCCESS",
          payload: getDefaultValuesResp,
        });
      } else {
        dispatch({
          type: "GET_DEFAULT_VALUES_FAIL",
          payload:
            getDefaultValuesResp.results[0].description ||
            "Error While Getting Defaults Values.",
        });
      }
    } else {
      dispatch({
        type: "GET_DEFAULT_VALUES_FAIL",
        payload: "Error While Getting Defaults Values.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Defaults Values.";
    dispatch({
      type: "GET_DEFAULT_VALUES_FAIL",
      payload: error,
    });
  }
};
//----****Get SetUserSettings****-----------
export const setUserSettings = (a) => async (dispatch) => {
  dispatch({
    type: "GET_SET_USER_SETTING_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=SetUserSettings";

  try {
    let response = await axiosDPFAPIClient.get(url);
    let setUserSettingsResp =
      (response && response.data && response.data.UserResponse) || "";
    if (setUserSettingsResp && setUserSettingsResp.results.length > 0) {
      if (
        setUserSettingsResp.results[0] &&
        setUserSettingsResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_SET_USER_SETTING_FAIL",
          payload:
            setUserSettingsResp.results[0].description ||
            "Error While Set user Settings.",
        });
      }
      if (
        setUserSettingsResp.results[0] &&
        setUserSettingsResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_SET_USER_SETTING_SUCCESS",
          payload: setUserSettingsResp,
        });
      }
    } else {
      dispatch({
        type: "GET_SET_USER_SETTING_FAIL",
        payload: "Error While Set user Settings.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Set user Settings.";
    dispatch({
      type: "GET_SET_USER_SETTING_FAIL",
      payload: error,
    });
  }
};
//----****Get Help Page****-----------
export const getHelpPage = (a) => async (dispatch) => {
  dispatch({
    type: "GET_HELP_PAGE_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=GetHelpPage";

  try {
    let response = await axiosDPFAPIClient.get(url);

    let geHelpPageResp =
      (response && response.data && response.data.UserResponse) || "";
    if (geHelpPageResp && geHelpPageResp.results.length > 0) {
      if (
        geHelpPageResp.results[0] &&
        geHelpPageResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_HELP_PAGE_FAIL",
          payload:
            geHelpPageResp.results[0].description ||
            "Error While Getting Help Page.",
        });
      }
      if (
        geHelpPageResp.results[0] &&
        geHelpPageResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_HELP_PAGE_SUCCESS",
          payload: geHelpPageResp,
        });
      }
    } else {
      dispatch({
        type: "GET_HELP_PAGE_FAIL",
        payload: "Error While Getting Help Page.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Help Page.";
    dispatch({
      type: "GET_HELP_PAGE_FAIL",
      payload: error,
    });
  }
};
//----****Get Transaction History****-----------
export const GetTransactionHistory = (a) => async (dispatch) => {
  dispatch({
    type: "GET_TRANSACTION_HISTORY_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=GetTransactionHistory";

  try {
    let response = await axiosDPFAPIClient.get(url);

    let geTransactionHistoryResp =
      (response && response.data && response.data.UserResponse) || "";
    if (
      geTransactionHistoryResp &&
      geTransactionHistoryResp.results.length > 0
    ) {
      if (
        geTransactionHistoryResp.results[0] &&
        geTransactionHistoryResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_TRANSACTION_HISTORY_FAIL",
          payload:
            geTransactionHistoryResp.results[0].description ||
            "Error While Getting Transaction History.",
        });
      }
      if (
        geTransactionHistoryResp.results[0] &&
        geTransactionHistoryResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_TRANSACTION_HISTORY_SUCCESS",
          payload: geTransactionHistoryResp,
        });
      }
    } else {
      dispatch({
        type: "GET_TRANSACTION_HISTORY_SUCCESS",
        payload: geTransactionHistoryResp,
        // type: "GET_TRANSACTION_HISTORY_FAIL",
        // payload: "Error While Getting Transaction History."
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Transaction History.";
    dispatch({
      type: "GET_TRANSACTION_HISTORY_FAIL",
      payload: error,
    });
  }
};
//----****Get Recent Activity****-----------
export const GetRecentActivity = (a) => async (dispatch) => {
  dispatch({
    type: "GET_RECENT_ACTIVITY_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=GetRecentActivity";

  try {
    let response = await axiosDPFAPIClient.get(url);

    let geRecentActivityResp =
      (response && response.data && response.data.UserResponse) || "";
    if (geRecentActivityResp && geRecentActivityResp.results.length > 0) {
      if (
        geRecentActivityResp.results[0] &&
        geRecentActivityResp.results[0].status === "Failed"
      ) {
        dispatch({
          type: "GET_RECENT_ACTIVITY_FAIL",
          payload:
            geRecentActivityResp.results[0].description ||
            "Error While Getting Recent Activity.",
        });
      }
      if (
        geRecentActivityResp.results[0] &&
        geRecentActivityResp.results[0].status === "Success"
      ) {
        dispatch({
          type: "GET_RECENT_ACTIVITY_SUCCESS",
          payload: geRecentActivityResp,
        });
      }
    } else {
      dispatch({
        type: "GET_RECENT_ACTIVITY_FAIL",
        payload: "Error While Getting Recent Activity.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Recent Activity.";
    dispatch({
      type: "GET_RECENT_ACTIVITY_FAIL",
      payload: error,
    });
  }
};
// ********************User Setups Actions********************
//----****Get Users List****-----------
export const getUsersList = () => async (dispatch) => {
  dispatch({
    type: "GET_USERS_LIST_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=GetUsersList";

  try {
    let response = await axiosDPFAPIClient.get(url);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "GET_USERS_LIST_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "GET_USERS_LIST_FAIL",
          payload:
            res.results[0].description || "Error While Getting Users List",
        });
      }
    } else {
      dispatch({
        type: "GET_USERS_LIST_FAIL",
        payload: "Error While Getting Users List",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Users List";
    dispatch({
      type: "GET_USERS_LIST_FAIL",
      payload: error,
    });
  }
};
//----****Get User Setup****-----------
export const getUserSetup = (userLogin) => async (dispatch) => {
  dispatch({
    type: "GET_USER_SETUP_INIT",
  });
  const url = `/DPFAPI/UserRequest?actionType=GetUserSetup&userLogin=${userLogin}`;

  try {
    let response = await axiosDPFAPIClient.get(url);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "GET_USER_SETUP_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "GET_USER_SETUP_FAIL",
          payload:
            res.results[0].description || "Error While Getting User Setup.",
        });
      }
    } else {
      dispatch({
        type: "GET_USER_SETUP_FAIL",
        payload: "Error While Getting User Setup.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting User Setup.";
    dispatch({
      type: "GET_USER_SETUP_FAIL",
      payload: error,
    });
  }
};

//----****Update User****-----------
export const updateUser = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_USER_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let userData = {
    actionType: "UpdateUser",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, userData);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "UPDATE_USER_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "UPDATE_USER_FAIL",
          payload: res.results[0].description || "Error While Updating User.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_USER_FAIL",
        payload: "Error While Updating User.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating User.";
    dispatch({
      type: "UPDATE_USER_FAIL",
      payload: error,
    });
  }
};

export const updateUserDetails = async (data) => {
  const url = "/DPFAPI/UserRequest";

  let userData = {
    actionType: "UpdateUser",
    ...data,
  };

  const fallbackResponse = {
    results: [
      {
        status: "Failed",
        description: "Error While Updating User.",
      }
    ],
    userList: [],
  };

  try {
    const response = await axiosDPFAPIClient.post(url, userData);
    let res = response?.data?.UserResponse || fallbackResponse;
    return res;
  } catch (err) {
    console.log('Error while updating user', err);
    const errorMessage = err?.message || "Error While Updating User.";
    fallbackResponse.results[0].description = errorMessage;
    return fallbackResponse;    
  }
};

//----****prime User****-----------
export const primeUser = () => async (dispatch) => {
  dispatch({
    type: "PRIME_USER_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let userData = {
    actionType: "PrimeUser",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, userData);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "PRIME_USER_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "PRIME_USER_FAIL",
          payload: res.results[0].description || "Error While Priming User.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_USER_FAIL",
        payload: "Error While Priming User.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Priming User.";
    dispatch({
      type: "PRIME_USER_FAIL",
      payload: error,
    });
  }
};
//----****Insert User****-----------
export const insertUser = (data) => async (dispatch) => {
  dispatch({
    type: "INSERT_USER_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let userData = {
    actionType: "InsertUser",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, userData);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "INSERT_USER_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "INSERT_USER_FAIL",
          payload: res.results[0].description || "Error While Inserting User.",
        });
      }
    } else {
      dispatch({
        type: "INSERT_USER_FAIL",
        payload: "Error While Inserting User.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Inserting User.";
    dispatch({
      type: "INSERT_USER_FAIL",
      payload: error,
    });
  }
};
//----****delete User****-----------
export const deleteUser =
  (userLogin, userStatus = "") =>
  async (dispatch) => {
    dispatch({
      type: "DELETE_USER_INIT",
    });
    const url = "/DPFAPI/UserRequest";

    let userData = {
      actionType: "DeleteUser",
      userLogin,
    };

    try {
      let response = await axiosDPFAPIClient.post(url, userData);

      let res = (response && response.data && response.data.UserResponse) || "";
      if (res && res.results.length > 0) {
        if (res.results[0] && res.results[0].status === "Success") {
          // cognito delete user
          // only delete accepted users as non-accepted users are not available in cognito userpool
          if (
            localStorage.getItem("isCognito") === "true" &&
            userStatus.toLowerCase() === "accepted"
          ) {
            await axiosAPIGatewayClient.delete("auth/user", {
              data: { username: userLogin },
            });
          }

          dispatch({
            type: "DELETE_USER_SUCCESS",
            payload: res,
          });
        } else {
          dispatch({
            type: "DELETE_USER_FAIL",
            payload: res.results[0].description || "Error While Deleting User.",
          });
        }
      } else {
        dispatch({
          type: "DELETE_USER_FAIL",
          payload: "Error While Deleting User.",
        });
      }
    } catch (err) {
      const error = err.message || "Error While Deleting User.";
      dispatch({
        type: "DELETE_USER_FAIL",
        payload: error,
      });
    }
  };

//----****Get Advanced List ****-----------
export const GetUserWidgetSettings = () => async (dispatch) => {
  dispatch({
    type: "GET_USER_WIDGET_SETTINGS_INIT",
  });
  const url = `/DPFAPI/WidgetRequest?actionType=GetUserWidgetSettings `;

  try {
    let response = await axiosDPFAPIClient.get(url);
    let res =
      (response && response.data && response.data.WidgetRequest_response) || "";
    if (res && res.result.length > 0) {
      if (res.result[0] && res.result[0].status === "Success") {
        dispatch({
          type: "GET_USER_WIDGET_SETTINGS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "GET_USER_WIDGET_SETTINGS_FAILED",
          payload:
            res.results[0].description ||
            "Error While Getting get user widget settings",
        });
      }
    }
  } catch (err) {
    const error = err.message || "Error While Getting get user widget settings";
    dispatch({
      type: "GET_USER_WIDGET_SETTINGS_FAILED",
      payload: error,
    });
  }
};

//----****UpdateWidgetSettings ****-----------
export const UpdateWidgetSettings = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_USER_WIDGET_SETTINGS_INIT",
  });
  const url = "/DPFAPI/WidgetRequest";

  let _data = {
    actionType: "UpdateWidgetSettings",
    POEdit: [data],
  };
  console.log(_data);
  try {
    let response = await axiosDPFAPIClient.post(url, _data);
    let res =
      (response && response.data && response.data.WidgetRequest_response) || "";
    if (res && res.result.length > 0) {
      if (res.result[0] && res.result[0].status === "Success") {
        dispatch({
          type: "UPDATE_USER_WIDGET_SETTINGS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "UPDATE_USER_WIDGET_SETTINGS_FAILED",
          payload:
            res.results[0].description ||
            "Error While Getting get user widget settings",
        });
      }
    }
  } catch (err) {
    const error = err.message || "Error While Getting get user widget settings";
    dispatch({
      type: "UPDATE_USER_WIDGET_SETTINGS_FAILED",
      payload: error,
    });
  }
};
//----****Get Advanced List ****-----------
export const getAdvancedList = (userType, userLogin) => async (dispatch) => {
  dispatch({
    type: "GET_ADVANCED_LIST_INIT",
  });
  const url = `/DPFAPI/UserRequest?actionType=GetAdvancedList&userType=${userType}&userLogin=${userLogin} `;

  try {
    let response = await axiosDPFAPIClient.get(url);
    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "GET_ADVANCED_LIST_SUCCESS",
          payload: res,
        });
        dispatch({
          type: "GET_BASIC_LIST_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "GET_ADVANCED_LIST_FAIL",
          payload:
            res.results[0].description || "Error While Getting Advanced List",
        });
        dispatch({
          type: "GET_BASIC_LIST_FAIL",
          payload:
            res.results[0].description || "Error While Getting Basic List",
        });
      }
    } else {
      dispatch({
        type: "GET_ADVANCED_LIST_FAIL",
        payload: "Error While Getting Advanced List",
      });
      dispatch({
        type: "GET_BASIC_LIST_FAIL",
        payload: "Error While Getting Advanced List",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Advanced List";
    dispatch({
      type: "GET_ADVANCED_LIST_FAIL",
      payload: error,
    });
    dispatch({
      type: "GET_BASIC_LIST_FAIL",
      payload: error,
    });
  }
};

const filterDisabledUsers = (response) => {
  const clonedResponse = { ...response };
  const clonedUserList = clonedResponse?.userList || [];
  const filteredUserList = clonedUserList.filter((user) => user.status !== 'Disabled');
  clonedResponse.userList = filteredUserList;
  return clonedResponse;
}

// **************************END******************************
// **************************Approval Setup APIs********************
//----****Get Approvers List****-----------
export const getApprovers = () => async (dispatch) => {
  dispatch({
    type: "GET_APPROVERS_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=GetApprovers";

  try {
    let response = await axiosDPFAPIClient.get(url);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        const userResponse = filterDisabledUsers(res);
        dispatch({
          type: "GET_APPROVERS_SUCCESS",
          payload: userResponse,
        });
      } else {
        dispatch({
          type: "GET_APPROVERS_FAIL",
          payload:
            res.results[0].description || "Error While Getting Approvers.",
        });
      }
    } else {
      dispatch({
        type: "GET_APPROVERS_FAIL",
        payload: "Error While Getting Approvers.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Approvers.";
    dispatch({
      type: "GET_APPROVERS_FAIL",
      payload: error,
    });
  }
};
//----****Get Approval Groups****-----------
export const getApprovalGroups = () => async (dispatch) => {
  dispatch({
    type: "GET_APPROVAL_GROUPS_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=GetApprovalGroups";

  try {
    let response = await axiosDPFAPIClient.get(url);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "GET_APPROVAL_GROUPS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "GET_APPROVAL_GROUPS_FAIL",
          payload:
            res.results[0].description ||
            "Error While Getting Approval Groups.",
        });
      }
    } else {
      dispatch({
        type: "GET_APPROVAL_GROUPS_FAIL",
        payload: "Error While Getting Approval Groups.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting Approval Groups.";
    dispatch({
      type: "GET_APPROVAL_GROUPS_FAIL",
      payload: error,
    });
  }
};
//----****Get Approval Group****-----------
export const getApprovalGroup = (recordID) => async (dispatch) => {
  dispatch({
    type: "GET_APPROVAL_GROUP_INIT",
  });
  const url = `/DPFAPI/UserRequest?actionType=GetApprovalGroup&recordID=${recordID}`;

  try {
    let response = await axiosDPFAPIClient.get(url);

    let res = (response && response.data && response.data.UserResponse) || {};
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "GET_APPROVAL_GROUP_SUCCESS",
          payload: res,
        });
        return res;
      } else {
        dispatch({
          type: "GET_APPROVAL_GROUP_FAIL",
          payload:
            res.results[0].description || "Error While Getting Approval Group.",
        });
      }
    } else {
      dispatch({
        type: "GET_APPROVAL_GROUP_FAIL",
        payload: "Error While Getting Approval Group.",
      });
    }
    return null;
  } catch (err) {
    const error = err.message || "Error While Getting Approval Group.";
    dispatch({
      type: "GET_APPROVAL_GROUP_FAIL",
      payload: error,
    });
    return null;
  }
};
//----****Insert Approval Group****-----------
export const insertApprovalGroup = (data) => async (dispatch) => {
  dispatch({
    type: "INSERT_APPROVAL_GROUP_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let _data = {
    actionType: "InsertApprovalGroup",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, _data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "INSERT_APPROVAL_GROUP_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "INSERT_APPROVAL_GROUP_FAIL",
          payload:
            res.results[0].description ||
            "Error While Inserting Approval Group.",
        });
      }
    } else {
      dispatch({
        type: "INSERT_APPROVAL_GROUP_FAIL",
        payload: "Error While Inserting Approval Group.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Inserting Approval Group.";
    dispatch({
      type: "INSERT_APPROVAL_GROUP_FAIL",
      payload: error,
    });
  }
};
//----****Update Approval Group****-----------
export const updateApprovalGroup = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_USER_APPROVAL_GROUP_INIT",
  });

  const url = "/DPFAPI/UserRequest";

  let _data = {
    actionType: "UpdateApprovalGroup",
    ...data,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, _data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "UPDATE_USER_APPROVAL_GROUP_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "UPDATE_USER_APPROVAL_GROUP_FAIL",
          payload:
            res.results[0].description ||
            "Error While Updating Approval Group.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_USER_APPROVAL_GROUP_FAIL",
        payload: "Error While Updating Approval Group.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating Approval Group.";
    dispatch({
      type: "UPDATE_USER_APPROVAL_GROUP_FAIL",
      payload: error,
    });
  }
};
//----****Prime Approver****-----------
export const primeApprover = (approverType) => async (dispatch) => {
  dispatch({
    type: "PRIME_APPROVER_INIT",
  });

  const url = "/DPFAPI/UserRequest";

  let _data = {
    actionType: "PrimeApprover",
    approverType,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, _data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "PRIME_APPROVER_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "PRIME_APPROVER_FAIL",
          payload:
            res.results[0].description || "Error While Priming Approver.",
        });
      }
    } else {
      dispatch({
        type: "PRIME_APPROVER_FAIL",
        payload: "Error While Priming Approver.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Priming Approver.";
    dispatch({
      type: "PRIME_APPROVER_FAIL",
      payload: error,
    });
  }
};
//----****Delete Approval Group****-----------
export const deleteApprovalGroup = (recordID) => async (dispatch) => {
  dispatch({
    type: "PRIME_APPROVER_INIT",
  });

  const url = "/DPFAPI/UserRequest";

  let _data = {
    actionType: "DeleteApprovalGroup",
    recordID,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, _data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "DELETE_APPROVAL_GROUP_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "DELETE_APPROVAL_GROUP_FAIL",
          payload:
            res.results[0].description ||
            "Error While Deleting Approval Group.",
        });
      }
    } else {
      dispatch({
        type: "DELETE_APPROVAL_GROUP_FAIL",
        payload: "Error While Deleting Approval Group.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Deleting Approval Group.";
    dispatch({
      type: "DELETE_APPROVAL_GROUP_FAIL",
      payload: error,
    });
  }
};
// **************************END******************************

// *******************User Defaults API's********************
//----****Get User Defaults****-----------
export const getUserDefaults = () => async (dispatch) => {
  dispatch({
    type: "GET_USER_DEFAULTS_INIT",
  });
  const url = `/DPFAPI/UserRequest?actionType=GetUserDefaults`;

  try {
    let response = await axiosDPFAPIClient.get(url);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "GET_USER_DEFAULTS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "GET_USER_DEFAULTS_FAIL",
          payload:
            res.results[0].description || "Error While Getting User Defaults.",
        });
      }
    } else {
      dispatch({
        type: "GET_USER_DEFAULTS_FAIL",
        payload: "Error While Getting User Defaults.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting User Defaults.";
    dispatch({
      type: "GET_USER_DEFAULTS_FAIL",
      payload: error,
    });
  }
};
//----****Update User Defaults****-----------
export const updateUserDefaults = (userDefaults) => async (dispatch) => {
  dispatch({
    type: "UPDATE_USER_DEFAULTS_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let data = {
    actionType: "UpdateUserDefaults",
    userDefaults,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "UPDATE_USER_DEFAULTS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "UPDATE_USER_DEFAULTS_FAIL",
          payload:
            res.results[0].description || "Error While Updating User Defaults.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_USER_DEFAULTS_FAIL",
        payload: "Error While Updating User Defaults.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating User Defaults.";
    dispatch({
      type: "UPDATE_USER_DEFAULTS_FAIL",
      payload: error,
    });
  }
};
// **************************END******************************

// *******************System Defaults API's********************
//----****Get System Defaults****-----------
export const getSystemDefaults = () => async (dispatch) => {
  dispatch({
    type: "GET_SYSTEM_DEFAULTS_INIT",
  });
  const url = `/DPFAPI/UserRequest?actionType=GetSystemDefaults`;

  try {
    let response = await axiosDPFAPIClient.get(url);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "GET_SYSTEM_DEFAULTS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "GET_SYSTEM_DEFAULTS_FAIL",
          payload:
            res.results[0].description ||
            "Error While Getting System Defaults.",
        });
      }
    } else {
      dispatch({
        type: "GET_SYSTEM_DEFAULTS_FAIL",
        payload: "Error While Getting System Defaults.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting System Defaults.";
    dispatch({
      type: "GET_SYSTEM_DEFAULTS_FAIL",
      payload: error,
    });
  }
};
//----****Update System Defaults****-----------
export const updateSystemDefaults = (systemDefaults) => async (dispatch) => {
  dispatch({
    type: "UPDATE_SYSTEM_DEFAULTS_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let data = {
    actionType: "UpdateSystemDefaults",
    systemDefaults,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "UPDATE_SYSTEM_DEFAULTS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "UPDATE_SYSTEM_DEFAULTS_FAIL",
          payload:
            res.results[0].description ||
            "Error While Updating System Defaults.",
        });
      }
    } else {
      dispatch({
        type: "UPDATE_SYSTEM_DEFAULTS_FAIL",
        payload: "Error While Updating System Defaults.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Updating System Defaults.";
    dispatch({
      type: "UPDATE_SYSTEM_DEFAULTS_FAIL",
      payload: error,
    });
  }
};
//----****Send Invite****-----------
export const sendInvite = (userLogin) => async (dispatch) => {
  dispatch({
    type: "SEND_INVITE_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let data = {
    actionType: "SendInvite",
    userLogin,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "SEND_INVITE_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "SEND_INVITE_FAIL",
          payload: res.results[0].description || "Error While Sending Invite.",
        });
      }
    } else {
      dispatch({
        type: "SEND_INVITE_FAIL",
        payload: "Error While Sending Invite.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Sending Invite.";
    dispatch({
      type: "SEND_INVITE_FAIL",
      payload: error,
    });
  }
};

//----****Export users****-----------
export const exportUsers = (payload) => async (dispatch) => {
  dispatch({
    type: "EXPORT_USERS_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let data = {
    actionType: "ExportUsers",
    ...payload,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "EXPORT_USERS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "EXPORT_USERS_FAIL",
          payload: res.results[0].description || "Error exporting users",
        });
      }
    } else {
      dispatch({
        type: "EXPORT_USERS_FAIL",
        payload: "Error while exporting users",
      });
    }
  } catch (err) {
    const error = err.message || "Error exporting users";
    dispatch({
      type: "EXPORT_USERS_FAIL",
      payload: error,
    });
  }
};

//----****Export users****-----------
export const pasteUsers = (payload) => async (dispatch) => {
  dispatch({
    type: "PASTE_USERS_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let data = {
    actionType: "PasteUsers",
    ...payload,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "PASTE_USERS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "PASTE_USERS_FAIL",
          payload: res.results[0].description || "Error while pasting users",
        });
      }
    } else {
      dispatch({
        type: "PASTE_USERS_FAIL",
        payload: "Error while pasting users.",
      });
    }
  } catch (err) {
    const error = err.message || "Error while pasting users";
    dispatch({
      type: "PASTE_USERS_FAIL",
      payload: error,
    });
  }
};

/**
 * @returns {Promise<PasteUserResponse>}
 */
export const pasteUsersDetails = async (payload) => {
  const url = "/DPFAPI/UserRequest";

  let data = {
    actionType: "PasteUsers",
    ...payload,
  };

  const fallbackResponse = {
    results: [
      {
        status: "Failed",
        description: "Error while pasting users.",
      }
    ],
    data: []
  }
  try {
    let response = await axiosDPFAPIClient.post(url, data);
    let pasteUserResponse = response?.data?.UserResponse || fallbackResponse;
    return pasteUserResponse;
  } catch (err) {
    console.log('Error while pasting the user, sending to server', err);
    const error = err?.message || "Error while pasting users";
    fallbackResponse.results[0].description = error;
    return fallbackResponse;
  }
}

//----**** Prime transfer transactions ****-----------
export const primeTransferTransactions = () => async (dispatch) => {
  dispatch({
    type: "PRIME_TRANSFER_TRANSACTIONS_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let data = {
    actionType: "PrimeTransferTransactions",
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "PRIME_TRANSFER_TRANSACTIONS_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "PRIME_TRANSFER_TRANSACTIONS_FAIL",
          payload:
            res.results[0].description ||
            "Error while Priming Transfer Transactions",
        });
      }
    } else {
      dispatch({
        type: "PRIME_TRANSFER_TRANSACTIONS_FAIL",
        payload: "Error while Priming Transfer Transactions.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Priming Transfer Transactions";
    dispatch({
      type: "PRIME_TRANSFER_TRANSACTIONS_FAIL",
      payload: error,
    });
  }
};

//----**** Prime transfer transactions ****-----------
export const transferTransactions = (payload) => async (dispatch) => {
  dispatch({
    type: "TRANSFER_TRANSACTIONS_INIT",
  });
  const url = "/DPFAPI/UserRequest";

  let data = {
    actionType: "TransferTransactions",
    ...payload,
  };

  try {
    let response = await axiosDPFAPIClient.post(url, data);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      dispatch({
        type: "TRANSFER_TRANSACTIONS_SUCCESS",
        payload: res,
      });
    } else {
      dispatch({
        type: "TRANSFER_TRANSACTIONS_FAIL",
        payload: "Error while Transferring Transactions.",
      });
    }
  } catch (err) {
    const error = err.message || "Error while Transferring Transactions";
    dispatch({
      type: "TRANSFER_TRANSACTIONS_FAIL",
      payload: error,
    });
  }
};

export const GetUserTallies = () => async (dispatch) => {
  dispatch({
    type: "GET_USER_TALLIES_INIT",
  });
  const url = "/DPFAPI/UserRequest?actionType=GetUserTallies";

  try {
    let response = await axiosDPFAPIClient.get(url);

    let res = (response && response.data && response.data.UserResponse) || "";
    if (res && res.results.length > 0) {
      if (res.results[0] && res.results[0].status === "Success") {
        dispatch({
          type: "GET_USER_TALLIES_SUCCESS",
          payload: res,
        });
      } else {
        dispatch({
          type: "GET_USER_TALLIES_FAIL",
          payload:
            res.results[0].description || "Error While Getting User Tallies.",
        });
      }
    } else {
      dispatch({
        type: "GET_USER_TALLIES_FAIL",
        payload: "Error While Getting User Tallies.",
      });
    }
  } catch (err) {
    const error = err.message || "Error While Getting System Defaults.";
    dispatch({
      type: "GET_USER_TALLIES_FAIL",
      payload: error,
    });
  }
};

// **************************END******************************

//----****Clear User States In Store****-----------
export function clearUserStates() {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_USER_STATES",
    });
  };
}
//----****Clear States After Logout****-----------
export function clearStatesAfterLogout() {
  return async (dispatch) => {
    dispatch({
      type: "CLEAR_STATES_AFTER_LOGOUT",
    });
  };
}

// ********************User Setups Actions********************

//***************themeColor */
export const updateThemeSetting = (setting) => async (dispatch) => {
  dispatch({
    type: "UPDATE_SETTING",
    payload: setting,
  });
};

/**
 * @typedef {Object} PasteUserResponse
 * @property {Array.<{status: string, description: string}>} results - An array of result objects.
 * @property {Array.<Object>} data - An array of data objects.
 */

/**
 * @typedef {Object} NonCognito2FAData
 * @property {'RequestPinCode'} actionType - The action to be performed here it is requestPinCode.
 * @property {string} userLogin - The login username
 * @property {string} companyID - The company ID of the user
 */

/**
 * @typedef {Object} VerifyPinCodeData
 * @property {'VerifyPinCode'} actionType - The action to be performed here it is VerifyPinCode.
 * @property {string} userLogin - The login username
 * @property {string} companyID - The company ID of the user
 * @property {string} pinCode - The pin code
 */

/**
 * @typedef {Object} Basis
 * @property {string} prompt
 * @property {string} type
 * @property {number} rate
 */